<script setup>
import PromptDialogWrapper from '@/widgets/PromptDialogWrapper'
import ConfirmDialog from '@/widgets/ConfirmDialog'
import CrypkoReportDialog from './CrypkoReportDialog.vue'
import { APIRequest, datetimeFormatter } from '@/utils'
import { ref, reactive } from 'vue'
import store from '@/store'
import { PROD_MODE } from '@/utils/constants'
const emit = defineEmits(['removed', 'update'])
import Dialog from '@/utils/dialog'

const props = defineProps({
  album: {
    type: Object,
    required: true,
  },
})

const dialog = ref(false)
const removeConfirmDialog = ref(null)
const reportDialog = ref(null)
const hash = props.album.hash
const user = reactive(store.state.user.user)
async function onRemove () {
  if (!await removeConfirmDialog.value.show()) return
  await new APIRequest('/travel/album/remove/', {
    id: props.album.id,
  }).send()
  emit('removed', props.album)
  dialog.value = false
  Dialog.showSuccessDialog()
}

async function onRemoveMsg () {
  await new APIRequest('/travel/album/remove_msg/', {
    hash: props.album.hash,
  }).send()
  emit('update', props.album)
  dialog.value = false
  Dialog.showSuccessDialog()
}

const isNsfw = ref(props.album.is_nsfw)

const onToggleNsfw = async () => {
  const resp = await new APIRequest('/travel/album/toggle_nsfw/', {
    hash: props.album.hash,
  }).send()
  isNsfw.value = resp.data.is_nsfw
}

function onReport () {
  reportDialog.value.show()
}

</script>
<template>
  <v-dialog
    v-model="dialog"
    width="500"
  >
    <template #activator="{ on, attrs }">
      <slot v-bind="{ on, attrs }">
        <v-btn
          color="#fffa"
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>
            mdi-information
          </v-icon>
        </v-btn>
      </slot>
    </template>

    <v-card>
      <v-card-title>
        {{ $t('general.label.details') }}
      </v-card-title>
      <v-card-text v-if="props.album">
        <div class="grid-wrapper mb-2 text-caption">
          <v-sheet
            class="d-flex align-center justify-space-between px-2 secondary--text font-weight-bold"
            color="rgba(252, 176, 113, 0.1)"
            height="32"
            rounded="md"
          >
            <v-icon
              left
              small
              color="secondary"
            >
              mdi-map-marker
            </v-icon>
            <span>
              {{ props.album.travel.destination ?? 'Unknown' }}
            </span>
          </v-sheet>
          <v-sheet
            class="d-flex align-center justify-space-between px-2 secondary--text font-weight-bold"
            color="rgba(252, 176, 113, 0.1)"
            height="32"
            rounded="md"
          >
            <v-icon
              left
              small
              color="secondary"
            >
              mdi-clock
            </v-icon>
            {{ datetimeFormatter.format(new Date(props.album.travel.end_at)) }}
          </v-sheet>
        </div>
        <v-row
          class="my-3 px-4"
          align="center"
        >
          <v-divider />
          <div class="mx-2">
            {{ $t('travel.actions') }}
          </div>
          <v-divider />
        </v-row>
        <div
          class="d-flex flex-column mt-3"
          style="gap: 0.5rem"
        >
          <v-btn
            v-if="user.id === album.user.id && album.message.status === 'done'"
            class="fill-width"
            color="primary"
            style="background: rgba(242, 118, 119, 0.1)"
            text
            elevation="0"
            @click.stop="onRemoveMsg"
          >
            <v-icon left>
              mdi-message-off
            </v-icon>
            {{ $t('travel.remove-msg') }}
          </v-btn>
          <v-btn
            v-if="user.id === album.user.id"
            class="fill-width"
            color="primary"
            style="background: rgba(242, 118, 119, 0.1)"
            text
            elevation="0"
            @click.stop="onRemove"
          >
            <v-icon left>
              mdi-delete
            </v-icon>
            {{ $t('travel.remove-album') }}
          </v-btn>
          <v-btn
            class="fill-width"
            color="primary"
            style="background: rgba(242, 118, 119, 0.1)"
            text
            elevation="0"
            @click.stop="onReport"
          >
            <v-icon left>
              mdi-alert
            </v-icon>
            {{ $t('general.label.report') }}
          </v-btn>
          <v-btn
            v-if="!PROD_MODE"
            class="fill-width"
            color="primary"
            style="background: rgba(242, 118, 119, 0.1)"
            text
            elevation="0"
            @click.stop="onToggleNsfw"
          >
            <v-icon left>
              mdi-dev-to
            </v-icon>
            {{ isNsfw ? 'Unset' : 'Set' }} NSFW
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
    <prompt-dialog-wrapper ref="removeConfirmDialog">
      <confirm-dialog
        :title="$t('dialog.remove-album.title')"
        :msg="$t('dialog.remove-album.message2')"
        :confirm-label="$t('general.label.confirm')"
        :cancel-label="$t('general.label.cancel')"
      />
    </prompt-dialog-wrapper>
    <PromptDialogWrapper ref="reportDialog">
      <CrypkoReportDialog
        :hash="hash"
        type="album"
      />
    </PromptDialogWrapper>
  </v-dialog>
</template>

<style lang="scss" scoped>
.grid-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 0.5rem;
}
</style>