

import { ref, unref, onMounted, onBeforeUnmount, computed, watchEffect } from 'vue'

export function formatRemainingTime (totalSeconds) {
    const hours = Math.floor(totalSeconds / 3600)
    const minutes = Math.floor((totalSeconds % 3600) / 60)
    const seconds = totalSeconds % 60
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
}

export function useFormattedRemainTime (date) {
    const remainSeconds = ref(-1)
    watchEffect(() => {
        if (!date) {
            return
        }
        remainSeconds.value = Math.max(0, (unref(date).getTime() - new Date().getTime()) / 1000)
    })
    let int = 0
    onMounted(() => {
        function setRemainTime () {
            if (!date) {

                const now = new Date()
                const nowJST = new Date(now.getTime() + (now.getTimezoneOffset() + 9 * 60) * 60 * 1000)
                const nextDayJST = new Date(nowJST.getFullYear(), nowJST.getMonth(), nowJST.getDate() + 1)
                remainSeconds.value = (nextDayJST.getTime() - nowJST.getTime()) / 1000
            } else {
                remainSeconds.value = (unref(date).getTime() - new Date().getTime()) / 1000
            }
            remainSeconds.value = Math.max(remainSeconds.value, 0)
        }
        int = setInterval(() => {
            setRemainTime()
        }, 1000)
        setRemainTime()
    })
    onBeforeUnmount(() => {
        clearInterval(int)
    })

    const formattedRemainSeconds = computed(() => {
        return formatRemainingTime(Math.floor(remainSeconds.value))
    })
    return formattedRemainSeconds
}