<template>
  <div
    :style="{
      minWidth,
    }"
  >
    <conditional-wrapper
      tag="router-link"
      :active="!(disableClick || disableLink)"
      :props="selectLocationWay"
    >
      <v-sheet
        class="crypko-card__sheet"
        :class="{
          'crypko-card__shadow': dropShadow,
          'crypko-card__selected': selected,
        }"
        width="100%"
        :rounded="rounded"
        @click="clickCrypko"
      >
        <div
          v-if="showTopTagRarity && topTag && topTag.rarity !== 'N'"
          style="position: absolute; z-index: 1; right:0.5rem; top: 0.5rem"
        >
          <tag-sheet
            :tag="topTag"
            :color="MODEL_COLORS[crypko.model].primary"
          />
        </div>
        <div>
          <crypko-img
            v-if="crypko"
            class="crypko-card mx-auto"
            :class="{
              'cursor-default': disableClick,
            }"
            :crypko="crypko"
            :size="size"
            :rounded="rounded"
            v-bind="imgProps"
          />
        </div>
        <v-btn
          v-if="favIcon"
          class="fav-btn"
          absolute
          icon
          small
          @click.stop.prevent="favCrypko"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            version="1.1"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            style="fill:white; stroke-width:2.5; pointer-events: none;"
            :style="{
              stroke: crypko.faved ? '#f06060' : '#f27677',
              fill: crypko.faved ? '#f06060' : 'white',
            }"
          >
            <path
              d="M12,21.35L10.55,20.03C5.4,15.36 2,12.27 2,8.5C2,5.41 4.42,3 7.5,3C9.24,3 10.91,3.81 12,5.08C13.09,3.81 14.76,3 16.5,3C19.58,3 22,5.41 22,8.5C22,12.27 18.6,15.36 13.45,20.03L12,21.35Z"
            />
          </svg>
        </v-btn>
        <v-btn
          v-if="zoomIcon"
          class="zoom-btn"
          absolute
          elevation="0"
          fab
          x-small
          @click.stop="$emit('expand')"
        >
          <v-icon color="grey lighten-1">
            mdi-arrow-expand
          </v-icon>
        </v-btn>
        <div class="top-right-btns d-flex flex-column">
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-btn
                v-if="crypko.chattable"
                v-bind="attrs"
                class="px-0"
                :color="!user || user.isFreemium() ? 'grey' : 'primary'"
                fab
                elevation="0"
                width="28px"
                height="28px"
                v-on="on"
                @click.stop.prevent="onChatClick"
              >
                <v-icon
                  color="white"
                  size="16px"
                >
                  mdi-chat
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('crypko.card.tooltip.chat') }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-btn
                v-if="crypko.crossfuse"
                v-bind="attrs"
                class="px-0"
                color="light-green"
                fab
                elevation="0"
                width="28px"
                height="28px"
                v-on="on"
                @click.stop.prevent="onCrossfuseClick"
              >
                <v-icon
                  color="white"
                  size="16px"
                >
                  mdi-vector-combine
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('crypko.card.tooltip.crossfuse') }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-btn
                v-if="crypko.nsfw"
                v-bind="attrs"
                color="grey lighten-1"
                fab
                elevation="0"
                width="28px"
                height="28px"
                v-on="on"
                @click.stop.prevent
              >
                <v-icon
                  color="white"
                  size="18px"
                >
                  mdi-eye-minus
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('crypko.card.tooltip.nsfw') }}</span>
          </v-tooltip>
        </div>
        <v-btn
          v-if="crypko.public === false"
          class="privacy-btn"
          color="grey lighten-1"
          fab
          elevation="0"
          width="28px"
          height="28px"
          @click.stop.prevent
        >
          <v-icon
            color="white"
            size="18px"
          >
            mdi-lock
          </v-icon>
        </v-btn>
      </v-sheet>
    </conditional-wrapper>
    <div
      v-if="displayName"
      class="d-flex justify-space-between align-center mt-1 text-caption px-3"
    >
      <div class="crypko-name">
        {{ crypko.name }}
      </div>
      <router-link
        tag="div"
        :to="{
          name: 'user-home',
          params: { id: crypko.owner.id }
        }"
        class="cursor-pointer"
      >
        <user-avatar
          :user="crypko.owner"
          size="26px"
          disable-menu
        />
      </router-link>
    </div>
    <freemium-chat-confirm-dialog ref="freemiumChatDialog" />
  </div>
</template>

<script>
import CrypkoImg from '@/widgets/CrypkoImg'
import sizeable from '@/mixins/sizeable'
import Crypko from '@/utils/crypko'
import Dialog from '@/utils/dialog'
import ConditionalWrapper from '@/widgets/ConditionalWrapper'
import { CREATE_ROUTE_NAME , MODEL_COLORS } from '@/utils/constants'
import { mapFields } from 'vuex-map-fields'
import { mapState } from 'vuex'
import FreemiumChatConfirmDialog from '@/widgets/FreemiumChatConfirmDialog.vue'
import TagSheet from '@/widgets/TagSheet'

export default {
  name: 'crypko-card',
  components: {
    UserAvatar: () => import('../widgets/UserAvatar'),
    ConditionalWrapper,
    CrypkoImg,
    FreemiumChatConfirmDialog,
    TagSheet,
  },
  mixins: [sizeable],
  props: {
    crypko: {
      type: Object,
      default: null,
    },
    showTopTagRarity: {
      type: Boolean,
      default: false,
    },
    disableLink: {
      type: Boolean,
      default: false,
    },
    replaceLink: {
      type: Boolean,
      default: false,
    },
    disableClick: {  // implies disableLink
      type: Boolean,
      default: false,
    },
    imgProps: {
      type: Object,
      default: () => ({}),
    },
    selected: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: String,
      default: '16',
    },
    favIcon: {
      type: Boolean,
      default: false,
    },
    displayName: {
      type: Boolean,
      default: false,
    },
    zoomIcon: {
      type: Boolean,
      default: false,
    },
    dropShadow: {
      type: Boolean,
      default: false,
    },
    minWidth: {
      type: Number,
      default: 160,
    },
  },
  data () {
    return {
      MODEL_COLORS,
    }
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapFields('chat', ['showChatDialog']),
    topTag () {
      if (!this.crypko.tags) return null
      return this.crypko.tags.slice().sort((a, b) => b.rarity - a.rarity)[0]
    },
    selectLocationWay () {
      if (this.replaceLink) {
        return {
          to: this.crypko.route,
          replace: true,
        }
      } else {
        return { to: this.crypko.route }
      }
    },
  },
  methods: {
    async onChatClick () {
      if (this.user && this.user.isFreemium()) {
        await this.$refs.freemiumChatDialog.show()
        return
      }
      this.$router.push({
        name: 'crypko',
        params: {
          hash: this.crypko.hash,
        },
        query: {
          chat: true,
        },
      })
    },
    clickCrypko () {
      if (this.disableClick) return
      this.$emit('click', this.crypko)
    },
    async favCrypko () {
      const fav = !this.crypko.faved
      // FIXME
      // eslint-disable-next-line vue/no-mutating-props
      this.crypko.faved = fav
      try {
        await Crypko.fav(this.crypko.hash, fav)
        await this.$store.dispatch('user/updateCurrentUser')
      } catch (e) {
        Dialog.showFailedDialog()
        // eslint-disable-next-line vue/no-mutating-props
        this.crypko.faved = !fav
      }
    },
    onCrossfuseClick () {
      if (this.user.id === this.crypko.owner.id) {
        Dialog.showSuccessDialog(this.$t('general.msg.own-crypko'), 1200)
        return
      }
      this.$store.commit('fuse/setPair', {
        idx: 0,
        crypko: null,
      })
      this.$store.commit('fuse/setPair', {
        idx: 1,
        crypko: this.crypko,
      })
      this.$router.push({
        name: CREATE_ROUTE_NAME.FUSE,
      })
    },
  },
}
</script>

<style scoped lang="scss">
.crypko-card__sheet {
  position: relative;
  overflow: hidden;
}

.crypko-card__selected {
  outline: 4px solid map-get($colors, crypko-card-border-selected);
}

.crypko-card__shadow {
  box-shadow: map-get($shadows, background-section);
  transition: box-shadow 0.2s;
}

.crypko-card__shadow:hover {
  box-shadow: map-get($shadows, hover);
}

.model-chip {
  padding: 0 8px 0 8px;
}

.fav-btn {
  bottom: 10px;
  right: 10px;
}

.zoom-btn {
  top: 10px;
  left: 10px;
  opacity: 0.8;
  width: 28px;
  height: 28px;
}

.privacy-btn {
  position: absolute;
  bottom: 10px;
  left: 10px;
  opacity: 0.6;
}

.top-right-btns {
  position: absolute;
  top: 10px;
  right: 10px;
  row-gap: 4px;
}

.top-right-btns>.v-btn {
  opacity: 0.6;
}

.crypko-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
