<script setup>
import UserAvatar from '@/widgets/UserAvatar.vue'
import { computed } from 'vue'
import { useRouter } from '../router'
const props = defineProps({
  user: {
    type: Object,
    default: null,
  },
  index: {
    type: Number,
    default: 0,
  },
})
const router = useRouter()
function toUserPage (id) {
  router.push({
    name: 'user-home',
    params: { id },
  })
}

const color = computed(() => {
  switch (props.index) {
    case 0:
      return '#ef4444'
    case 1:
      return '#f97316'
    case 2:
      return '#f59e0b'
    default:
      return '#777'
  }
})

const formatter = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  notation: 'standard', 
})

const icon = computed(() => {
  return  'mdi-medal'
})
</script>
<template>
  <VListItem
    ref="item"
    :style="{
      padding: $vuetify.breakpoint.mdAndUp ? '' : '0 8px'
    }"
    class="user-rank-item"
    @click="toUserPage(user.id)"
  >
    <v-icon
      v-if="!props.user.ranking && $vuetify.breakpoint.mdAndUp"
      :color="color"
    >
      {{ icon }}
    </v-icon>
    <div
      v-else
      style="min-width: 24px; text-align: center;"
    >
      {{ user.ranking ? user.ranking : index + 1 }}
    </div>
    <user-avatar
      :user="user"
      size="26px"
      disable-menu
    />
    <div style="flex-grow: 1; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
      {{ user.username }}
    </div>
    <div :style="`color: ${color}`">
      {{ formatter.format(user.count) }}
    </div>
  </VListItem>
</template>
<style>
.user-rank-item {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}
</style>