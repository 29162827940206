<template>
  <div>
    <div>
      <p>{{ $t(`help.create.keypoint.0`) }}</p>
      <p>{{ $t(`help.create.keypoint.1`) }}</p>
      <v-img
        :max-width="HELP_IMAGE_MAX_WIDTH"
        src="/images/help/pose1.png"
      />
      <v-img
        :max-width="HELP_IMAGE_MAX_WIDTH"
        src="/images/help/pose2.png"
      />
    </div>
    <div>
      <p>{{ $t(`help.create.keypoint.2`) }}</p>
      <v-img
        :max-width="HELP_IMAGE_MAX_WIDTH"
        src="/images/help/pose3.png"
      />
    </div>
    <div>
      <p>{{ $t(`help.create.keypoint.3`) }}</p>
      <v-img
        :max-width="HELP_IMAGE_MAX_WIDTH"
        src="/images/help/pose4.png"
      />
    </div>
    <div>
      <p>{{ $t(`help.create.keypoint.4`) }}</p>
      <v-img
        :max-width="HELP_IMAGE_MAX_WIDTH"
        src="/images/help/pose5.png"
      />
      <v-img
        :max-width="HELP_IMAGE_MAX_WIDTH"
        src="/images/help/pose6.png"
      />
    </div>
  </div>
</template>

<script>
import { HELP_IMAGE_MAX_WIDTH } from '@/utils/constants'

export default {
  name: 'help-pose',
  data () {
    return {
      HELP_IMAGE_MAX_WIDTH,
    }
  },
}
</script>

<style lang="scss" scoped>
.v-image {
  margin: 0 0 20px;
}
</style>
