<template>
  <router-link
    :to="user?TO_GALLERY:'/'"
  >
    <img
      class="logo__icon"
      :src="src"
    >
  </router-link>
</template>

<script>
import { mapState } from 'vuex'
import {
  NAV_BTN, NAV_TYPE,
} from '../utils/constants'

export default {
  name: 'logo-icon',
  computed: {
    ...mapState('user', ['user']),
    src () {
      return this.user
        ? '/images/favicon.png'
        : '/images/logo-header.png'
    },
    TO_GALLERY () {
      return NAV_BTN[NAV_TYPE.GALLERY].to
    },

  },
}
</script>

<style lang="scss" scoped>
.logo {
  &__icon {
    margin-top: 5px;
    height: 40px;

    @media #{map-get($display-breakpoints, 'xs-only')} {
      height: 36px;
    }

    @media screen and (max-width: 359px) {
      display: none;
    }
  }
}
</style>
