var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"wrapper fill-height mt-2 px-6"},[_c('div',{staticClass:"fill-width"},[_c('div',{staticClass:"text-h5 font-weight-bold mb-6"},[_c('span',{staticStyle:{"box-shadow":"inset 0 -10px 0 0 #ddd"}},[_vm._v(" "+_vm._s(_vm.$t('workbench.select.create-character'))+" ")])]),_c('div',{staticClass:"tool-row"},_vm._l((_vm.characterBtns),function(btn,btnName){return _c('div',{key:btnName},[_c('v-card',{staticClass:"d-flex flex-column align-center py-6 select-card",attrs:{"color":!btn.isAvailable(_vm.user) ? '#dcdcdc' : null,"width":"160","height":"160","rounded":"lg","outlined":""},on:{"click":() => _vm.onBtnClick(btn)}},[(!btn.isAvailable(_vm.user))?_c('v-icon',{attrs:{"color":btn.color,"size":"56"}},[_vm._v(" mdi-lock ")]):_c('img',{staticClass:"d-block rounded-0",attrs:{"src":`/images/workbench/${btnName}.svg`,"width":"56","height":"56"}}),_c('div',{staticClass:"text-h5 mt-5 text-no-wrap",style:({
              'color': btn.color,
            })},[_vm._v(" "+_vm._s(_vm.$t(`header.router.${btn.to.name}`))+" ")]),(btn.alpha)?_c('img',{staticClass:"d-block rounded-0 alpha-beta-icon",attrs:{"src":"/images/workbench/alpha.svg"}}):_vm._e(),(btn.beta)?_c('img',{staticClass:"d-block rounded-0 alpha-beta-icon",attrs:{"src":"/images/workbench/beta.svg"}}):_vm._e()],1)],1)}),0),_c('div',{staticClass:"text-h5 font-weight-bold mt-10 mb-6"},[_c('span',{staticStyle:{"box-shadow":"inset 0 -10px 0 0 #ddd"}},[_vm._v(" "+_vm._s(_vm.$t('workbench.select.create-illust'))+" ")])]),_c('div',{staticClass:"tool-row"},[_c('v-card',{staticClass:"d-flex flex-column align-center py-6 select-card",attrs:{"width":"160","height":"160","rounded":"lg","outlined":""},on:{"click":() => _vm.onBtnClick(_vm.travelBtn)}},[_c('div',{staticClass:"d-inline-block",staticStyle:{"overflow":"hidden","border-radius":"16px"}},[_c('v-img',{attrs:{"src":"/images/travel_create.jpg","max-height":"56","max-width":"56","contain":"","rounded":"lg"}})],1),_c('div',{staticClass:"text-h5 mt-5 text-no-wrap",style:({
            'color': 'rgb(198, 120, 129)',
          })},[_vm._v(" "+_vm._s(_vm.$t(`header.router.travel`))+" ")])]),_vm._l((_vm.illustBtns),function(btn,btnName){return _c('div',{key:btnName},[_c('v-card',{staticClass:"d-flex flex-column align-center py-6 select-card",attrs:{"color":!btn.isAvailable(_vm.user) ? '#dcdcdc' : null,"width":"160","height":"160","rounded":"lg","outlined":""},on:{"click":() => _vm.onBtnClick(btn)}},[(!btn.isAvailable(_vm.user))?_c('v-icon',{attrs:{"color":btn.color,"size":"56"}},[_vm._v(" mdi-lock ")]):_c('img',{staticClass:"d-block rounded-0",attrs:{"src":`/images/workbench/${btnName}.svg`,"width":"56","height":"56"}}),_c('div',{staticClass:"text-h5 mt-5 text-no-wrap",style:({
              'color': btn.color,
            })},[_vm._v(" "+_vm._s(_vm.$t(`header.router.${btn.to.name}`))+" ")]),(btn.alpha)?_c('img',{staticClass:"d-block rounded-0 alpha-beta-icon",attrs:{"src":"/images/workbench/alpha.svg"}}):_vm._e(),(btn.beta)?_c('img',{staticClass:"d-block rounded-0 alpha-beta-icon",attrs:{"src":"/images/workbench/beta.svg"}}):_vm._e()],1)],1)})],2)]),_c('plan-required-dialog',{ref:"planRequiredDialog"}),_c('prompt-dialog-wrapper',{ref:"crypkoRequiredDialog"},[_c('confirm-options-dialog',{attrs:{"title":_vm.$t('dialog.require-crypko.title'),"msg":_vm.$t('dialog.require-crypko.message'),"btn-colors":['primary', null, null],"options":[`${_vm.$t('general.label.generate')}`,`${_vm.$t('general.label.continue-anyway')}`,`${_vm.$t('general.label.close')}`]}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }