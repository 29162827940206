<template>
  <v-card>
    <v-card-title class="justify-center pt-10 pb-2">
      <v-img
        max-width="120"
        height="120"
        src="/images/404.png"
      />
    </v-card-title>
    <v-card-title class="justify-center">
      {{ $t('slots-insufficient') }}
    </v-card-title>
    <v-card-text v-if="user">
      <div class="d-flex justify-center align-center mb-8">
        <div class="slots-text mr-2">
          {{ $t('general.label.slots-remain') }}
        </div>
        <div
          class="d-flex align-center primary--text"
        >
          <div>
            {{ remainSlots }}
          </div>
          <v-btn
            class="ml-2"
            icon
            small
            @click="onRefreshClick"
          >
            <v-icon
              color="grey"
              size="22"
            >
              mdi-refresh
            </v-icon>
          </v-btn>
        </div>
      </div>
      <v-btn
        class="btn"
        color="primary"
        elevation="0"
        rounded
        @click="onDeleteSomeClick"
      >
        {{ $t('general.label.delete-unwanted') }}
      </v-btn>
      <v-btn
        v-if="user && user.isFreemium()"
        class="btn"
        color="secondary"
        elevation="0"
        rounded
        :to="{ name: 'plans' }"
      >
        {{ $t('payment.upgrade-plan') }}
      </v-btn>
      <v-btn
        class="btn"
        elevation="0"
        rounded
        @click="confirm"
      >
        {{ $t('general.label.continue-anyway') }}
      </v-btn>
      <v-btn
        class="btn"
        elevation="0"
        rounded
        @click="cancel"
      >
        {{ $t('general.label.cancel') }}
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'slot-confirm-dialog',
  inject: ['confirm', 'cancel'],
  data () {
    return {}
  },
  computed: {
    ...mapState('user', ['user']),
    remainSlots () {
      return this.user && Math.max(this.user.slots.max - this.user.slots.now, 0)
    },
    toCrypko () {
      return {
        name: 'user-crypko',
        params: {id: this.user.id},
      }
    },
  },
  methods: {
    onDeleteSomeClick () {
      this.$router.push(this.toCrypko)
    },
    async onRefreshClick () {
      await this.$store.dispatch('user/updateCurrentUser')
    },
  },
}
</script>

<style scoped>
.btn {
  width: 100%;
  margin: 10px auto 0;
}
.slots-text {
  background-color: #bdbdbd;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  padding: 2px 5px;
}
</style>
<i18n>
{
  "ja": {
    "slots-insufficient": "スロット不足"
  },
  "en": {
    "slots-insufficient": "Not enough slots"
  },
  "cn": {
    "slots-insufficient": "保存数超过上限"
  }
}
</i18n>