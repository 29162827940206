<script setup>
import { APIRequest } from '@/utils'
import UserRankList from './UserRankList.vue'
import LoadingProgressCircular from '@/widgets/LoadingProgressCircular.vue'
import { ref } from 'vue'

defineProps({
    title: {
        type: String,
        default: null,
    },
    icon: {
        type: String,
        default: null,
    },
})

const ranks = ref([])
new APIRequest('/users/get_all_rank/?limit=5', {}, 'get').send().then((response)=>{
    ranks.value = response.data
})

</script>

<template>
  <v-card
    class="wrapper fill-width"
    elevation="0"
    :rounded="$vuetify.breakpoint.mdAndUp ? 'lg' : '0'"
  >
    <v-card-title class="d-flex pb-2 align-center px-0">
      <v-sheet
        v-if="icon"
        class="d-flex justify-center align-center mr-1"
        color="primary"
        width="24px"
        height="24px"
        rounded="circle"
      >
        <v-icon
          color="white"
          size="15px"
        >
          {{ icon }}
        </v-icon>
      </v-sheet>
      <div class="text-h6 grey--text text--darken-2 font-weight-bold">
        <slot name="title">
          {{ title }}
        </slot>
      </div>
      <v-spacer />
    </v-card-title>
    <v-divider />
    <v-card-text class="px-0">
      <v-row
        v-if="ranks.length !== 0"
        :dense="$vuetify.breakpoint.mdAndUp ? false : true"
      >
        <v-col
          v-for="rank in ranks"
          :key="rank.name"
          cols="6"
          lg="3"
        >
          <UserRankList
            :title="rank.name"
            :users="rank.data"
            :self="rank.self"
          />
        </v-col>
      </v-row>
      <loading-progress-circular
        v-else
        class="mb-8"
      />
    </v-card-text>
  </v-card>
</template>

  
<style scoped lang="scss">
.wrapper {
    background-color: #0000;
}
</style>
  