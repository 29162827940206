<template>
  <div 
    v-if="display === 'square'"
    class="crypko-grid-square"
  >
    <div
      v-for="(_, idx) in crypkos"
      :key="idx"
      class="crypko-grid-item-square"
    >
      <crypko-card
        v-if="crypkos && crypkos[idx]"
        :crypko="crypkos[idx]"
        :selected="selectedMap[idx]"
        :size="undefined"
        :min-width="undefined"
        v-bind="cardProps"
        :show-top-tag-rarity="showTopTagRarity"
        :zoom-icon="false"
        @click="crypko => onClick(crypko, idx)"
        @expand="$emit('expand', idx)"
      />
      <div
        v-else
        class="grid-row__placeholder"
        :class="{'grid-row__placeholder--visible': placeholder}"
      />
    </div>
  </div>
  <div
    v-else
    class="crypko-grid"
    :class="{
      'crypko-grid__simple': simple,
    }"
  >
    <div
      v-for="(_, idx) in crypkos"
      :key="idx"
    >
      <crypko-card
        v-if="crypkos && crypkos[idx]"
        :crypko="crypkos[idx]"
        :selected="selectedMap[idx]"
        :size="160"
        v-bind="cardProps"
        :show-top-tag-rarity="showTopTagRarity"
        @click="crypko => onClick(crypko, idx)"
        @expand="$emit('expand', idx)"
      />
      <div
        v-else
        class="grid-row__placeholder"
        :class="{'grid-row__placeholder--visible': placeholder}"
      />
    </div>
  </div>
</template>

<script>
import CrypkoCard from './CrypkoCard'
import Crypko from '@/utils/crypko'
import resize from 'vue-resize-directive'
import { DEFAULT_CARD_SIZE } from '../utils/constants'
import { mapFields } from 'vuex-map-fields'
import { mapPropsModels } from '../utils'

export default {
  name: 'crypko-grid',
  components: {
    CrypkoCard,
  },
  directives: {
    resize,
  },
  props: {
    display: {
      type: String,
      default: 'default',
    },
    crypkos: {
      type: Array,
      default: null,
    },
    showTopTagRarity: {
      type: Boolean,
      default: false,
    },
    // enforce row number
    rowNum: {
      type: Number,
      default: null,
    },
    maxColNum: {
      type: Number,
      default: 5,
    },
    // resize card smaller for mobile device
    autoResize: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: Boolean,
      default: false,
    },
    simple: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    selection: {
      type: Array,
      default: null,
    },
    cardProps: {
      type: Object,
      default: null,
    },
    justifyCenter: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      cardWidth: 0,
      matrix: null,
      containerWidth: 0,
    }
  },
  computed: {
    ...mapFields('carousel', { carouselCrypkos: 'crypkos' }),
    ...mapPropsModels(['selection']),
    minMargin () {
      return this.$vuetify.breakpoint.xsOnly ? 16 : 30
    },
    defaultCardWidth () {
      return DEFAULT_CARD_SIZE
    },
    selectedMap () {
      return this.crypkos.map((crypko, idx) =>
        this.selectionModel && this.selectionModel.includes(idx))
    },
  },
  watch: {
    crypkos () {
      this.selectionModel = []
    },
  },
  mounted () {
  },
  methods: {
    onClick (crypko, crypkoIdx) {
      if (this.selectable) {
        const selectionIdx = this.selectionModel.indexOf(crypkoIdx)
        if (selectionIdx < 0) {
          this.selectionModel = this.selectionModel.concat(crypkoIdx)
        } else {
          // remove selection
          this.selectionModel = this.selectionModel
            .slice(0, selectionIdx)
            .concat(this.selectionModel.slice(selectionIdx + 1))
        }
      }
      this.carouselCrypkos = JSON.parse(JSON.stringify(this.crypkos)).map(c => new Crypko(c))
      this.$emit('select', {
        crypko,
        crypkoIdx,
      })
    },
  },
}
</script>

<style scoped lang="scss">
.crypko-grid {
  display: grid;
  row-gap: 24px;
  column-gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  justify-content: space-between;

  @media #{map-get($display-breakpoints, 'xs-only')} {
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  }

  &__simple {
    grid-template-columns: repeat(auto-fill, 160px);
    column-gap: 48px;
  }
}

.grid-row {
  &__placeholder {
    width: var(--card-width);

    &--visible {
      padding-bottom: 100%;
      border: 1px solid grey;
    }
  }
}

.crypko-grid-square {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem; 

}

.crypko-grid-item-square {
  padding-top: 100%; 
  position: relative;
}

.crypko-grid-item-square * {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
</style>
