<template>
  <div>
    <div class="d-flex align-center">
      <v-btn
        icon
        :to="{ name: 'gallery' }"
      >
        <v-icon size="36">
          mdi-arrow-left-circle
        </v-icon>
      </v-btn>
      <div class="ml-4 text-h6 font-weight-bold grey--text text--darken-2">
        {{ title }}
      </div>
    </div>
    <crypko-browser />
  </div>
</template>

<script>
import CrypkoBrowser from '@/components/CrypkoBrowser'
import { GALLERY_VIEW_NAME, GALLERY_ROUTE_NAME } from '@/utils/constants'

export default {
  name: 'gallery-crypko-browser',
  components: {
    CrypkoBrowser,
  },
  props: {
  },
  data () {
    return {
    }
  },
  computed: {
    title () {
      switch (this.$route.name) {
        case GALLERY_ROUTE_NAME.TREND:
          return this.$t(`gallery.views.${GALLERY_VIEW_NAME.TREND}`)
        case GALLERY_ROUTE_NAME.CHATTABLE:
          return this.$t(`gallery.views.${GALLERY_VIEW_NAME.CHATTABLE}`)
        case GALLERY_ROUTE_NAME.CROSSFUSE:
          return this.$t(`gallery.views.${GALLERY_VIEW_NAME.CROSSFUSE}`)
        case GALLERY_ROUTE_NAME.FOLLOWING:
          return this.$t(`gallery.views.${GALLERY_VIEW_NAME.FOLLOWING}`)
        case GALLERY_ROUTE_NAME.HIBISCUS:
          return this.$t(`gallery.views.${GALLERY_VIEW_NAME.HIBISCUS}`)
        case GALLERY_ROUTE_NAME.GERBERA:
          return this.$t(`gallery.views.${GALLERY_VIEW_NAME.GERBERA}`)
        case GALLERY_ROUTE_NAME.FREESIA:
          return this.$t(`gallery.views.${GALLERY_VIEW_NAME.FREESIA}`)
        case GALLERY_ROUTE_NAME.FREESIA_M:
          return this.$t(`gallery.views.${GALLERY_VIEW_NAME.FREESIA_M}`)
        case GALLERY_ROUTE_NAME.ERICA:
          return this.$t(`gallery.views.${GALLERY_VIEW_NAME.ERICA}`)
        case GALLERY_ROUTE_NAME.CANVAS:
          return this.$t(`gallery.views.${GALLERY_VIEW_NAME.CANVAS}`)
        default:
          return null
      }
    },
  },
}
</script>

<style scoped lang="scss">
.wrapper {
  max-width: map-get($sizes, main-body-max-width);
}
</style>
