import Vue from 'vue'
import Vuex, { Store } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import fuse from './modules/fuse'
import generate from './modules/generate'
import display from './modules/display'
import edit from './modules/edit'
import marking from './modules/marking'
import variation from './modules/variation'
import emote from './modules/emote'
import carousel from './modules/carousel'
import chat from './modules/chat'
import attributes from './modules/attributes'
import keypoint from './modules/keypoint'
import workbench from './modules/workbench'
import user from './modules/user'
import interpolate from './modules/interpolate'
import video from './modules/video'
import notification from './modules/notification'
import task from './modules/task'
import travel from './modules/travel'
import survey from './modules/survey'
import { DEV_MODE } from '../utils/constants'
import { getField, updateField } from 'vuex-map-fields'

Vue.use(Vuex)

export default new Store({
  strict: DEV_MODE,
  modules: {
    carousel,
    chat,
    display,
    edit,
    marking,
    travel,
    emote,
    fuse,
    generate,
    attributes,
    workbench,
    keypoint,
    user,
    video,
    notification,
    variation,
    interpolate,
    task,
    survey,
  },
  state: {
    cache: {},
    // DEBUG
    API_HOSTNAME: process.env.VUE_APP_API_HOSTNAME,
    lang: null,
    config: {
      'AttrsCPConfirm': true,
      'NSFWConfirm': false,
      'showNSFWDialog': true,
      'hasChatted': false,
    },
    checkedTermsVersion: null,
    stampCardDialog: false,
    changelogDialog: false,
    dailyTaskDialog: false,
  },
  mutations: {
    updateField,
    updateCache (state, { key, value }) {
      state.cache[key] = {
        value,
        time: Date.now(),
      }
    },
  },
  getters: {
    getField,
    getCache: state => key => {
      return state.cache[key]
    },
  },
  plugins: [
    createPersistedState({
      paths: [
        'API_HOSTNAME',
        'lang',
        'config',
        'checkedTermsVersion',
        'hasChatted',
      ],
    }),
  ],
})
