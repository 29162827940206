<template>
  <v-card>
    <v-card-title class="justify-center pt-10 pb-2">
      <v-icon
        color="secondary"
        size="80"
      >
        {{ icon || 'mdi-information' }}
      </v-icon>
    </v-card-title>
    <v-card-title class="justify-center text-center text">
      {{ title || $t('general.label.confirm') }}
    </v-card-title>
    <v-card-text>
      <p class="text-center mb-8 text">
        {{ msg }}
      </p>
      <v-btn
        v-for="(option, i) in options"
        :key="i"
        class="btn"
        :color="btnColors ? btnColors[i] : null"
        elevation="0"
        rounded
        @click="() => confirmWithParam(i)"
      >
        {{ option }}
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'confirm-options-dialog',
  inject: ['confirmWithParam', 'cancel'],
  props: {
    title: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    msg: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    btnColors: {
      type: Array,
      default: null,
    },
  },
  data () {
    return {}
  },
}
</script>

<style scoped>
.btn {
  width: 100%;
  margin: 10px auto 0;
}

.text {
  white-space: pre-line;
  word-break: normal;
}
</style>
