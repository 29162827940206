var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"waterfallWrapper",staticClass:"waterfall-wrapper",staticStyle:{"position":"relative","width":"100%"},style:(`height: ${_setup.positionData.containerHeight}px`)},_vm._l((_setup.positionData.boxes),function(pos,idx){return _c('div',{key:`${idx}-${pos['top']}-${pos['left']}-${pos['width']}-${pos['height']}`,style:({
      position: 'absolute',
      top: `${pos['top']}px`,
      left: `${pos['left']}px`,
      width: `${pos['width']}px`,
      height: `${pos['height']}px`,
    })},[_vm._t("default",null,{"data":_vm.data[idx]})],2)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }