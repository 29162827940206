import { GALLERY_ROUTE_NAME, GALLERY_VIEW_NAME, CREATE_ROUTE_NAME } from '@/utils/constants'
import AppLayout from '@/layout/AppLayout'
import WorkbenchLayout from '@/layout/WorkbenchLayout'
import GalleryLayout from '@/layout/GalleryLayout'
import UserLayout from '@/layout/UserLayout'
import FollowLayout from '@/layout/FollowLayout'
import Vue, { getCurrentInstance } from 'vue'
import Router from 'vue-router'
import i18n from './i18n'
import store from './store'


Vue.use(Router)

const children = [
  {
    path: '',
    name: 'introduction',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Introduction" */ './layout/Introduction'),
  },
  {
    path: 'guideline',
    name: 'guideline',
    component: () => import(/* webpackChunkName: "UserGuideLine" */ './components/UserGuideLine.vue'),
  },
  {
    path: 'play',
    name: 'play',
    component: () => import(/* webpackChunkName: "PlayView" */  './views/PlayView'),
  },
  {
    path: 'play/travel',
    name: 'travel',
    component: () => import(/* webpackChunkName: "TravelView" */ './views/TravelView'),
    meta: { requiresAuth: true },
  },
  {
    path: 'auth',
    component: () => import(/* webpackChunkName: "AuthView" */ './views/AuthView'),
    children: [
      {
        path: '/',
        alias: '/',
        name: 'signup-signin',
        component: () => import('./widgets/SignupSigninDialog'),
      },
      {
        path: 'signup',
        name: 'signup',
        component: () => import('./widgets/SignupDialog'),
      },
      {
        path: 'signin',
        name: 'signin',
        component: () => import('./widgets/SigninDialog'),
      },
      {
        path: 'verify-email',
        name: 'verify-email',
        component: () => import('./widgets/EmailVerifyDialog'),
      },
      {
        path: 'reset-password/request',
        name: 'reset-pwd-request',
        component: () => import('./widgets/ResetPwdRequestDialog'),
      },
      {
        path: 'reset-password/submit',
        name: 'reset-pwd-submit',
        component: () => import('./widgets/ResetPwdSubmitDialog'),
      },
      {
        path: 'welcome',
        name: 'signup-welcome',
        component: () => import('./widgets/SignupWelcomeDialog'),
      },
    ],
  },
  {
    path: 'settings',
    name: 'settings',
    component: () => import(/* webpackChunkName: "SettingsView" */ './views/SettingsView'),
  },
  {
    path: 'notifications',
    name: 'notifications',
    component: () => import('./views/NotificationsView'),
    meta: { requiresAuth: true },
  },
  {
    path: 'plans',
    name: 'plans',
    component: () => import(/* webpackChunkName: "PricesView" */ './views/PricesView'),
  },
  {
    path: 'payment_cancelled',
    name: 'payment-cancelled',
    redirect: '/plans',
  },
  {
    path: 'subscription_cancelled',
    name: 'subscription_cancelled',
    redirect: '/plans',
  },
  {
    path: 'help',
    name: 'help',
    component: () => import(/* webpackChunkName: "HelpLayout" */ './layout/HelpLayout'),
  },
  {
    path: 'crypko/:hash',
    component: () => import(/* webpackChunkName: "CrypkoTemplate" */ './layout/CrypkoTemplate'),
    meta: { requiresAuth: true },
    props: true,
    children: [
      // by default, light is on the left/up and dark is on the right/bottom
      {
        path: '',
        name: 'crypko',
        components: {
          light: () => import(/* webpackChunkName: "CrypkoDisplay" */ './components/CrypkoDisplay'),
          dark: () => import(/* webpackChunkName: "CrypkoDetail" */ './components/CrypkoDetail'),
          bottom: () => import(/* webpackChunkName: "CrypkoCarousel" */ './components/CrypkoCarousel'),
        },
        meta: {
          title: (route) => `Crypko ${route.params.hash.slice(0, 6)}`,
        },
      },
    ],
  },
  {
    path: 'list/:list_id',
    name: 'list',
    component: () => import('./layout/ListLayout'),
    meta: { requiresAuth: true },
  },
  {
    path: 'gallery',
    name: 'gallery',
    component: GalleryLayout,
    meta: { requiresAuth: true },
    children: [
      {
        path: 'crypko',
        name: 'gallery-crypko',
        component: GalleryLayout,
      },
      {
        path: 'travel',
        name: 'gallery-travel',
        component: () => import(/* webpackChunkName: "TravelGalleryView" */ './views/TravelGalleryView'),
      },
      {
        path: GALLERY_VIEW_NAME.TREND,
        name: GALLERY_ROUTE_NAME.TREND,
      },
      {
        path: GALLERY_VIEW_NAME.CHATTABLE,
        name: GALLERY_ROUTE_NAME.CHATTABLE,
      },
      {
        path: GALLERY_VIEW_NAME.CROSSFUSE,
        name: GALLERY_ROUTE_NAME.CROSSFUSE,
      },
      {
        path: GALLERY_VIEW_NAME.FOLLOWING,
        name: GALLERY_ROUTE_NAME.FOLLOWING,
      },
      {
        path: GALLERY_VIEW_NAME.HIBISCUS,
        name: GALLERY_ROUTE_NAME.HIBISCUS,
      },
      {
        path: GALLERY_VIEW_NAME.GERBERA,
        name: GALLERY_ROUTE_NAME.GERBERA,
      },
      {
        path: GALLERY_VIEW_NAME.FREESIA,
        name: GALLERY_ROUTE_NAME.FREESIA,
      },
      {
        path: GALLERY_VIEW_NAME.FREESIA_M,
        name: GALLERY_ROUTE_NAME.FREESIA_M,
      },
      {
        path: GALLERY_VIEW_NAME.ERICA,
        name: GALLERY_ROUTE_NAME.ERICA,
      },
      {
        path: GALLERY_VIEW_NAME.CANVAS,
        name: GALLERY_ROUTE_NAME.CANVAS,
      },
      {
        path: GALLERY_VIEW_NAME.SEARCH,
        name: GALLERY_ROUTE_NAME.SEARCH,
      },
    ],
  },
  {
    path: 'user/:id',
    name: 'user',
    component: UserLayout,
    meta: { requiresAuth: true },
    children: [
      {
        path: 'home',
        name: 'user-home',
        component: () => import(/* webpackChunkName: "UserHome" */ './components/UserHome'),
      },
      {
        path: 'crypko',
        name: 'user-crypko',
        component: () => import('./components/CrypkoBrowser'),
      },
      {
        path: 'album',
        name: 'user-album',
        component: () => import('./components/UserAlbum'),
      },
      {
        path: 'list',
        name: 'user-list',
        component: () => import('./components/ListBrowser'),
        props: route => ({
          defaultQuery: {
            owner: Number.parseInt(route.params.id),
          },
        }),
      },
      {
        path: 'faved',
        name: 'user-faved',
        component: () => import('./components/ListCrypkoBrowser'),
        props: { favList: true },
      },
    ],
  },
  {
    path: 'user/:id',
    name: 'user',
    component: FollowLayout,
    meta: { requiresAuth: true },
    children: [
      {
        path: 'followings',
        name: 'followings-user',
        component: () => import('./components/UserBrowser'),
        props: route => ({
          defaultQuery: {
            get_followings: route.params.id,
          },
        }),
      },
      {
        path: 'followers',
        name: 'followers-user',
        component: () => import('./components/UserBrowser'),
        props: route => ({
          defaultQuery: {
            get_followers: route.params.id,
          },
        }),
      },
    ],
  },
  {
    path: 'terms',
    name: 'terms',
    component: () => import('./views/TermsView'),
  },
  {
    path: 'survey',
    name: 'survey',
    component: () => import('./views/SurveyView'),
    meta: { requiresAuth: true },
  },
]

const tmpChildren = [
  {
    path: 'create',
    component: WorkbenchLayout,
    name: CREATE_ROUTE_NAME.CREATE,
    meta: { requiresAuth: true },
    children: [
      {
        path: 'generate',
        name: CREATE_ROUTE_NAME.GENERATE,
        component: () => import(/* webpackChunkName: "GenerateWorkbench" */ './workbenches/GenerateWorkbench'),
      },
      {
        path: 'fuse',
        name: CREATE_ROUTE_NAME.FUSE,
        component: () => import(/* webpackChunkName: "FuseWorkbench" */ './workbenches/FuseWorkbench'),
      },
      {
        path: 'canvas',
        name: CREATE_ROUTE_NAME.CANVAS,
        components: {
          default: () => import(/* webpackChunkName: "EditWorkbench" */ './workbenches/EditWorkbench'),
        },
      },
      {
        path: 'attributes',
        name: CREATE_ROUTE_NAME.ATTRS,
        component: () => import(/* webpackChunkName: "AttributesWorkbench" */ './workbenches/AttributesWorkbench.vue'),
      },
      {
        path: 'variation',
        name: CREATE_ROUTE_NAME.VARIATION,
        component: () => import(/* webpackChunkName: "VariationWorkbench" */ './workbenches/VariationWorkbench.vue'),
      },
      {
        path: 'keypoint',
        name: CREATE_ROUTE_NAME.KEYPOINT,
        components: {
          default: () => import(/* webpackChunkName: "KeypointWorkbench" */ './workbenches/KeypointWorkbench'),
        },
      },
      {
        path: 'video',
        name: CREATE_ROUTE_NAME.INTERPOLATE,
        components: {
          default: () => import(/* webpackChunkName: "InterpolateWorkbench" */ './workbenches/InterpolateWorkbench'),
        },
      },
    ],
  },
  {
    path: 'crypko/:hash/tree',
    component: () => import(/* webpackChunkName: "CrypkoTreeLayout" */ './layout/CrypkoTreeLayout'),
    meta: { requiresAuth: true },
    name: 'crypko-tree',
  },
]

// Dynamic imports are automatically prefetched in latest Chrome/Firefox
// and preload may be just overkilling
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    // {
    //   path: '',  // leave empty for children match
    //   component: AppLayout,
    //   children: children,
    // },
    {
      path: '/:locale(en|ja|cn)',
      component: AppLayout,
      children: children,
    },
    {
      path: '/:locale(en|ja|cn)',
      component: {
        render: h => h('router-view'),
      },
      children: tmpChildren,
    },
    {
      path: '/',
      name: 'app',
      component: AppLayout,
      children: [
        {
          path: ':locale(en|ja|cn)/*',
          name: 'page404',
          component: () => import(/* webpackChunkName: "Page404" */ './views/Page404'),
        },
        {
          path: '*',
        },
      ],
    },
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      const element = document.querySelector(to.hash)
      if (element) {
        const topOfElement = window.scrollY + element.getBoundingClientRect().top - 48 // 48 is the height of the app bar
        window.scrollTo({
          top: topOfElement,
          behavior: 'smooth',
        })
      }
      return false
    } else {
      return {
        x: 0,
        y: 0,
      }
    }
  },
})

const setLinkTags = (fullPath) => {
  const pathname = ['ja', 'en', 'cn'].includes(fullPath.split('/')[1]) ? fullPath.slice(3) : fullPath
  const metaKeywords = document.querySelector('meta[name=\'keywords\']')

  const insertLink = ({ rel, href, hrefLang }) => {
    const link = document.querySelector(hrefLang ? `link[rel='${rel}'][hrefLang='${hrefLang}']` : `link[rel='${rel}']`)
    if (link) {
      link.setAttribute('href', href)
      if (hrefLang) link.setAttribute('hrefLang', hrefLang)
    } else {
      const newLink = document.createElement('link')
      newLink.setAttribute('rel', rel)
      newLink.setAttribute('href', href)
      if (hrefLang) newLink.setAttribute('hrefLang', hrefLang)
      metaKeywords.parentNode.insertBefore(newLink, metaKeywords.nextSibling)
    }
  }

  insertLink({
    rel: 'alternate',
    href: `https://crypko.ai/cn${pathname}`,
    hrefLang: 'zh-Hans',
  })
  insertLink({
    rel: 'alternate',
    href: `https://crypko.ai/ja${pathname}`,
    hrefLang: 'ja',
  })
  insertLink({
    rel: 'alternate',
    href: `https://crypko.ai/en${pathname}`,
    hrefLang: 'en',
  })
  insertLink({
    rel: 'alternate',
    href: `https://crypko.ai${pathname}`,
    hrefLang: 'x-default',
  })
  insertLink({
    rel: 'canonical',
    href: `https://crypko.ai${pathname}`,
  })
}

router.beforeEach((to, from, next) => {
  // add title to each route
  // https://github.com/vuejs/vue-router/issues/914
  let { title } = to.meta
  if (!title) {
    if (to.name) {
      document.title = i18n.t('header.router.' + to.name)
    }
  } else {
    switch (typeof title) {
      case 'function':
        title = title(to)
        break
      default:
        break
    }
    document.title = title
  }

  setLinkTags(to.fullPath)

  // Turn off chat popover when switching crypko
  store.commit('chat/updateField', {
    path: 'showChatDialog',
    value: false,
  })

  const toLocale = ['ja', 'en', 'cn'].includes(to.fullPath.split('/')[1]) ? to.fullPath.split('/')[1] : ''
  const fromLocale = ['ja', 'en', 'cn'].includes(from.fullPath.split('/')[1]) ? from.fullPath.split('/')[1] : ''
  if (toLocale) {
    i18n.locale = toLocale
    const title = to.fullPath.slice(3) === '/' ? `${document.title}` : `${document.title} | Crypko`
    let description = 'High-quality Anime Character Generation and Design powered by GAN (Generative Adversarial Networks).'
    switch (toLocale) {
      case 'ja':
        document.querySelector('html').setAttribute('lang', 'ja')
        description = 'Crypko（クリプコ）は、Preferred Networks のディープラーニング技術を活用し、GAN（Generative Adversarial Networks）による高品質なアニメキャラクター生成・デザインするデジタルツールです。'
        break
      case 'cn':
        document.querySelector('html').setAttribute('lang', 'zh-CN')
        description = 'Crypko是使用Preferred Networks深度学习技术，由GAN（生成对抗网络）驱动的高质量动漫角色生成设计工具。'
        break
      default:
        document.querySelector('html').setAttribute('lang', 'en')
    }
    document.title = title
    document.querySelector('meta[property=\'og:title\']').setAttribute('content', title)
    document.querySelector('meta[name=\'description\']').setAttribute('content', description)
    document.querySelector('meta[property=\'og:description\']').setAttribute('content', description)
    next()
  } else {
    if (from.name === to.name) return
    if (fromLocale) {
      i18n.locale = fromLocale
      document.querySelector('html').setAttribute('lang', fromLocale === 'cn' ? 'zh-CN' : fromLocale)
      next(`/${fromLocale}${to.fullPath}`)
    } else {
      i18n.locale = 'en'
      document.querySelector('html').setAttribute('lang', i18n.locale)
      next(`/${i18n.locale}${to.fullPath}`)
    }
  }
})

// Redirect the user to Auth page if the user has not logged in
router.beforeResolve(async (to, from, next) => {
  const authNames = ['signup-signin', 'signup', 'signin']
  if (authNames.includes(to.name) &&  authNames.includes(from.name) && from.query.redirect && !to.query.redirect) {
    return next({
      name: to.name,
      query: {
        redirect: from.query.redirect,
      },
    })
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const user = store.getters['user/user']
    if (!user) {
      return next({
        name: 'signup-signin',
        query: {
          redirect: to.fullPath,
        },
      })
    } else if (!user.is_verified) {
      return next({
        name: 'verify-email',
      })
    }
  }
  return next()
})

// handling NavigationDuplicated error for vue-router 3.1.x
// https://github.com/vuejs/vue-router/issues/2881
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  try {
    return originalPush.call(this, location).catch(err => {
      console.error('Global Vue Router push error', location, err)
    })
  } catch (error) {
    console.log(error)
  }
}

// - Helper functions

// An array of size 1 in route.query will not be an array
// Use this function to always return an array
export function getQueryArr (arrName) {
  const arr = router.currentRoute.query[arrName]
  return Array.isArray(arr) ? arr
    : arr === undefined ? arr
      : [arr]
}


export function useRouter () {
  // インスタンスにアクセス
  const instance = getCurrentInstance()

  // `setup()`外で使用するとインスタンスは取得できません
  // https://v3.vuejs.org/api/composition-api.html#getcurrentinstance
  if (!instance) {
    throw new Error(`Should be used in setup().`)
  }

  // proxyが従来の`this`にあたるコンポーネントインスタンス
  return instance.proxy.$router
}

export function useRoute () {
  const instance = getCurrentInstance()
  if (!instance) {
    throw new Error(`Should be used in setup().`)
  }
  return instance.proxy.$route
}

export default router
