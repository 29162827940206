<script setup>
import { computed } from 'vue'
import albumInfoDialog from '@/widgets/AlbumInfoDialog.vue'
import albumLikeBtn from '@/components/AlbumLikeBtn.vue'
import CrypkoImg from '@/widgets/CrypkoImg.vue'
import UserAvatar from '@/widgets/UserAvatar'
import { asyncComputed } from '@vueuse/core'
import AlbumMsgBubble from '@/components/AlbumMsgBubble'

const props = defineProps({
  album: {
    type: Object,
    default: null,
  },
})
const emit = defineEmits(['closed', 'removed', 'update'])
const isSelectingAlbum = computed({
  get: () => {
    return !!props.album
  },
  set: () => {
    emit('closed')
  },
})
function onRemoved () {
  emit('removed', props.album)
}
function onUpdate () {
  emit('update', props.album)
}

import OSSUtils from '@/utils/oss'
const albumURL = asyncComputed(async ()=> await OSSUtils.getAlbumUrl(props.album.hash))
const onImgClick = () => {
  const url = albumURL.value
  window.open(url, '_blank')
}
</script>
<template>
  <v-dialog
    v-model="isSelectingAlbum"
    width="fit-content"
  >
    <v-card v-if="album">
      <v-card-title class="pa-2 pa-sm-3 flex-nowrap">
        <div
          class="d-flex flex-grow-1"
          style="gap: 1rem; overflow-x: auto; overflow-y: hidden;"
        >
          <router-link
            tag="a"
            :to="{
              name: 'user-home',
              params: { id: album.user.id }
            }"
            target="_blank"
            class="cursor-pointer"
          >
            <div
              v-if="album"
              class="d-flex align-center"
              style="gap: 0.5rem;"
            >
              <div>
                <user-avatar
                  :user="album.user"
                  size="36px"
                  disable-menu
                />
              </div>
              <div>
                <div
                  class="black--text font-weight-bold"
                  style="font-size: 1rem; line-height: 1.25rem; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; max-width: 80px;"
                >
                  {{ album.user.username }}
                </div>
                <div
                  class="text-caption grey--text"
                  style="line-height: 1rem;"
                >
                  {{ $t('general.label.owner') }}
                </div>
              </div>
            </div>
          </router-link>
          <v-divider vertical />
          <router-link
            tag="a"
            :to="{
              name: 'crypko',
              params: {
                hash: album?.crypko?.hash,
              },
            }"
            target="_blank"
            class="cursor-pointer"
          >
            <div
              v-if="album?.crypko"
              class="d-flex align-center"
              style="gap: 0.5rem;"
            >
              <div>
                <crypko-img
                  class="rounded-circle"
                  size="36"
                  :crypko="album.crypko"
                  face
                />
              </div>
              <div>
                <div
                  class="black--text font-weight-bold"
                  style="font-size: 1rem; line-height: 1.25rem; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; max-width: 80px;"
                >
                  {{ album.crypko.name }}
                </div>
                <div
                  class="text-caption grey--text"
                  style="line-height: 1rem;"
                >
                  Crypko
                </div>
              </div>
            </div>
          </router-link>
        </div>
        <v-btn
          icon
          @click="isSelectingAlbum = false"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <div class="px-2">
        <album-msg-bubble
          v-if="['done', 'gen'].includes(album.message?.status)"
          expandable
          :max-width="`calc((90vh - 110px) * ${album.width / album.height} - 16px)`"
          :msg="album.message"
          bg-color="rgba(139, 195, 74, 0.1)"
          font-color="rgb(139, 195, 74)"
        />
      </div>
      <div style="position: relative;">
        <v-img
          class="cursor-pointer"
          max-height="calc(90vh - 110px)"
          :max-width="`calc((90vh - 110px) * ${album.width / album.height})`"
          contain
          :src="albumURL"
          @click="() => onImgClick()"
        >
          <div style="position: absolute; bottom: 0.25rem; left: 0.25rem;">
            <album-info-dialog
              :album="album"
              @removed="onRemoved"
              @update="onUpdate"
            />
          </div>
          <div style="position: absolute; bottom: 0.75rem; right: 0.75rem;">
            <album-like-btn :album="album" />
          </div>
        </v-img>
      </div>
    </v-card>
  </v-dialog>
</template>

<style scoped>
::v-deep .v-dialog {
   margin: 12px !important;
}
</style>