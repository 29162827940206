<template>
  <div class="d-flex align-center">
    <list-dialog :crypkos="crypkos">
      <template #default="{ on: list }">
        <v-tooltip top>
          <template #activator="{ on: tooltip, attrs }">
            <v-btn
              color="grey lighten-3"
              :disabled="!crypkos.length"
              elevation="0"
              icon
              rounded
              v-bind="attrs"
              v-on="{ ...tooltip, ...list }"
            >
              <v-icon color="grey darken-1">
                mdi-playlist-plus
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t(`detail.toolbar.addList`) }}</span>
        </v-tooltip>
      </template>
    </list-dialog>
    <privacy-dialog
      :crypkos="crypkos"
    >
      <template #default="{ on: privacy }">
        <v-tooltip top>
          <template #activator="{ on: tooltip, attrs }">
            <v-btn
              class="ml-3"
              :disabled="!crypkos.length || crypkoNotOwned"
              elevation="0"
              icon
              rounded
              v-bind="attrs"
              v-on="{ ...tooltip, ...privacy }"
            >
              <v-icon color="grey darken-1">
                mdi-lock
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t(`privacy.setting`) }}</span>
        </v-tooltip>
      </template>
    </privacy-dialog>
    <v-tooltip top>
      <template #activator="{ on, attrs }">
        <v-btn
          class="ml-3"
          :disabled="!crypkos.length || crypkoNotOwned"
          elevation="0"
          icon
          rounded
          v-bind="attrs"
          v-on="on"
          @click="batchDownload"
        >
          <v-icon color="grey darken-1">
            mdi-download
          </v-icon>
        </v-btn>
      </template>
      <span>{{ $t(`general.label.download`) }}</span>
    </v-tooltip>
    <v-tooltip top>
      <template #activator="{ on, attrs }">
        <v-btn
          class="ml-3"
          :disabled="!crypkos.length || crypkoNotOwned"
          elevation="0"
          icon
          rounded
          v-bind="attrs"
          v-on="on"
          @click="batchDelete"
        >
          <v-icon color="grey darken-1">
            mdi-delete
          </v-icon>
        </v-btn>
      </template>
      <span>{{ $t(`general.label.delete`) }}</span>
    </v-tooltip>
    <v-tooltip top>
      <template #activator="{ on, attrs }">
        <v-btn
          v-if="!PROD_MODE"
          class="ml-3"
          :disabled="!crypkos.length"
          elevation="0"
          icon
          rounded
          v-bind="attrs"
          v-on="on"
          @click="batchNSFW"
        >
          <v-icon color="grey darken-1">
            mdi-eye-minus
          </v-icon>
        </v-btn>
      </template>
      <span>Toggle NSFW</span>
    </v-tooltip>
    <loading-dialog ref="loadingDialog" />
    <prompt-dialog-wrapper
      ref="deleteDialog"
      :persistent="processingRequest"
    >
      <crypko-delete-dialog
        :crypkos="crypkos"
      />
    </prompt-dialog-wrapper>
    <prompt-dialog-wrapper
      ref="downloadDialog"
      max-width="350px"
      :persistent="processingRequest"
    >
      <crypko-download-dialog
        :crypkos="crypkos"
      />
    </prompt-dialog-wrapper>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import { APIRequest } from '@/utils'
import { PROD_MODE } from '@/utils/constants'
import ListDialog from '@/components/ListDialog'
import Dialog from '@/utils/dialog'
import PrivacyDialog from '@/widgets/PrivacyDialog'
import LoadingDialog from '@/widgets/LoadingDialog'
import PromptDialogWrapper from '@/widgets/PromptDialogWrapper'
import CrypkoDeleteDialog from '@/widgets/CrypkoDeleteDialog'
import CrypkoDownloadDialog from '@/widgets/CrypkoDownloadDialog'

export default {
  name: 'crypko-batch-btns',
  components: {
    ListDialog,
    PrivacyDialog,
    LoadingDialog,
    PromptDialogWrapper,
    CrypkoDeleteDialog,
    CrypkoDownloadDialog,
  },
  props: {
    crypkos: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      PROD_MODE,
    }
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapFields('workbench', ['processingRequest']),
    crypkoNotOwned () {
      return this.crypkos.some(crypko => !crypko || !this.user || crypko.owner.id !== this.user.id)
    },
    hashes () {
      return this.crypkos.map(crypko => crypko.hash)
    },
    deleteRequest () {
      return new APIRequest('/crypkos/delete/', { hashes: this.hashes })
    },
  },
  methods: {
    async batchDownload () {
      if (this.crypkoNotOwned) {
        Dialog.showFailedDialog(this.$t('crypko-not-owned'))
        return
      }
      await this.$refs.downloadDialog.show()
      // const hashes = this.crypkos.map(crypko => crypko.hash)
      // const request = new APIRequest(`/crypkos/download/`, {
      //   hashes,
      // })
      // if (hashes.length === 1) {
      //   try {
      //     const { data } = await request.send()
      //     await downloadFromUrl(data.url, `${hashes[0]}.jpg`)
      //   } catch {
      //     Dialog.showFailedDialog()
      //   }
      // } else {
      //   const loadingDialog = this.$refs.loadingDialog
      //   const { data } = (await loadingDialog.send(request))
      //   await downloadFromUrl(data.url, `${hashes.length}crypkos.zip`)
      // }
    },
    async batchDelete () {
      if (this.crypkoNotOwned) {
        Dialog.showFailedDialog(this.$t('crypko-not-owned'))
        return
      }
      await this.$refs.deleteDialog.show()
    },
    async batchNSFW () {
      const request = new APIRequest('/crypkos/update_nsfw/', {
        hashes: this.hashes,
      })
      try {
        await request.send()
        Dialog.showSuccessDialog()
      } catch (e) {
        console.error(e)
        Dialog.showFailedDialog()
      }
    },
  },
}
</script>

<style scoped lang="scss">
.v-btn--icon {
  background-color: #eeeeee;
}
</style>

<i18n>
{
  "ja": {
    "crypko-not-owned": "他のユーザーのCrypkoが存在します。"
  },
  "en": {
    "crypko-not-owned": "Crypko owned by others exists."
  },
  "cn": {
    "crypko-not-owned": "存在其他用户的Crypko。"
  }
}
</i18n>
