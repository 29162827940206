import Vue from 'vue'
import Vuetify, { VBtn, VIcon, VProgressCircular } from 'vuetify/lib'
import '@/scss/variables.scss'
import '@mdi/font/css/materialdesignicons.css'
import ExchangeFillIcon from '@/icons/exchange-fill.vue'
import CoinCircleIcon from '@/icons/coin-circle.vue'

Vue.use(Vuetify, {
  // due to limitation of Vuetify, Vuetify components used in functional components 
  // need to be registered globally
  // https://vuetifyjs.com/en/customization/a-la-carte#functional-components
  components: {
    VBtn,
    VIcon,
    VProgressCircular,
  },
})

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
    values: {
      'coin-circle': {
        component: CoinCircleIcon,
      },
      'exchange-fill': {
        component: ExchangeFillIcon,
      },
    },
  },
  theme: {
    options: {
      // By enabling customProperties, for each theme color, 
      // a css variable such as `var(--v-primary-base)` will be generated
      // https://vuetifyjs.com/en/customization/theme#custom-properties      
      customProperties: true,
    },
    dark: false,
    themes: {
      light: {
        primary: '#F27677',
        secondary: '#FCB071',
        accent: '#f0bb72',
        error: '#ff5252',
        info: '#2196f3',
        success: '#4caf50',
        warning: '#ffc107',
        anchor: '#39c5bb',
        // custom colors below
        'text-field': '#111111',
      },
      dark: {
      },
    },
  },
})

