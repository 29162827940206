import { getField, updateField } from 'vuex-map-fields'
import User from '@/utils/user'
import OSSUtils from '@/utils/oss'
import { APIRequest } from '@/utils'
import GAUtils from '@/utils/ga'

function getDefaultState () {
  return {
    user: null,
    token: null,
    profileUser: null,
    homeAvatarSrc: null,
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    updateField,
    setUser (state, user) {
      let _user = user
      if (user && !(user instanceof User)) {
        _user = new User(user)
      }
      state.user = _user
      GAUtils.setUserProps(user)
    },
    setProfileUser (state, user) {
      state.profileUser = user
    },
    updateUser (state, fields) {
      if (!state.user) return
      Object.entries(fields).forEach(([key, value]) => {
        state.user[key] = value
      })
    },
    setHomeAvatarSrc (state, url) {
      state.homeAvatarSrc = url
    },
  },
  actions: {
    async updateProfileUser ({ commit }, user_id) {
      if (!user_id) return null
      commit('setProfileUser', null)
      const data = await User.fetch(user_id)
      commit('setProfileUser', data)
    },
    async updateCurrentUser ({ commit }) {
      if (!APIRequest.token) return
      const user = await User.fetch(APIRequest.token.id)
      
      if (user) {
        user.updated = Date.now()
      }
      commit('setUser', user)
    },
    async updateCoins ({ commit }) {
      const coins = await User.getCoins()
      commit('updateUser', {
        coins,
      })
    },
    async updateStamina ({ commit }) {
      const stamina = await User.getStamina()
      commit('updateUser', {
        stamina,
        updated: Date.now(),
      })
    },
    async updateHomeAvatarSrc ({ commit, state }) {
      if (state.user.id) {
        const url = await OSSUtils.getUserAvatar(state.user.id)
        commit('setHomeAvatarSrc', url)
      }
    },
  },
  getters: {
    getField,
    user: state => state.user,
  },
}
