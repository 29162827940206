import store from '@/store'
import { NOTIFICATION_POLLING_INTERVAL, STAMINA_POLLING_INTERVAL } from '@/utils/constants'

/**
 * Polling is used to send requests periodically and do not send them when the window is inactive.
 * 
 * @param {number} interval Interval time in milliseconds
 * @param {string} action Action key
 */
export class Polling {
    constructor (interval, action) {
        this.interval = interval
        this.action = action
        this.timerId = 1
        this.timerObj = {}
        // Should use arraw function to avoid `this` binding
        this.updateHookEvent = () => {
            this.updateHook()
        }
    }
    async update () {
        if (document.visibilityState !== 'visible') return
        if (store.state.user.user) {
            try {
                await store.dispatch(this.action)
            } catch (e) {
                console.error(e)
            }
        }
    }

    async updateHook () {
        this.update()
        document.removeEventListener('visibilitychange', this.updateHookEvent)
    }

    start () {
        const id = this.timerId++
        this.timerObj[id] = true
        // Should use arraw function to avoid `this` binding
        const timerFn = async () => {
            if (!this.timerObj[id]) return
            await this.update()
            if (document.visibilityState !== 'visible') {
                document.addEventListener('visibilitychange', this.updateHookEvent)
            }
            setTimeout(timerFn, this.interval)
        }
        timerFn().then()
    }

    stop () {
        this.timerObj = {}
    }
}

export const notificationPolling = new Polling(NOTIFICATION_POLLING_INTERVAL, 'notification/getUnreadCount')

/**
 * A version that does not use static methods, but extends from Polling.
 * Use this for better code reuse.
 */
class StaminaPolling extends Polling {
    constructor () {
        super(STAMINA_POLLING_INTERVAL, 'user/updateStamina')
    }
    /**
     * @override
     * Override the method to retain the logic to initiate requests based on remain. 
     */
    start () {
        const id = this.timerId++
        this.timerObj[id] = true
        const timerFn = async () => {
            if (!this.timerObj[id]) return
            await this.update()
            if (document.visibilityState !== 'visible') {
                document.addEventListener('visibilitychange', this.updateHookEvent)
            }
            const user = store.state.user.user
            // update at remaining time
            if (user && user.stamina) {
                const remain = user.stamina.remain
                if (Number.isInteger(remain) &&
                    remain > 0 &&
                    remain * 1000 < this.interval) {
                    setTimeout(() => {
                        this.update()
                    }, remain * 1000)
                }
            }
            setTimeout(timerFn, this.interval)
        }
        timerFn()
    }
}

export const staminaPolling = new StaminaPolling()