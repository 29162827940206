<template>
  <div
    class="d-flex mx-sm-auto"
    style="overflow-x: auto; overflow-y: hidden;"
  >
    <div
      class="d-flex"
      style="gap: 0.5rem"
    >
      <v-btn
        class="rounded-pill px-2"
        color="grey darken-2"
        :to="{
          name: 'gallery'
        }"
        :ripple="false"
        exact
        text
        height="32px"
        @click="() => $emit('changeSearch', [])"
      >
        <v-icon
          class="mr-1"
          small
        >
          mdi-home
        </v-icon>
        {{ $t('gallery.views.home') }}
      </v-btn>
      <v-btn
        class="rounded-pill px-2"
        color="grey darken-2"
        :to="{
          name: 'gallery-crypko'
        }"
        :ripple="false"
        exact
        text
        height="32px"
        @click="() => $emit('changeSearch', [])"
      >
        <v-icon
          class="mr-1"
          small
        >
          mdi-face-man
        </v-icon>
        {{ $t('general.crypko') }}
      </v-btn>
      <v-btn
        class="rounded-pill px-2"
        color="grey darken-2"
        :to="{
          name: 'gallery-travel'
        }"
        :ripple="false"
        exact
        text
        height="32px"
      >
        <v-icon
          class="mr-1"
          small
        >
          mdi-image-album
        </v-icon>
        {{ $t('header.router.travel') }}
      </v-btn>
    </div>
    <v-dialog max-width="768">
      <template #activator="{ on }">
        <v-btn
          class="rounded-pill px-0"
          text
          style="position: absolute; right: 16px"
          min-width="32px"
          height="32px"
          v-on="on"
        >
          <v-icon size="20px">
            mdi-magnify
          </v-icon>
        </v-btn>
      </template>
      <v-card style="display: flex; align-items: center; justify-content: center; padding: 1rem">
        <v-combobox
          v-model="searchQuery"
          class="combobox rounded-pill"
          :append-icon="null"
          multiple
          chips
          autocomplete
          single-line
          clearable
          hide-details
          :items="searchItems"
          dense
          flat
          :hide-no-data="inputValue === ''"
          filled
          :filter="filter"
          :label="$t('search.hint')"
          prepend-inner-icon="mdi-magnify"
          @input="updateValue"
          @update:search-input="updateSearchInput"
        >
          <template #no-data>
            <div
              v-if="inputValue[0] !== '#'"
              class="pa-2"
            >
              {{ $t(`search.no-data`) }}
            </div>
            <div
              v-else
              class="pa-2"
            >
              {{ $t(`search.search-by-hash`) }}
            </div>
          </template>
          <template #selection="{ attrs, item, selected }">
            <v-chip
              v-bind="attrs"
              :color="`${item.color} lighten-3`"
              :input-value="selected"
              label
              small
            >
              <v-icon
                left
                small
              >
                {{ getIcon(item) }}
              </v-icon>
              {{ tagDisplayName(item) }}
            </v-chip>
          </template>
          <template #item="{ item }">
            {{ tagDisplayName(item) }}
          </template>
        </v-combobox>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import tagData from '@/utils/tags'
export default {
  name: 'gallery-header',
  props: {},
  data: function () {
    return {
      searchQuery: null,
      inputValue: '',
    }
  },
  computed: {
    searchItems () {
      if (this.inputValue === '') {
        return []
      }
      const res = Object.keys(tagData).filter(key => {
        // Filter by tag name in all languages
        const text = Object.values(tagData[key]).join(' ')
        // Regex fuzzy search
        return new RegExp(`(.*)(${this.inputValue.split('').join(')(.*)(')})(.*)`, 'i').test(text)
      }).splice(0, 5).map((value) => ({
        value: value,
        text: this.inputValue, // This field is required for vuetify internal processing.
      }))
      return res
    },
  },
  // for debugging
  // watch: {
  //   inputValue (val) {
  //     console.log((val))
  //   },
  // },
  created () {
    if (this.$route.query.search) {
      this.searchQuery = this.$route.query.search.split(',')
    }
  },
  methods: {
    updateSearchInput (val) {
      if (val !== null) this.inputValue = val
    },
    filter () {
      return true
    },
    tagDisplayName (tag) {
      let value = tag
      if (typeof tag === 'object') {
        value = tag.value
      }
      if (tagData[value] && tagData[value][this.$i18n.locale]) {
        return tagData[value][this.$i18n.locale]
      } else {
        console.log((value))
        if (value[0] === '#') return value.slice(1)
        return value
      }
    },
    getIcon (tag) {
      if (typeof tag === 'string') {
        if (Object.keys(tagData).includes(tag)) return 'mdi-tag'
        else if (tag.startsWith('#')) return 'tag'
        else return 'mdi-text'
      }
      if (Object.keys(tagData).includes(tag.value)) return 'mdi-tag'
      else return 'mdi-text'
    },
    updateValue: function (queryValues) {
      // for (const v of queryValues) {
      //   if (typeof v === 'function') {
      //     v() // execute function in value field
      //   }
      // }
      const newQuery = queryValues.map(d => {
        if (typeof d === 'string') return d.replace(/^#/, '')
        return d.value
      }).filter(item => typeof item === 'string'
        && item !== ''
        && item !== ','
        && item !== ' '
        && item !== '#')
      this.$emit('changeSearch', newQuery)
      // this.$refs.input.blur() // hide options
      this.inputValue = ''
    },
    getItemText (key) {
      return Object.keys(tagData[key]).map(locate => {
        return tagData[key][locate]
      }).join(' ')
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep {
  .v-input__slot::before {
    border-style: none !important;
  }

  .v-input__slot::after {
    border-style: none !important;
  }

  .v-label {
    color: #bbb;
  }
}

.combobox {
  width: 500px;

  @media #{map-get($display-breakpoints, 'xs-only')} {
    width: 360px;
  }
}

.v-autocomplete__content.v-menu__content .v-select-list {
  border-radius: 8px;
}
</style>

<style>
.v-menu__content.v-autocomplete__content {
  box-shadow: 0 0 8px -1px rgba(0, 0, 0, 0.1);
  margin-top: 8px;
  border-radius: 8px;
}
.v-btn--active::before {
  opacity: 0.1 !important;
}
</style>