<template>
  <prompt-dialog-wrapper ref="freemiumChatDialog">
    <confirm-options-dialog
      icon="mdi-chat-alert"
      :title="$t('dialog.chat-freemium.title')"
      :msg="$t('payment.feature-plan-unavailable')"
      :btn-colors="['primary', null, null]"
      :options="[$t('dialog.chat-freemium.actions.0'), $t('payment.view-plans'), $t('general.label.cancel')]"
    />
  </prompt-dialog-wrapper>
</template>

<script>
import { mapState } from 'vuex'
import PromptDialogWrapper from '@/widgets/PromptDialogWrapper'
import ConfirmOptionsDialog from '@/widgets/ConfirmOptionsDialog'

export default {
  name: 'freemium-chat-confirm-dialog',
  components: {
    PromptDialogWrapper,
    ConfirmOptionsDialog,
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapState('user', ['user']),
  },
  methods: {
    async show () {
      if (this.user.isFreemium()) {
        const res = await this.$refs.freemiumChatDialog.show()
        if (res === 0) {
          open(process.env.VUE_APP_PLAYGROUND_HOSTNAME, '_blank')
        } else if (res === 1) {
          this.$router.push({ name: 'plans' })
        }
        return
      }
      this.$router.push({
        name: 'crypko',
        params: {
          hash: this.crypko.hash,
        },
        query: {
          chat: true,
        },
      })
    },
  },
}
</script>

<style scoped lang="scss">
</style>
