<template>
  <v-dialog
    v-model="dialog"
    :max-width="maxWidth"
    :persistent="persistent"
    :scrollable="scrollable"
    :hide-overlay="hideOverlay"
  >
    <slot />
  </v-dialog>
</template>

<script>
// https://stackoverflow.com/questions/56026220/how-to-programmatically-launch-a-vuetify-dialog-and-wait-for-the-response
export default {
  name: 'prompt-dialog-wrapper',
  provide () {
    return {
      confirm: this.confirm,
      confirmWithParam: this.confirmWithParam,
      cancel: this.cancel,
    }
  },
  props: {
    maxWidth: {
      type: String,
      default: '300px',
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
    hideOverlay: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      dialog: false,
      resolve: null,
      reject: null,
    }
  },
  watch: {
    dialog () {
      if (!this.dialog) {
        this.cancel()
      }
    },
  },
  methods: {
    show () {
      this.dialog = true
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    /**
     * The ability to pass values in dialogs has been added here.
     * Because of some use case like `@click="confirm"` exists.
     * To reduce logic changes, The first argument is the event object.
     * The second argument is custom data.
     */
    confirm (_event, val = true) {
      this.resolve(val)
      this.dialog = false
    },
    confirmWithParam (param) {
      this.resolve(param)
      this.dialog = false
    },
    cancel () {
      this.resolve(false)
      this.dialog = false
    },
  },
}
</script>

<style scoped>
::v-deep .v-dialog {
   margin: 12px !important;
}
</style>