import { getField, updateField } from 'vuex-map-fields'
export default {
  namespaced: true,
  state: {
    showChatDialog: false,
  },
  mutations: {
    updateField,
  },
  getters: {
    getField,
  },
}