<template>
  <v-dialog
    v-model="dialog"
    max-width="700"
    scrollable
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar
          color="primary"
          dark
          height="45"
          elevation="0"
        >
          <v-toolbar-title>{{ $t('header.router.help') }}</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              class="close"
              icon
              dark
              @click.stop="dialog = false"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
      </v-card-title>
      <v-card-text class="pt-6">
        <component :is="helpName" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { CREATE_ROUTE_NAME } from '@/utils/constants'
import HelpGenerate from '@/components/HelpGenerate'
import HelpFuse from '@/components/HelpFuse'
import HelpAttrs from '@/components/HelpAttrs'
import HelpCanvas from '@/components/HelpCanvas'
import HelpPose from '@/components/HelpPose'
import HelpVariation from '@/components/HelpVariation'
import HelpInterpolate from '@/components/HelpInterpolate'

export default {
  name: 'workbench-help-dialog',
  components: {
    HelpGenerate,
    HelpFuse,
    HelpAttrs,
    HelpCanvas,
    HelpPose,
    HelpVariation,
    HelpInterpolate,
  },
  data () {
    return {
      CREATE_ROUTE_NAME,
      dialog: false,
      helpName: 'help-generate',
    }
  },
  methods: {
    openDialog () {
      const name = this.$route.name
      switch (name) {
        case CREATE_ROUTE_NAME.GENERATE:
          this.helpName = 'help-generate'
          break
        case CREATE_ROUTE_NAME.FUSE:
          this.helpName = 'help-fuse'
          break
        case CREATE_ROUTE_NAME.ATTRS:
          this.helpName = 'help-attrs'
          break
        case CREATE_ROUTE_NAME.VARIATION:
          this.helpName = 'help-variation'
          break
        case CREATE_ROUTE_NAME.CANVAS:
          this.helpName = 'help-canvas'
          break
        case CREATE_ROUTE_NAME.KEYPOINT:
          this.helpName = 'help-pose'
          break
        case CREATE_ROUTE_NAME.PROJECT:
          this.helpName = 'help-project'
          break
        case CREATE_ROUTE_NAME.INTERPOLATE:
          this.helpName = 'help-interpolate'
          break
        default:
          return
      }
      this.dialog = true
    },
  },
}
</script>
