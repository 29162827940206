<script setup>
import { computed, ref, provide, watch} from 'vue'
import LoadingProgressCircular from '@/widgets/LoadingProgressCircular.vue'
import AlbumImgDialog from '@/widgets/AlbumImgDialog.vue'
import AlbumWaterfallItem from './AlbumWaterfallItem.vue'
import Crypko from '@/utils/crypko'

const props = defineProps({
    loading: {
      type: Boolean,
      default: true,
    },
    title: {
        type: String,
        default: null,
    },
    icon: {
        type: String,
        default: null,
    },
    data: {
      type: Array,
      default: ()=> [],
    },
})

const raw = computed(()=>props.data)

const data = ref(props.data.map(d=>{
  return {
    ...d,
    crypko: new Crypko(d.crypko),
  }
}))

watch(raw, (newVal)=>{
  data.value = newVal.map(d=>{
    return {
      ...d,
      crypko: new Crypko(d.crypko),
    }
  })
})



const to = '/gallery/travel'
const selectingAlbum = ref(null)
provide('selectingAlbum', selectingAlbum)

const onRemoved = () => {
  selectingAlbum.value = null
}

function setLike (hash, liked){
  const index = data.value.findIndex(d=>d.hash === hash)
  if (index !== -1){
    data.value[index].liked = liked
    data.value[index].likes += liked ? 1 : -1
  }
}
provide('setLike', setLike)




</script>

<template>
  <v-card
    class="wrapper fill-width"
    elevation="0"
    :rounded="$vuetify.breakpoint.mdAndUp ? 'lg' : '0'"
  >
    <v-card-title class="d-flex pb-2 align-center px-0">
      <v-sheet
        v-if="icon"
        class="d-flex justify-center align-center mr-1"
        color="primary"
        width="24px"
        height="24px"
        rounded="circle"
      >
        <v-icon
          color="white"
          size="15px"
        >
          {{ icon }}
        </v-icon>
      </v-sheet>
      <div class="text-h6 grey--text text--darken-2 font-weight-bold">
        <slot name="title">
          {{ title }}
        </slot>
      </div>
      <v-spacer />
      <v-btn
        class="px-1"
        elevation="0"
        text
        :to="to"
      >
        {{ $t('general.label.more') }}
      </v-btn>
    </v-card-title>
    <v-divider />
    <v-card-text class="px-0">
      <v-row
        v-if="!loading"
        :dense="$vuetify.breakpoint.mdAndUp ? false : true"
      >
        <v-col
          v-for="datum in data"
          :key="datum.id"
          cols="6"
          md="3"
        >
          <AlbumWaterfallItem
            :data="datum"
            height="200px"
          />
        </v-col>  
      </v-row>
      <loading-progress-circular
        v-else  
        class="mb-8"
      />
    </v-card-text>
    <AlbumImgDialog
      :album="selectingAlbum"
      @closed="selectingAlbum = null"
      @removed="onRemoved"
    />
  </v-card>
</template>

  
<style scoped lang="scss">
.wrapper {
    background-color: #0000;
}
</style>
  