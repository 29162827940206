import Crypko from '../../utils/crypko'
import { getField, updateField } from 'vuex-map-fields'
import { MODELS, GEN_MODEL_CONFIG } from '../../utils/constants'

function getDefaultState () {
  return {
    crypkos: Crypko.getPlaceholders(GEN_MODEL_CONFIG[MODELS.HIBISCUS].default_batchsize),
    ganModel: MODELS.HIBISCUS,
    selectedCrypkos: [],
    genSettings: { norm_pose: false },
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    updateField,
    setGanModel: (state, model) => {
      if (!(model in GEN_MODEL_CONFIG)) {
        throw new Error('Invalid generation model:', model)
      }
      state.ganModel = model
      if (Crypko.isEmpty(state.crypkos)) {
        state.crypkos = Crypko.getPlaceholders(GEN_MODEL_CONFIG[model].default_batchsize)
      }
    },
  },
  getters: {
    getField,
  },
}