<template>
  <div>
    <v-btn
      block
      :color="userCurrentlyFollowed ? '#e0e0e0' : 'primary'"
      elevation="0"
      :outlined="!userCurrentlyFollowed"
      rounded
      v-bind="btnProps"
      @click.prevent="follow(!userCurrentlyFollowed)"
    >
      {{ userCurrentlyFollowed ? $t('general.label.following') : $t('general.label.follow') }}
    </v-btn>
  </div>
</template>

<script>
import Dialog from '@/utils/dialog'

export default {
  name: 'follow-btn',
  props: {
    user: {
      type: Object,
      default: null,
    },
    btnProps: {
      type: Object,
      default: () => {},
    },
  },
  data () {
    return {
      userCurrentlyFollowed: this.user.followed,
    }
  },
  computed: {
  },
  watch: {
    user () {
      this.userCurrentlyFollowed =  this.user.followed
    },
  },
  methods: {
    async follow (flag=true) {
      try {
        await this.user.follow(flag)
        this.userCurrentlyFollowed = flag
      } catch (e) {
        Dialog.showFailedDialog()
      }
    },
  },
}
</script>
