<template>
  <div class="d-flex align-center justify-center">
    <vue-loading
      type="cylon"
      color="#f27677"
      :size="{ width: size, height: size }"
    />
  </div>
</template>

<script>
import { VueLoading } from 'vue-loading-template'

export default {
  name: 'loading-progress-circular',
  components: {
    VueLoading,
  },
  props: {
    size: {
      type: String,
      default: '40px',
    },
    color: {
      type: String,
      default: 'primary',
    },
  },
  data () {
    return {}
  },
}
</script>

<style scoped>
</style>