import Vue from 'vue'
import i18n from '@/i18n'

export default class Dialog {

  static dialog () {
    return Vue.prototype.$dialog
  }

  static showSuccessDialog (msg=i18n.t('general.msg.succeeded'), timeout=2000) {
    this.dialog().notify.success(msg, {
      position: 'bottom-right',
      timeout,
    })
  }

  static showFailedDialog (msg=i18n.t('general.msg.failed'), timeout=3000) {
    this.dialog().notify.error(msg, {
      position: 'bottom-right',
      timeout,
    })
  }

  static showErrorDialog ({title=i18n.t('general.label.error'), text=i18n.$t('general.msg.error')}) {
    this.dialog().error({
      title,
      text,
    })
  }

  static async showDeleteDialog (title=i18n.t('general.msg.delete'), text='') {
    return await this.dialog().confirm({
      title,
      text,
    })
  }

  static async showTextDialog (title='Edit', text='', maxLength=12) {
    return await this.dialog().prompt({
      title,
      text,
      rules: [
        v => !!v || 'required',
        v => v.length <= maxLength || 'must be less than 12 characters',
      ],
      textField: {
        // Any addtional props/attrs that will be binded to v-text-field component
        // type: 'password',
        counter: maxLength,
      },
    })
  }

  static async showConfirmDialog (title, text) {
    return await this.dialog().confirm({
      title,
      text,
    })
  }
}