<template>
  <div class="crypko-browser__wrapper d-flex flex-column">
    <div
      v-if="crypkos"
      class="crypko-browser__header d-flex align-center mb-4"
    >
      <v-spacer />
      <multi-select-btn
        v-if="selectable && !isGallery"
        :key="JSON.stringify($route.query)"
        class="mr-3"
        :selection="selectedCrypkos"
        @click="onMultiSelectClick"
      />
      <browser-sort
        v-if="!isGallery || isGallerySearch"
        class="mr-3"
        :ordering="query.ordering"
        :ordering-types="browsCategory"
        @change="updateOrdering"
      />
      <browser-filter
        v-if="!isGallery || isGallerySearch"
        v-model="filterParams"
        :model-fixed="modelFixed"
        :filtered-model="modelsFiltered ? modelsFiltered : modelConstraints"
        :filtered-type="typesFiltered"
        :model-constraints="modelConstraints"
        :filtered-privacy="privacyArray"
        @change="updateFilter"
      />
    </div>
    <loading-progress-circular
      v-if="loading"
      class="flex-grow-1 pt-8"
    />
    <p
      v-else-if="error || !crypkos"
      class="error-msg"
    >
      {{ error || $t('general.msg.empty') }}
    </p>
    <div v-else-if="crypkos && crypkos.length">
      <crypko-grid
        ref="crypkoGrid"
        :card-props="{
          disableLink: disableLink || selecting,
          displayName: true,
          dropShadow: true,
          favIcon: true,
        }"
        :crypkos="crypkos"
        :selectable="selecting"
        :selection="selection"
        v-on="$listeners"
        @selection="updateSelectedCrypkos"
      />
    </div>
    <div v-else>
      {{ $t('general.msg.empty') }}
    </div>
    <pagination
      v-if="totalMatched"
      class="mt-5 mb-10"
      :page="query.page"
      :pagination-length="paginationLength"
      @change="updatePageWrapper"
    />
  </div>
</template>

<script>
import CrypkoGrid from '@/components/CrypkoGrid'
import Crypko from '@/utils/crypko'
import { mapFields } from 'vuex-map-fields'
import BrowserSort from '@/widgets/BrowserSort'
import BrowserFilter from '@/widgets/BrowserFilter'
import Pagination from '@/widgets/Pagination'
import LoadingProgressCircular from '@/widgets/LoadingProgressCircular'
import MultiSelectBtn from '@/widgets/MultiSelectBtn.vue'
import { browser } from '@/mixins/browser'
import { GALLERY_ROUTE_NAME } from '@/utils/constants'

const DEFAULT_QUERY = {
  ordering: '-created',
  page: 1,
}

export default {
  name: 'crypko-browser',
  components: {
    LoadingProgressCircular,
    CrypkoGrid,
    Pagination,
    BrowserSort,
    BrowserFilter,
    MultiSelectBtn,
  },
  mixins: [browser],
  props: {
    disableLink: {
      type: Boolean,
      default: false,
    },
    defaultQuery: {
      type: Object,
      default: () => DEFAULT_QUERY,
    },
    modelFixed: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Boolean,
      default: true,
    },
    useRoute: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      browsCategory: 'crypko',
      crypkos: null,
      error: null,
      filterParams: {},
      loading: false,
      localRoute: {
        params: {},
        query: {},
      },
      selectedCrypkos: [],
      selecting: false,
      totalMatched: null,
      query: {},
    }
  },
  computed: {
    ...mapFields('carousel', { carouselCrypkos: 'crypkos' }),
    ...mapFields('generate', ['ganModel']),
    ownNoCrypko () {
      return this.$_.isEmpty(this.query.search) &&
        this.$_.isEmpty(this.query.filter)
    },
    selection () {
      return this.selectedCrypkos
        ? this.selectedCrypkos
          .map(crypko => this.crypkos.indexOf(crypko))
        : []
    },
    isGallery () {
      return this.$route.name && this.$route.name.startsWith('gallery')
    },
    isGallerySearch () {
      return this.$route.name && this.$route.name === GALLERY_ROUTE_NAME.SEARCH
    },
  },
  watch: {
    crypkos () {
      if (!this.crypkos) return
      this.carouselCrypkos = JSON.parse(JSON.stringify(this.crypkos)).map(c => new Crypko(c))
    },
  },
  methods: {
    async init () {
      // Important to clear all crypkos to forbid Vue from reusing CrypkoImg component!
      // Might not be performance optimized but less error-prone
      this.crypkos = null

      this.error = null
      this.loading = true
      let data
      try {
        const params = this.stringifyQuery(this.query)
        if (this.owned) {
          params.owner = this.user.id
        } else if (this.route.params.id) {
          params.owner = this.route.params.id
        }
        if (!this.isGallery || this.isGallerySearch) {
          data = await Crypko.getList(params)
        } else {
          data = await Crypko.getGalleryView(this.$route.name, params)
        }
        this.crypkos = data.crypkos
        this.totalMatched = data.count
      } catch (e) {
        this.error = this.$t('header.error.unknownError')
        console.error(
          'Error get crypkos: ',
          e.response,
          e,
        )
        return
      }
      this.error = null
      this.loading = false
    },
    // searchByID (str) {
    //   this.$router.push('/card/' + str)
    // },
    onMultiSelectClick (v) {
      this.selectedCrypkos = []
      this.selecting = v
    },
    updateSelectedCrypkos (selection) {
      this.selectedCrypkos = selection.map(idx => this.crypkos[idx])
    },
    updatePageWrapper (page) {
      this.updatePage(page)
      this.selectedCrypkos = []
      this.selecting = false
    },
  },
}
</script>

<style scoped lang="scss">
.crypko-browser {
  &__wrapper {
    max-width: map-get($sizes, main-body-max-width);
    margin: auto;
    text-align: center; /* for alert messages */
  }
  &__header {
    max-width: 100%;
  }
}
</style>
