<template>
  <v-dialog
    v-model="stampCardDialog"
    content-class="task-dialog"
    max-width="600"
    scrollable
  >
    <v-card>
      <v-toolbar
        class="flex-column"
        elevation="0"
      >
        <v-toolbar-title class="d-flex align-center">
          <v-icon class="mr-3">
            mdi-calendar-check
          </v-icon>
          <span>
            {{ $t('stamp.loginBonus') }}
          </span>
        </v-toolbar-title>
        <v-spacer />
        <div
          v-if="startAt && endAt"
          class="text-body-2"
        >
          {{ `${startAt} ~ ${endAt} (JST)` }}
        </div>
      </v-toolbar>
      <v-card-text
        v-if="!loading"
        class="pa-3"
        style="overflow: hidden"
      >
        <div v-if="noData">
          <v-img
            lazy-src="/images/404_s.png"
            src="/images/404.png"
            class="mx-auto"
            height="200px"
            width="200px"
          />
          <div class="text-center">
            {{ $t('stamp.preparing') }}
          </div>
        </div>
        <div
          v-else
          class="reward-box-container"
        >
          <v-sheet
            v-for="(reward, i) in rewards"
            :key="reward.id"
            class="reward-box d-flex flex-column justify-center"
          >
            <div class="reward-index">
              {{ i + 1 }}
            </div>
            <v-icon
              v-if="reward.gained"
              class="reward-icon"
              :class="gained_reward === reward.id ? 'stamp-icon' : ''"
              color="light-green"
            >
              mdi-check-circle
            </v-icon>
            <v-icon
              v-else-if="reward.type === 'stamina'"
              class="reward-icon"
              color="secondary"
            >
              mdi-alpha-c-circle
            </v-icon>
            <v-icon
              v-else-if="reward.type === 'slot'"
              class="reward-icon"
              color="secondary"
            >
              mdi-tray-plus
            </v-icon>
            <v-icon
              v-else
              class="reward-icon"
            >
              mid-help-circle-outline
            </v-icon>
            <div class="reward-label text-center">
              {{ $t(`general.${reward.type}`) }} x{{ reward.amount }}
            </div>
          </v-sheet>
        </div>
        <div
          v-if="!loading && !noData"
          class="text-center mt-2"
        >
          {{ $t('stamp.next-stamp-in', [timeRemain]) }}
        </div>
        <div class="d-flex justify-center mt-3">
          <v-btn
            color="#fdf1e7"
            style="color: #fcb071"
            elevation="0"
            rounded
            @click="onShowTaskClick"
          >
            <v-icon
              left
            >
              mdi-checkbox-multiple-marked
            </v-icon>
            <span class="font-weight-bold">
              {{ $t('header.avatar.daily-task') }}
            </span>
          </v-btn>
        </div>
      </v-card-text>
      <v-card-text v-else>
        <loading-progress-circular />
      </v-card-text>
      <v-card-text
        v-if="!PROD_MODE"
        class="pa-3"
      >
        <div
          style="background-color: #fafafa; border-radius: 12px"
          class="d-flex justify-space-between align-center px-4"
        >
          <v-menu
            ref="menu"
            v-model="datePickerMenu"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="debugDate"
                style="max-width: 200px"
                label="pick date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="debugDate"
              no-title
              scrollable
            />
          </v-menu>
          <v-btn
            color="light-green"
            dark
            elevation="0"
            @click="onDupCardClick"
          >
            dup stamp card
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { APIRequest } from '@/utils'
import { mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import LoadingProgressCircular from '@/widgets/LoadingProgressCircular'
import { PROD_MODE } from '@/utils/constants'

export default {
  name: 'stamp-card-dialog',
  components: {
    LoadingProgressCircular,
  },
  data () {
    return {
      PROD_MODE,
      NEXT_UPDATE_TIME: null,
      loading: false,
      startAt: null,
      endAt: null,
      rewards: null,
      gained_reward: null,
      timer: null,
      timeRemain: '??:??:??',
      tomorrow: null,
      noData: false,
      datePickerMenu: false,
      debugDate: null,
    }
  },
  computed: {
    ...mapFields(['stampCardDialog', 'dailyTaskDialog']),
    ...mapState('user', ['user']),
  },
  watch: {
    async user (new_user, old_user) {
      if (!(old_user && new_user && old_user.id === new_user.id)) {
        // reset
        this.loading = false
        this.rewards = null
        this.startAt = null
        this.endAt = null
        this.gained_reward = null
        if (this.timer) {
          clearInterval(this.timer)
          this.timer = null
        }
        this.tomorrow = null
        this.noData = false
      }
      if ((new_user && new_user.is_verified && (!old_user || old_user.id !== new_user.id || !old_user.is_verified))) {
        await this.checkAndShowDialog()
      }
    },
    async debugDate () {
      await this.checkAndShowDialog()
    },
    async $route (to, from) {
      if (from.fullPath.includes('/auth/') && !to.fullPath.includes('/auth/')) {
        await this.checkAndShowDialog()
      }
    },
  },
  async mounted () {
    this.checkAndShowDialog()
  },
  methods: {
    async checkAndShowDialog () {
      if (this.loading || !this.user || window.location.pathname.includes('/auth/')) return
      this.loading = true

      const params = {}
      if (this.debugDate && !PROD_MODE) {
        params.date = Math.round((new Date(this.debugDate + 'T00:00:00.000+09:00')).getTime() / 1000)
      }

      const request = new APIRequest('/stamp/card/', params)
      const { data, status } = await request.send()
      if (!data || status === 204) {
        this.noData = true
        this.loading = false
        this.startAt = null
        this.endAt = null
        return
      }
      this.rewards = data.rewards
      this.noData = false
      const parseDate = date => new Date(parseInt(date) * 1000)
        .toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' })
        .split(' ')[0].slice(5)
      this.startAt = parseDate(data.start_at)
      this.endAt = parseDate(data.end_at)
      this.loading = false
      this.gained_reward = data.gained_reward
      if (this.gained_reward !== null) {
        this.stampCardDialog = true
        // play confetti animation
        const FragClassName = 'stamp-dialog-confetti-frag'
        setTimeout(() => {
          const el = document.getElementsByClassName('stamp-icon').item(0)
          if (!el) return
          const c = document.createDocumentFragment()
          for (let i = 0; i < 50; i++) {
            const styles = '\
                    transform: translate3d(' + (Math.random() * 200 - 100) + 'px, ' + (Math.random() * 200 - 100) + 'px, 0) rotate(' + Math.random() * 360 + 'deg);\
                    background: hsla('+ Math.random() * 360 + ',100%,50%,1);\
                    -webkit-animation-name: bang;\
                    -webkit-animation-fill-mode: forwards;\
                    -webkit-animation-timing-function: ease-out;\
                    -webkit-animation-duration: 700ms;\
                    -webkit-animation-delay: 500ms;\
                    '
            const e = document.createElement('i')
            e.classList.add(FragClassName)
            e.style.cssText = styles.toString()
            c.appendChild(e)
          }
          el.append(c)
        }, 700)
        setTimeout(() => {
          const frags = document.getElementsByClassName(FragClassName)
          while (frags.length > 0) {
            frags[0].parentNode.removeChild(frags[0])
          }
        }, 2500)
        // reset gained_reward to prevent playing animation everytime
        setTimeout(() => {
          this.gained_reward = null
        }, 2000)
        this.$store.dispatch('user/updateCurrentUser')
        this.$store.dispatch('notification/getUnreadCount')
      }
      this.startStampCountDown()
    },
    clearTimer () {
      if (this.timer) {
        clearInterval(this.timer)
      }
    },
    setTomorrow () {
      this.tomorrow = new Date(new Date().toLocaleString({ timeZone: 'Asia/Tokyo' }))
      this.tomorrow.setHours(24, 0, 0, 0)
    },
    startStampCountDown () {
      this.clearTimer()
      this.setTomorrow()
      // https://stackoverflow.com/a/68941165
      this.timer = setInterval(() => {
        const second = 1000
        const minute = second * 60
        const hour = minute * 60
        // get time in JST
        const end = this.tomorrow.getTime()
        const now = new Date().getTime()
        const timeLeft = end - now

        const hours = Math.floor(timeLeft / hour)
        const minutes = Math.floor((timeLeft % (hour)) / (minute))
        const seconds = Math.floor((timeLeft % (minute)) / second)

        if (timeLeft < 0) {
          clearInterval(this.timer)
          this.setTomorrow()
          this.checkAndShowDialog()
        } else {
          const zeroPad = num => String(num).padStart(2, '0')
          this.timeRemain = `${zeroPad(hours)}:${zeroPad(minutes)}:${zeroPad(seconds)}`
        }
      }, 1000)
    },
    async onDupCardClick () {
      const request = new APIRequest('/stamp/card/copy/')
      await request.send()
    },
    onShowTaskClick () {
      this.stampCardDialog = false
      this.dailyTaskDialog = true
    },
  },
}
</script>

<style lang="scss">
@keyframes bang {
  from {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.stamp-dialog-confetti-frag {
  position: absolute;
  display: block;
  left: 50%;
  top: 50%;
  width: 3px;
  height: 8px;
  opacity: 0;
}
</style>

<style scoped lang="scss">
.reward-index {
  position: absolute;
  top: 4px;
  left: 4px;
  font-size: 18px;
  color: #a3a3a3;

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    font-size: 14px;
  }
}

.reward-label {
  margin-top: 2px;
  color: #737373;
  white-space: nowrap;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    font-size: 11px;
  }
}

.reward-icon {
  font-size: 48px;
  transition: all 300ms 0s ease;

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    font-size: 36px;
  }
}

.reward-box {
  background: #fafafa;
  border-radius: 12px;
  position: relative;
  height: 120px;
  padding-top: 8px;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    height: 95px;
  }
}

.reward-box-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    grid-template-columns: repeat(4, 1fr);;
  }
  gap: 6px;
}

.stamp-icon {
  -webkit-animation-name: stamp;
  animation-name: stamp;
  animation-delay: 0.3s;
  -webkit-animation-delay: 0.3s;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  opacity: 0;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@keyframes stamp {
  0% {
    opacity: 0.2;
    transform-origin: 50% 50%;
    transform: scale(3);
    transition: all 0.3s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
