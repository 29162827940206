import { render, staticRenderFns } from "./BrowserFilter.vue?vue&type=template&id=bfc31de4&scoped=true&"
import script from "./BrowserFilter.vue?vue&type=script&lang=js&"
export * from "./BrowserFilter.vue?vue&type=script&lang=js&"
import style0 from "./BrowserFilter.vue?vue&type=style&index=0&id=bfc31de4&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bfc31de4",
  null
  
)

export default component.exports