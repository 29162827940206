<template>
  <div
    class="d-flex align-center"
    style="overflow-x: auto; overflow-y: hidden; gap: 0.25rem; max-width: 100%;"
  >
    <model-showcase-dialog
      v-for="model in supportedModels.concat(unavailableSupportedModels)"
      :key="model"
      :model="model"
    >
      <template #default="{ on }">
        <v-chip
          class="primary--text"
          color="rgba(242, 118, 119, 0.1)"
          small
          style="flex-shrink: 0; white-space: nowrap;"
          v-on="on"
        >
          <v-icon
            v-if="unavailableSupportedModels.includes(model)"
            left
            size="14px"
          >
            mdi-lock
          </v-icon>
          {{ $t(`models.${model}`) }}
        </v-chip>
      </template>
    </model-showcase-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getModelConfig, MODEL_ORDER } from '@/utils/constants'
import ModelShowcaseDialog from '@/components/ModelShowcaseDialog'

export default {
  name: 'feature-supported-models',
  components: {
    ModelShowcaseDialog,
  },
  props: {
    feature: {
      // used to display supported models
      // fuse/attrs/... see model_configs.json
      type: String,
      default: null,
    },
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapState('user', ['user']),
    supportedModels () {
      if (!this.feature) return []
      const models = Object.keys(getModelConfig(this.feature)).sort((a, b) => MODEL_ORDER[a] - MODEL_ORDER[b])
      return models.filter(model => (this.user && !this.user.isFreemium()) || !getModelConfig(this.feature)[model].paid)
    },
    unavailableSupportedModels () {
      if (!this.feature) return []
      const models = Object.keys(getModelConfig(this.feature)).sort((a, b) => MODEL_ORDER[a] - MODEL_ORDER[b])
      return models.filter(model => (!this.user || this.user.isFreemium()) && getModelConfig()[model].paid)
    },
  },
}
</script>
