<template>
  <v-sheet
    class="d-flex align-center pa-2 rounded msg-bubble"
    :class="{
      'cursor-pointer': expandable,
    }"
    :color="bgColor"
    :max-width="maxWidth"
    :style="{'--bg-color': bgColor}"
    @click="onSheetClick"
  >
    <v-icon
      :color="fontColor"
      class="mr-2"
      size="16"
    >
      mdi-chat
    </v-icon>
    <div
      v-if="msg.status === 'done'"
      :class="{
        'text-truncate': !isMsgExpanded,
        'text-wrap': isMsgExpanded,
      }"
      style="font-size: 14px; line-height: 1.5; text-align: left;"
      :style="{color: fontColor}"
    >
      {{ msg[$i18n.locale] }}
    </div>
    <v-skeleton-loader
      v-else
      class="mb-0 mx-auto"
      loading
      width="90%"
      type="text"
      height="10"
    />
  </v-sheet>
</template>

<script>
export default {
  name: 'album-msg-bubble',
  components: {
  },
  props: {
    msg: {
      type: Object,
      required: true,
    },
    bgColor: {
      type: String,
      default: 'rgba(242, 118, 119, 0.1)',
    },
    fontColor: {
      type: String,
      default: 'rgba(0, 0, 0, 0.87)',
    },
    maxWidth: {
      type: [String, Number],
      default: '100%',
    },
    expandable: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      isMsgExpanded: false,
    }
  },
  created () {
    this.isMsgExpanded = !this.expandable
  },
  methods: {
    onSheetClick () {
      if (this.expandable) {
        this.isMsgExpanded = !this.isMsgExpanded
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.msg-bubble {
  position: relative;
  margin-bottom: 8px;
}

.msg-bubble::after {
  content: "";
  position: absolute;
  bottom: -8px; /* Adjust according to the size of the triangle */
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid var(--bg-color);
}

::v-deep {
  .v-skeleton-loader__text {
    margin-bottom: 0;
  }
}
</style>