<template>
  <v-dialog
    v-model="dailyTaskDialog"
    content-class="task-dialog"
    max-width="600px"
    scrollable
  >
    <v-card>
      <v-toolbar elevation="0">
        <v-toolbar-title class="d-flex align-center">
          <v-icon class="mr-3">
            mdi-checkbox-multiple-marked
          </v-icon>
          <span>
            {{ $t('dailyTask.title') }}
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          color="light-green lighten-5"
          style="color: #7cb342"
          elevation="0"
          rounded
          @click="onExchangeItemClick"
        >
          <v-icon left>
            $coin-circle
          </v-icon>
          <span>
            {{ $t('general.label.exchange') }}
          </span>
        </v-btn>
      </v-toolbar>
      <v-card-text
        v-if="user.tasks"
        style="display: flex; flex-direction: column; gap: 0.75rem; flex-grow: 1;"
        class="pa-sm-4 pa-2 pb-3"
      >
        <v-hover
          v-for="task in user.tasks"
          :key="task.id"
          v-slot="{ hover }"
        >
          <v-sheet
            :color="task.finished ? (hover ? 'light-green lighten-4' : 'light-green lighten-5') : (hover ? '#ffedd5' : '#fff9f1')"
            class="d-flex align-center flex-wrap pa-2"
            style="border-radius: 8px; row-gap: 0.5rem"
            :style="$vuetify.breakpoint.smAndUp ? 'column-gap: 1rem' : 'column-gap: 0.75rem'"
          >
            <div
              class="d-flex justify-center align-center"
              :style="$vuetify.breakpoint.smAndUp ? 'width: 64px; height: 64px' : 'width: 48px; height: 48px'"
            >
              <v-icon
                v-if="task.reward_claimed"
                color="light-green darken-1"
                size="2rem"
              >
                mdi-check-circle
              </v-icon>
              <v-sheet
                v-else
                class="d-flex flex-column align-center justify-center"
                :color="task.finished ? 'light-green lighten-4' : '#ffedd5'"
                width="100%"
                height="100%"
                rounded="lg"
              >
                <v-icon
                  :color="task.finished ? 'light-green darken-1' : '#fb923c'"
                  size="1.5rem"
                >
                  $coin-circle
                </v-icon>
                <div
                  class="text-caption font-weight-bold mt-sm-0 mt-n1"
                  :style="{
                    color: task.finished ? '#7CB342' : '#fb923c'
                  }"
                >
                  {{ task.task.reward.coin }}
                </div>
              </v-sheet>
            </div>
            <div
              class="d-flex flex-column"
              style="flex-grow: 1; gap: 0.5rem"
            >
              <div style="display: flex; gap: 0.5rem">
                <div style="display: flex; flex-direction: column; gap: 0.25rem; justify-contents: center; flex-grow: 1;">
                  <div class="d-flex justify-space-between align-center">
                    <div
                      :class="{
                        'text-body-2': $vuetify.breakpoint.smAndUp,
                        'text-caption': !$vuetify.breakpoint.smAndUp,
                      }"
                      class="font-weight-bold"
                    >
                      {{ getTitle(task.task) }}
                    </div>
                    <div
                      :class="{
                        'text-body-2': $vuetify.breakpoint.smAndUp,
                        'text-caption': !$vuetify.breakpoint.smAndUp,
                      }"
                    >
                      {{ task.progress }} / {{ task.task.target_count }}
                    </div>
                  </div>
                  <v-progress-linear
                    :value="task.progress / task.task.target_count * 100"
                    :color="task.finished ? 'light-green lighten-1' : '#fdba74'"
                    height="6px"
                    rounded
                  />
                </div>
              </div>
            </div>
            <div class="task-action-btn">
              <!-- やってみる -->
              <v-btn
                v-if="!task.finished"
                style="color: #fb923c;"
                color="#ffedd5"
                width="86px"
                elevation="0"
                rounded
                small
                :disabled="task.finished || task.reward_claimed"
                @click="onTryClick(task.task)"
              >
                {{ $t('general.label.try') }}
              </v-btn>
              <!-- クリア報酬を受け取る -->
              <v-btn
                v-else-if="task.finished && !task.reward_claimed"
                color="light-green darken-1"
                dark
                width="86px"
                elevation="0"
                rounded
                small
                @click="onClaimClick(task.task)"
              >
                {{ $t('general.label.claim') }}
              </v-btn>
              <!-- 受け取り済み -->
              <v-btn
                v-else
                width="86px"
                elevation="0"
                rounded
                small
                disabled
              >
                {{ $t('general.label.claimed') }}
              </v-btn>
            </div>
          </v-sheet>
        </v-hover>
      </v-card-text>
      <v-card-text v-else>
        <loading-progress-circular />
      </v-card-text>
      <v-card-actions
        class="d-flex flex-column align-center"
        style="gap: 0.5rem"
      >
        <v-btn
          v-if="claimAllAvailable"
          class="px-3"
          color="light-green darken-1"
          dark
          elevation="0"
          rounded
          @click="onClaimAllClick"
        >
          {{ $t('general.label.claim-all') }}
        </v-btn>
        <div class="text-caption">
          {{ $t('general.msg.refresh-in', [formattedRemainTime]) }}
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
  
<script>
import { mapFields } from 'vuex-map-fields'
import { mapState } from 'vuex'
import { APIRequest, useFormattedRemainTime } from '@/utils'
import { CREATE_ROUTE_NAME } from '@/utils/constants'
import LoadingProgressCircular from '@/widgets/LoadingProgressCircular'

export default {
  components: { LoadingProgressCircular },
  setup () {
    const formattedRemainTime = useFormattedRemainTime()
    return {
      formattedRemainTime,
    }
  },
  data () {
    return {
      remainSeconds: 0,
      int: null,
    }
  },
  computed: {
    ...mapFields(['dailyTaskDialog']),
    ...mapState('user', ['user']),
    finishedTaskCount () {
      return this.user?.tasks?.filter(task => task.finished).length
    },
    claimAllAvailable () {
      return this.user?.tasks?.some(task => task.finished && !task.reward_claimed)
    },
  },
  watch: {
    dailyTaskDialog (val) {
      if (val) {
        this.updateTasks()
      }
    },
  },
  async mounted () {
    await this.updateTasks()
  },
  beforeDestroy () {
    clearInterval(this.int)
  },
  methods: {
    updateTasks () {
      this.$store.dispatch('user/updateCurrentUser')
    },
    getTitle (task) {
      return this.$t(`dailyTask.action.${task.action}`, [task.target_count])
    },
    onTryClick (task) {
      switch (task.action) {
        case 'fav':
          this.$router.push({ name: 'gallery' })
          this.dailyTaskDialog = false
          break
        case 'fuse':
          this.$router.push({ name: CREATE_ROUTE_NAME.FUSE })
          this.dailyTaskDialog = false
          break
        case 'gen':
        case 'save':
          this.$router.push({ name: CREATE_ROUTE_NAME.GENERATE })
          this.dailyTaskDialog = false
          break
        case 'crossfuse_set':
          this.$router.push({
            name: 'user-home',
            params: {
              id: this.user.id,
            },
          })
          this.dailyTaskDialog = false
          break
        case 'travel':
          this.$router.push({
            name: 'travel',
          })
          this.dailyTaskDialog = false
          break
        default:
          break
      }
    },
    async onClaimClick (task) {
      await new APIRequest('/task/claim/', { task_id: task.id }, 'post').send()
      this.$store.dispatch('user/updateCurrentUser')
    },
    async onClaimAllClick () {
      await new APIRequest('/task/claim/all/', null, 'post').send()
      this.$store.dispatch('user/updateCurrentUser')
    },
    onExchangeItemClick () {
      this.$router.push({ name: 'plans' })
      this.dailyTaskDialog = false
    },
  },
}
</script>

<style lang="scss">
.task-dialog {
  border-radius: 24px;
  margin: 12px;
}
</style>

<style lang="scss" scoped>
.v-progress-linear--rounded {
  border-radius: 999px;
}

::v-deep .v-icon--left {
  margin-right: 2px !important;
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .task-action-btn {
    flex-basis: 100%;
    text-align: center;
  }
}
</style>