// used for memes
import { getField, updateField } from 'vuex-map-fields'

export default {
  namespaced: true,
  state: {
    vidArgs: {
      'blend_frames': 0,
      'fps': 15,
      'duration': 1,  // 生成動画のキャラ変化時間（sec）
      'duration_before': 0,  // 生成動画の変化前の待ち時間（sec）
      'duration_after': 0,  // 生成動画の変化後の待ち時間（sec）
      'resolution': 512,
      'memes_logo': true,
      'crop_face': true,
      'crop_face_scale': 5,
    },
  },
  mutations: {
    updateField,
  },
  actions: {
  },
  getters: {
    getField,
  },
}