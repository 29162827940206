<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    scrollable
  >
    <template #activator="{ on, attrs }">
      <slot v-bind="{ on, attrs }" />
    </template>
    <v-card>
      <v-card-title
        class="d-flex align-center justify-space-between"
        style="gap: 0.5rem;"
      >
        <div
          class="font-weight-bold"
          style="font-weight: 12px; line-height: 24px;"
        >
          {{ $t(`models.${model}`) }}
        </div>
        <div
          class="d-flex"
          style="gap: 0.25rem; overflow-x: auto; overflow-y: hidden;"
        >
          <v-chip
            class="text-caption rounded primary--text"
            color="rgba(242, 118, 119, 0.1)"
            style="flex-shrink: 0"
            small
          >
            {{ $t(`models.desc.${model}`) }}
          </v-chip>
          <v-chip
            class="text-caption rounded primary--text"
            color="rgba(242, 118, 119, 0.1)"
            style="flex-shrink: 0"
            small
          >
            {{ MODEL_CONFIGS[model].paid ? $t('models.paid') : $t('models.free') }}
          </v-chip>
        </div>
      </v-card-title>
      <v-card-text>
        <div
          class="d-flex justify-space-between align-center pt-1"
          style="gap: 0.5rem;"
        >
          <div class="text-caption font-weight-bold text-no-wrap">
            {{ $t('models.supported-feature') }}
          </div>
          <div
            class="d-flex"
            style="gap: 0.25rem; overflow-x: auto; overflow-y: hidden;"
          >
            <v-chip
              v-for="feature in Object.keys(MODEL_CONFIGS[model].feature)"
              :key="feature"
              class="text-caption rounded blue--text text-no-wrap"
              style="flex-shrink: 0"
              color="rgba(33, 150, 243, 0.1)"
              small
            >
              {{ $t(`payment.plan-features.${feature}`) }}
            </v-chip>
          </div>
        </div>
        <div v-if="model !== MODELS.CANVAS">
          <div class="d-flex flex-row align-center my-3">
            <v-divider />
            <span
              style="color:gray; font-size: 14px"
              class="mx-3"
            >
              {{ $t('models.gen-sample') }}
            </span>
            <v-divider />
          </div>
          <div class="image-grid">
            <v-img
              v-for="i in 4"
              :key="i"
              :src="`/images/models/showcase/${model}/${i}.jpg`"
              aspect-ratio="1"
              contain
            />
          </div>
        </div>
        <div
          v-if="model === MODELS.HIBISCUS"
          class="text-center text-caption mt-3"
        >
          <a
            style="color: grey; text-decoration: underline;"
            href="/ja/info/travel_credits.pdf"
            target="_blank"
          >
            Credits
          </a>
        </div>
      </v-card-text>
      <v-card-actions
        class="flex-column"
        style="gap: 0.5rem;"
      >
        <v-btn
          v-if="!hideGenerate && (model in GEN_MODEL_CONFIG)"
          class="d-block fill-width mx-0"
          color="primary"
          elevation="0"
          @click="onGoGenerateClick"
        >
          <v-icon
            v-if="!isModelAvailable"
            left
          >
            mdi-lock
          </v-icon>
          <span>
            {{ $t('general.label.go-gen') }}
          </span>
        </v-btn>
        <v-btn
          class="d-block fill-width mx-0 primary--text"
          color="rgba(242, 118, 119, 0.1)"
          elevation="0"
          @click="dialog = false"
        >
          {{ $t('general.label.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <prompt-dialog-wrapper ref="subscriptionConfirmDialog">
      <confirm-dialog
        :msg="$t('payment.feature-plan-unavailable')"
        :confirm-label="$t('payment.view-plans')"
      />
    </prompt-dialog-wrapper>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import Dialog from '@/utils/dialog'
import { CREATE_ROUTE_NAME, MODELS, GEN_MODEL_CONFIG, MODEL_CONFIGS } from '@/utils/constants'
import PromptDialogWrapper from '@/widgets/PromptDialogWrapper'
import ConfirmDialog from '@/widgets/ConfirmDialog'

export default {
  name: 'model-showcase-dialog',
  components: {
    PromptDialogWrapper,
    ConfirmDialog,
  },
  props: {
    model: {
      type: String,
      default: null,
    },
    hideGenerate: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      Dialog,
      MODELS,
      GEN_MODEL_CONFIG,
      MODEL_CONFIGS,
      dialog: false,
    }
  },
  computed: {
    ...mapState('user', ['user']),
    isModelAvailable () {
      return !(GEN_MODEL_CONFIG[this.model]?.paid && this.user?.isFreemium())
    },
  },
  methods: {
    async onGoGenerateClick () {
      if (!this.isModelAvailable) {
        const res = await this.$refs.subscriptionConfirmDialog.show()
        if (res) {
          const route = this.$router.resolve({
            name: 'plans',
            hash: '#plans',
          })
          window.open(route.href)
        }
        return
      }
      this.$store.commit('generate/setGanModel', this.model)
      this.$router.push({
        name: CREATE_ROUTE_NAME.GENERATE,
      })
    },

  },
}
</script>

<style lang="scss" scoped>
.image-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns per row */
  gap: 16px; /* Adjust the spacing between items */
}

::v-deep .v-dialog {
  margin: 12px !important;
}
</style>