<script setup>
import store from '@/store'
import { computed, inject  } from 'vue'
import { useRoute } from '@/router'
import { APIRequest } from '@/utils'

const props = defineProps({
    album: {
        type: Object,
        required: true,
    },
})
const album = computed(()=>{
  return props.album
})


const likes = computed(()=>album.value.likes)
const liked = computed(()=>album.value.liked)
const route = useRoute()
const setLike = inject('setLike', null)
async function onClick (){
  if(liked.value){ 
    await new APIRequest('/travel/album/unlike/', {
      hash: album.value.hash,
    }).send()
    if (setLike){
      setLike(album.value.hash, false)
    }
  } else {
    await new APIRequest('/travel/album/like/', {
      hash: album.value.hash,
    }).send()
    if (setLike){
      setLike(album.value.hash, true)
    }
  }
  switch(route.name) {
    case 'gallery-travel':
    case 'user-album':
    case 'gallery':
      break
    default:
      store.dispatch('carousel/fetchCrypko')
  }
}
</script>
<template>
  <v-btn
    elevation="0"
    rounded
    small
    :color="liked ? 'red' : '#fff8'"
    :style="{
      color: liked ? 'white' : 'black',
    }"
    @click.stop="onClick"
  >
    <v-icon left>
      mdi-heart-outline
    </v-icon>
    <span>
      {{ likes }}
    </span>
  </v-btn>
</template>