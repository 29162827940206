import Vue from 'vue'
import VueI18n from 'vue-i18n'
// import store from './store'

Vue.use(VueI18n)

function loadLocaleMessages () {
  // use .js instead of .json for 
  const locales = require.context('./UI', true, /[A-Za-z0-9-_,\s]+\.js$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      // get the default object as message
      messages[locale] = locales(key).default
    }
  })
  return messages
}

export function getUserLocale () {
  // let userLang
  // try {
  //   userLang = store.state.lang
  // } catch (e) {
  //   // getting user language from store will fail when Vue is not instantiated
  //   console.log('You should not invoke getUserLocale function before Vue is instantiated!')
  //   userLang = null
  // }

  // https://stackoverflow.com/questions/673905/best-way-to-determine-users-locale-within-browser
  const language =
          // userLang ||
          (navigator.languages && navigator.languages[0]) ||
          navigator.language ||
          navigator.browserLanguage
  switch (language) {
    case 'zh-CN':
    case 'cn':
      return 'cn'
    case 'ja':
      return 'ja'
    default:
      return 'en'
  }
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'ja',
  messages: loadLocaleMessages(),
})
