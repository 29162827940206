<template>
  <div class="follow-layout__wrapper d-flex flex-column">
    <v-container class="follow-layout__content">
      <user-layout-header />
      <div class="tabs-container mt-3">
        <v-btn
          class="float-btn"
          fab
          small
          :to="{
            name: 'user-home',
            params: { id: $route.params.id }
          }"
        >
          <v-icon
            color="primary"
          >
            mdi-arrow-left
          </v-icon>
        </v-btn>
        <v-tabs
          class="d-flex justify-center"
          background-color="#0000"
        >
          <v-tab
            v-for="(btn, btnName) in FOLLOW_NAV_BTN"
            :key="btnName"
            class="follow-layout__tabbar--content mx-0"
            :to="btn.to"
          >
            {{ $t(`header.router.${btn.to.name}`) }}
          </v-tab>
        </v-tabs>
      </div>
      <router-view />
    </v-container>
    <footer-template />
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { FOLLOW_NAV_BTN } from '@/utils/constants'
import FooterTemplate from '@/layout/FooterTemplate'
import UserLayoutHeader from '@/components/UserLayoutHeader'

export default {
  name: 'follow-layout',
  components: {
    FooterTemplate,
    UserLayoutHeader,
  },
  data () {
    return {
      FOLLOW_NAV_BTN,
    }
  },
  computed: {
    ...mapFields('user', ['profileUser']),
  },
  watch: {
    '$route.params.id' () {
      if (!this.profileUser || this.profileUser.id != this.$route.params.id) {
        this.updateCurrentUser()
      }
    },
  },
  created () {
    this.updateCurrentUser()
  },
  methods: {
    async updateCurrentUser () {
      await this.$store.dispatch('user/updateProfileUser', this.$route.params.id)
    },
  },
}
</script>
<style lang="scss" scoped>
.follow-layout {
  &__wrapper {
    height: 100%;
    min-height: calc(100vh - 48px);
  }
  &__tabbar {
    &--content{
      @media screen and (max-width: 599px) {
        font-size: 80%;
      }
    }
  }
  &__content {
    height: 100%;
    max-width: map-get($sizes, main-body-max-width);
  }
}
.float-btn {
  box-shadow: none;
  border-radius: 100%;
  position: absolute;
  left: 0;
  top: 6px;
}
.tabs-container {
  position: relative;
  width: 100%;
}
::v-deep {
  .v-tabs-slider {
    width: 60%;
    margin: 0 auto;
  }
}
</style>