<template>
  <prompt-dialog-wrapper ref="dialog">
    <confirm-dialog
      :msg="$t('payment.feature-plan-unavailable')"
      :confirm-label="$t('payment.view-plans')"
    />
  </prompt-dialog-wrapper>
</template>

<script>
import PromptDialogWrapper from '@/widgets/PromptDialogWrapper'
import ConfirmDialog from '@/widgets/ConfirmDialog'

export default {
  name: 'plan-required-dialog',
  components: {
    PromptDialogWrapper,
    ConfirmDialog,
  },
  data () {
    return {
			dialog: false,
    }
  },
  methods: {
    async show () {
      const res = await this.$refs.dialog.show()
      if (res) {
        const route = this.$router.resolve({ name: 'plans' })
        window.open(route.href, '_blank')
      }
    },
  },
}
</script>
