<template>
  <v-card>
    <v-card-title class="justify-center pt-10 pb-2">
      <v-icon
        :color="iconColor"
        size="80"
      >
        {{ icon }}
      </v-icon>
    </v-card-title>
    <v-card-title class="justify-center text-center text">
      {{ title || $t('general.label.confirm') }}
    </v-card-title>
    <v-card-text>
      <p class="text-center mb-8 text">
        {{ msg }}
      </p>
      <v-btn
        class="btn"
        color="primary"
        elevation="0"
        rounded
        @click="confirm"
      >
        {{ confirmLabel || $t('general.label.confirm') }}
      </v-btn>
      <v-btn
        v-if="cancelable"
        class="btn"
        elevation="0"
        rounded
        @click="cancel"
      >
        {{ cancelLabel || $t('general.label.cancel') }}
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'confirm-dialog',
  inject: ['confirm', 'cancel'],
  props: {
    title: {
      type: String,
      default: null,
    },
    msg: {
      type: String,
      default: null,
    },
    confirmLabel: {
      type: String,
      default: null,
    },
    cancelLabel: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: 'mdi-information',
    },
    iconColor: {
      type: String,
      default: 'secondary',
    },
    cancelable: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {}
  },
}
</script>

<style scoped>
.btn {
  width: 100%;
  margin: 10px auto 0;
}

.text {
  white-space: pre-line;
  word-break: normal;
}
</style>
