import { getField, updateField } from 'vuex-map-fields'

export default {
  namespaced: true,
  state: {
  },
  mutations: {
    updateField,
  },
  actions: {
  },
  getters: {
    getField,
  },
}