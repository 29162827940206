import { getField, updateField } from 'vuex-map-fields'
export default {
  namespaced: true,
  state: {
    tasks: [],
  },
  mutations: {
    updateField,
  },
  getters: {
    getField,
    unclaimedCount (state) {
      return state.tasks.filter(task => task.finished && !task.reward_claimed).length
    },
  },
}