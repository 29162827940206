import { getField, updateField } from 'vuex-map-fields'

export default {
  namespaced: true,
  state: {
    adjustedCrypko: null,
    baseCrypko: null,
    genSettings: {},
    dirtyTags: {},
    freesiaDebugSettings: '{}',
  },
  mutations: {
    updateField,
    setBaseCrypko (state, crypko) {
      state.baseCrypko = crypko
      state.dirtyTags = {}
      state.adjustedCrypko = null
    },
  },
  actions: {
  },
  getters: {
    getField,
  },
}