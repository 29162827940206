import Crypko from '@/utils/crypko'
import { getField, updateField } from 'vuex-map-fields'
import { set } from 'vue'

function getDefaultState () {
  return {
    pair: [null, null],
    crypkos: null,
    selectedCrypkos: [],
    freesiaDebugSettings: '{}',
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    updateField,
    setPair (state, { idx, crypko }) {
      set(state.pair, idx, crypko)
    },
  },
  getters: {
    getField,
    pairSelected: state => !state.pair.some(Crypko.isEmpty),
    pairDirty: state => !Crypko.isEmpty(state.pair),
  },
}