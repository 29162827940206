<template>
  <div class="lang-select">
    <v-select
      v-model="lang"
      :items="langs"
      item-value="name"
      color="textField"
      dense
      flat
      hide-details
      solo
      @change="onChange"
    />
  </div>
</template>

<script>
import User from '@/utils/user'
import { mapFields } from 'vuex-map-fields'
import { mapState } from 'vuex'
import router from '@/router'

export default {
  name: 'language-select',
  data () {
    return {
      langs: [
        {
          name: 'en',
          text: 'English',
        },
        {
          name: 'ja',
          text: '日本語',
        },
        {
          name: 'cn',
          text: '简体中文',
        },
      ],
    }
  },
  computed: {
    ...mapFields(['lang']),
    ...mapState('user', ['user']),
  },
  watch: {
    $route (to ){
      this.lang = ['ja', 'en', 'cn'].includes(to.fullPath.split('/')[1]) ? to.fullPath.split('/')[1] : 'en'
    },
  },
  methods: {
    async onChange (val) {
      this.$i18n.locale = val
      document.querySelector('html').setAttribute('lang', this.$i18n.locale === 'cn' ? 'zh-CN' : this.$i18n.locale)
      if (this.user && this.user.id) {
        await User.patch(this.user.id, {
          data: {
            lang: val,
          },
        })
      }
      const locale = ['ja', 'en', 'cn'].includes(router.currentRoute.fullPath.split('/')[1]) ? router.currentRoute.fullPath.split('/')[1] : ''
      const pathname = locale ? router.currentRoute.fullPath.slice(3) : router.currentRoute.fullPath
      if (locale !== val) {
        router.push({ path: `/${val}${pathname}` })
      }
    },
  },
}
</script>

<style scoped>
.lang-select {
  width: 130px;
  margin: 4px;
}
</style>
