import Crypko from '@/utils/crypko'
import { APIRequest } from '@/utils'
import _ from 'lodash'
import store from '@/store'
import OSSUtils from '@/utils/oss'
import { SUBSCRIPTION_PLANS, LATEST_CHANGELOG_VERSION } from '@/utils/constants'
function getUserSource () {
  return sessionStorage.getItem('source')
}

export default class User {

  constructor (data) {
    if (_.isEmpty(data)) {
      console.error('Do not create empty User!')
      return
    }
    Object.assign(this, data)
  }

  static async register (params) {
    const request = new APIRequest(
      '/auth/register/', params, 'post', false)
    request.supressHTTPError()
    const { data, status } = await request.send()
    if (status === 201) {
      localStorage.setItem('changelogVersion', LATEST_CHANGELOG_VERSION)
      APIRequest.setToken(data.access_token)
      await store.dispatch('user/updateCurrentUser')
    } else {
      throw new Error(data.detail || 'APIError')
    }
  }

  static async oauthCheckToken (login_type, access_token) {
    const request = new APIRequest('/auth/oauth_check_token/', {
      login_type,
      access_token,
    }, 'post', false)
    request.supressHTTPError()
    const { data } = await request.send()
    if (data && 'access_token' in data) {
      APIRequest.setToken(data.access_token)
      await store.dispatch('user/updateCurrentUser')
      return data.access_token
    } else {
      return null
    }
  }

  static async oauthCheckJwt (login_type, jwt) {
    const request = new APIRequest('/auth/oauth_check_jwt/', {
      login_type,
      jwt,
    }, 'post', false)
    request.supressHTTPError()
    const { data } = await request.send()
    if (data && 'access_token' in data) {
      APIRequest.setToken(data.access_token)
      await store.dispatch('user/updateCurrentUser')
      return data.access_token
    } else {
      return null
    }
  }

  static async oauthRegister (params) {
    const request = new APIRequest('/auth/oauth_register/', params, 'post', false)
    request.supressHTTPError()
    const { data, status } = await request.send()
    if (status === 201) {
      APIRequest.setToken(data.access_token)
      await store.dispatch('user/updateCurrentUser')
      return status
    } else if (status === 401) {
      return status
    } else {
      throw new Error(data.detail || 'APIError')
    }
  }

  static async oauthRegisterWithJwt (params) {
    const source = getUserSource() 
    const request = new APIRequest('/auth/oauth_register_with_jwt/', {
      ...params,
      source,
    }, 'post', false)
    request.supressHTTPError()
    const { data, status } = await request.send()
    if (status === 201) {
      localStorage.setItem('changelogVersion', LATEST_CHANGELOG_VERSION)
      APIRequest.setToken(data.access_token)
      await store.dispatch('user/updateCurrentUser')
      return status
    } else if (status === 401) {
      return status
    } else {
      throw new Error(data.detail || 'APIError')
    }
  }

  static async login (email, password, recaptcha) {
    const request = new APIRequest('/auth/login/', {
      email,
      password,
      recaptcha,
    }, 'post', false)
    request.supressHTTPError()
    const { data, status } = await request.send()
    if (status === 200) {
      APIRequest.setToken(data.access_token)
      await store.dispatch('user/updateCurrentUser')
      await Promise.all([
        store.dispatch('user/updateHomeAvatarSrc'),
        store.dispatch('notification/getUnreadCount'),
      ])
    } else {
      throw new Error(data.detail || 'APIError')
    }
  }

  static async logout () {
    await new APIRequest('/auth/logout/', null, 'get', false).send()
    APIRequest.setToken(null)
    OSSUtils.clearToken()

    store.commit('user/setUser', null)
    store.commit('user/setHomeAvatarSrc', null)
  }

  static async verifyEmail (token) {
    const request = new APIRequest(`/auth/verify_email/`, null, 'get', false)
    request.supressHTTPError()
    request.query = { token }
    const { data, status } = await request.send()
    if (status !== 200) {
      throw new Error(data || 'APIError')
    }
  }

  static async verifyPassword (body) {
    const request = new APIRequest(`/auth/verify_password/`, body, 'post', true)
    request.supressHTTPError()
    const resp = await request.send()
    if (resp.status !== 200) {
      throw resp.data
    }
    return resp.data
  }

  static async changeEmail (body) {
    const request = new APIRequest(`/auth/change_email/`, body, 'post', true)
    request.supressHTTPError()
    const resp = await request.send()
    if (resp.status !== 200) {
      throw resp.data
    }
    return resp.data
  }

  static async resendVerifyEmail () {
    const request = new APIRequest('/auth/resend_email/', null, 'get')
    request.supressHTTPError()
    const { data, status } = await request.send()
    if (status !== 200) {
      throw new Error(data || 'APIError')
    }
    return data.email
  }

  static async sendResetPwdEmail (email) {
    let request
    if (email) {
      request = new APIRequest('/auth/send_reset_password_email/', { email }, 'post', false)
    } else {
      request = new APIRequest('/auth/send_reset_password_email/')
    }
    request.supressHTTPError()
    const { data, status } = await request.send()
    if (status !== 200) {
      throw new Error(data || 'APIError')
    }
  }

  static async resetPwd (password, token) {
    const request = new APIRequest('/auth/reset_password/', {
      password,
      token,
    }, 'post', false)
    const { status } = await request.send()
    if (status !== 200) {
      throw new Error('API Error')
    }
  }

  static async getList (params) {
    const request = new APIRequest('/users/', null, 'get')
    request.query = params
    const { data: { results, count, next, previous } } = await request.send()
    const users = results.map(data => new User(data))
    users.forEach(user => {
      user.preview_crypkos = user.preview_crypkos.map(c => {
        c.owner = { id: user.id }
        return Crypko.parse(c)
      })
    })
    return {
      users,
      count,
      next,
      previous,
    }
  }

  static async fetch (id) {
    if (!id) return null
    const { data } = await new APIRequest(`/users/${id}/`, null, 'get').send()
    return new User(data)
  }

  static async getCoins () {
    const { data } = await new APIRequest(`/users/get_coins/`, null, 'get').send()
    return data.coins
  }

  async follow (follow = true) {
    const request = new APIRequest('/users/follow/', {
      id: this.id,
      follow,
    })
    const { status } = await request.send()
    if (status !== 200) {
      throw new Error('API Error')
    }
  }

  static async setAvatar (hash) {
    if (!hash) return
    const { status } = await new APIRequest('/users/set_avatar/', { hash }).send()
    if (status !== 200) {
      throw new Error('API Error')
    }
  }

  static async patch (id, params) {
    if (!params || !id) return null
    const request = new APIRequest(`/users/${id}/`, params, 'patch')
    const { status } = await request.send()
    if (status !== 200) {
      throw new Error('API Error')
    }
  }

  static async getStamina () {
    const request = new APIRequest('/users/get_stamina/', null, 'get')
    const { data } = await request.send()
    return data
  }

  /**
   * Get user's favs or followers rank
   * 
   * @param {'fav' | 'followers' | 'crossfuse' } type 
   * @param {number} limit 
   * @param {number} days
   * @returns {Promise<{id: string, username: string, count: number}[]>} rank list
   */
  static async getRank (type, limit = 10, days = 0) {
    const searchParams = new URLSearchParams({
      type,
      limit,
      days,
    }).toString()
    const path = `/users/get_rank/`
    const url = `${path}?${searchParams}`
    const request = new APIRequest(url, null, 'get')
    const { data } = await request.send()
    return data
  }

  isFreemium () {
    return !this.subscription || this.subscription.plan === SUBSCRIPTION_PLANS.FREEMIUM
  }

  isStarter () {
    return this.subscription && this.subscription.plan === SUBSCRIPTION_PLANS.STARTER
  }

  isStandard () {
    return this.subscription && this.subscription.plan === SUBSCRIPTION_PLANS.STANDARD
  }

  isAboveStandard () {
    return this.subscription && [SUBSCRIPTION_PLANS.STANDARD, SUBSCRIPTION_PLANS.PREMIUM].includes(this.subscription.plan)
  }

  isPremium () {
    return this.subscription && this.subscription.plan === SUBSCRIPTION_PLANS.PREMIUM
  }
}
