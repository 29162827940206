<script setup>
import TravelGalleryView from '@/views/TravelGalleryView.vue'
import GalleryCrypko from '@/components/GalleryCrypko.vue'
</script>
<template>
  <div class="gallery-layout__wrapper d-flex flex-column">
    <v-toolbar
      class="bg-card-shadow"
      absolute
      elevation="0"
      width="100%"
      style="position: fixed; top: 48px"
    >
      <gallery-header @changeSearch="onSearchChange" />
    </v-toolbar>
    <v-container class="pt-14 pt-md-16">
      <div v-if="$route.name === 'gallery'">
        <gallery-home />
      </div>
      <div v-else-if="$route.name === 'gallery-crypko'">
        <GalleryCrypko />
      </div>
      <div
        v-else-if="$route.name === 'gallery-travel'"
        class="pt-4"
      >
        <TravelGalleryView />
      </div>
      <div
        v-else
        class="pt-4"
      >
        <gallery-crypko-browser />
      </div>
    </v-container>
    <footer-template />
  </div>
</template>

<script>
import GalleryHome from '@/components/GalleryHome'
import GalleryCrypkoBrowser from '@/components/GalleryCrypkoBrowser'
import GalleryHeader from '@/widgets/GalleryHeader'
import { mapState } from 'vuex'
import FooterTemplate from '@/layout/FooterTemplate'
import { GALLERY_ROUTE_NAME } from '@/utils/constants'

export default {
  name: 'gallery-layout',
  components: {
    FooterTemplate,
    GalleryCrypkoBrowser,
    GalleryHome,
    GalleryHeader,
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapState('user', ['user']),
  },
  methods: {
    onSearchChange (searchQuery) {
      if (!searchQuery || !searchQuery.length) {
        if (!this.$route.name === 'gallery') {
          this.$router.push({ name: 'gallery' })
        }
        return
      }
      const route = {
        params: { ...this.$route.params },
        query: { ...this.$route.query },
        name: GALLERY_ROUTE_NAME.SEARCH,
      }
      delete route.query.search
      delete route.query.page
      if (Array.isArray(searchQuery) && searchQuery.length > 0) {
        route.query.search = searchQuery.join(',')
      }
      this.$router.push(route)
    },
  },
}
</script>

<style lang="scss" scoped>
.gallery-layout {
  &__wrapper {
    height: 100%;
    min-height: calc(100vh - 48px);
  }
}

.container {
  max-width: map-get($sizes, main-body-max-width);
  width: 100%;
  height: 100%;
}
</style>

<style>
.gallery-layout__wrapper .v-toolbar__content {
  max-width: 1185px !important;
  margin: 0 auto !important;
}
</style>