<template>
  <v-dialog
    v-model="dialog"
    max-width="300"
    persistent
  >
    <v-card>
      <v-card-title class="justify-center pt-10 pb-2">
        <v-icon
          color="secondary"
          size="80"
        >
          mdi-information
        </v-icon>
      </v-card-title>
      <v-card-title class="justify-center text-center text">
        {{ $t('dialog.confirm-terms.title') }}
      </v-card-title>
      <v-card-text>
        <p class="text">
          {{ $t('dialog.confirm-terms.text', ['2024', '3', '21']) }}
        </p>
        <div class="text-center mb-3">
          <router-link
            class="black--text text-decoration-underline"
            target="_blank"
            :to="{ name: 'terms' }"
          >
            {{ $t('general.terms') }}
          </router-link>
        </div>
        <v-btn
          class="btn"
          color="primary"
          elevation="0"
          rounded
          @click="onConfirmClick"
        >
          {{ $t('dialog.confirm-terms.actions.true') }}
        </v-btn>
        <v-btn
          class="btn"
          elevation="0"
          rounded
          @click="onRejectClick"
        >
          {{ $t('dialog.confirm-terms.actions.false') }}
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import User from '@/utils/user'
import { LATEST_TERMS_VERSION } from '@/utils/constants'
import { mapState } from 'vuex'
import { APIRequest } from '@/utils'
import Dialog from '@/utils/dialog'

export default {
  name: 'confirm-terms-dialog',
  data () {
    return {
      dialog: false,
    }
  },
  computed: {
    ...mapState('user', ['user']),
  },
  watch: {
    async user (new_user, old_user) {
      if (!old_user && new_user) {
        this.checkAndShowDialog()
      }
    },
  },
  async mounted () {
    this.checkAndShowDialog()
  },
  methods: {
    checkAndShowDialog () {
      if (!this.user) return
      if (location.pathname.includes('/terms')) return
      const termsVersion = this.user.data.terms
      if (!termsVersion || termsVersion < LATEST_TERMS_VERSION) {
        this.dialog = true
      }
    },
    async onConfirmClick () {
      try {
        await new APIRequest('/users/update_terms/').send()
        this.dialog = false
      } catch (e) {
        Dialog.showFailedDialog()
        console.error(e)
      }
    },
    async onRejectClick () {
      await User.logout()
      this.$router.push({ name: 'signup-signin' })
      this.dialog = false
    },
  },
}
</script>

<style scoped>
.btn {
  width: 100%;
  margin: 10px auto 0;
}

.text {
  white-space: pre-line;
  word-break: normal;
}
</style>
