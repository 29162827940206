import { getDefaultOrderingType, getOrderingTypes } from '@/utils'
import { ITEMS_PER_PAGE, CRYPKO_FILTER_PARAMS } from '../utils/constants'
import { mapState } from 'vuex'

// browsing lifecycle
export const browser = {
  props: {
    owned: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('user', ['user']),
    route () {
      if (this.useRoute) return this.$route
      return this.localRoute
    },
    paginationLength () {
      if (!this.totalMatched) return 0
      return Math.ceil(this.totalMatched / ITEMS_PER_PAGE)
    },
    modelsFiltered () {
      if (CRYPKO_FILTER_PARAMS.MODEL.key in this.query) {
        return this.query[CRYPKO_FILTER_PARAMS.MODEL.key]
      } else {
        return null
      }
    },
    typesFiltered () {
      if (CRYPKO_FILTER_PARAMS.TYPE.key in this.query) {
        return this.query[CRYPKO_FILTER_PARAMS.TYPE.key]
      } else {
        return null
      }
    },
    modelConstraints () {
      if (this.modelFixed) {
        return this.defaultQuery.model
      } else {
        return null
      }
    },
    privacyArray () {
      if (!this.query.public) {
        return []
      } else if (this.query.public === 'true') {
        return ['public']
      } else if (this.query.public === 'false') {
        return ['private']
      }
    },
  },
  provide () {
    return {
      owned: this.owned || this.user.id === this.$route.params.id,
    }
  },
  watch: {
    route () {
      this.parseQueryFromRoute()
    },
    async query () {
      await this.init()
    },
  },
  created () {
    if (this.useRoute) {
      this.localRoute.params = this.$route.params
    }
    this.parseQueryFromRoute()
  },
  methods: {
    updatePage (page) {
      this.update({
        ...this.query,
        page,
      })
    },
    updateOrdering (ordering) {
      this.update({
        ...this.query,
        ordering,
        page: 1,
      })
    },
    updateFilter (filterParams) {
      const query = { ...this.query }
      for (const key in CRYPKO_FILTER_PARAMS) {
        delete query[CRYPKO_FILTER_PARAMS[key].key]
      }
      if (filterParams.privacy.length === 2) {
        delete query.public
        delete filterParams.privacy
      } else {
        const privacyStr = filterParams.privacy[0]
        if (privacyStr === 'public') {
          filterParams.public = ['true']
        } else if (privacyStr === 'private') {
          filterParams.public = ['false']
        }
        delete filterParams.privacy
      }
      this.update({
        ...query,
        ...filterParams,
        page: 1,
      })
    },
    parseQueryFromRoute () {
      const { query } = this.route

      // parse ordering
      let { ordering, page } = query

      if (!ordering || getOrderingTypes(this.browsCategory).indexOf(ordering) === -1) {
        // use default ordering when not-specified/invalid
        ordering = getDefaultOrderingType(this.browsCategory)
      }
      page = page > 1 ? parseInt(page) : 1
      const parsedQuery = {
        ...this.defaultQuery,
        ...query,
        ordering,
        page,
      }

      // * parse other query
      if (query.search) {
        parsedQuery.search = query.search.split(',')
      }

      // * update (only when necessary)
      if (this.$_.isEqual(this.query, parsedQuery)) {
        console.log('crypko-broswer: Identical parsedQuery. Ignored.', parsedQuery)
        return
      }
      this.query = parsedQuery
    },
    update (newQuery) {
      const queryObject = this.stringifyQuery(newQuery)
      if (this.useRoute) {
        this.$router.push({
          query: {
            ...queryObject,
          },
        })
      } else {
        this.localRoute = {
          ...this.localRoute,
          query: queryObject,
        }
      }
    },
    stringifyQuery (query) {
      const queryObject = {}
      Object.keys(query).map(k => {
        const v = query[k]
        queryObject[k] = Array.isArray(v) ? v.toString() : v
      })
      if (Array.isArray(query.search) && query.search.length > 0) {
        queryObject.search = query.search.join(',')
      }
      return queryObject
    },
  },
}