<template>
  <v-card>
    <v-card-title class="justify-center pt-10 pb-2">
      <v-icon
        color="light-green"
        size="80"
      >
        mdi-download
      </v-icon>
    </v-card-title>
    <v-card-title class="justify-center">
      {{ $t('general.label.download') }}
    </v-card-title>
    <v-card-text>
      <div
        class="text-center"
      >
        <p>
          {{ $t('desc') }}
        </p>
        <p v-if="!user || (user.isFreemium() && !purchased)">
          {{ $t('watermark-desc') }}
        </p>
      </div>
      <loading-btn
        :btn-props="{
          elevation: 0,
          rounded: true,
          color: 'f5f5f5',
        }"
        class="btn"
        :click-action="() => onDownloadClick(false)"
        :disabled="processingRequest"
        show-failure-msg
        @click-action-done="onRequestDone($event, false)"
        @action-failed="() => processingRequest = false"
      >
        {{ $t('general.label.download') + '(jpg)' }}
      </loading-btn>
      <loading-btn
        :btn-props="{
          elevation: 0,
          rounded: true,
          color: 'f5f5f5',
        }"
        class="btn"
        :click-action="() => onDownloadClick(true)"
        :disabled="!user || (user.isFreemium() && !purchased) || processingRequest"
        show-failure-msg
        @click-action-done="onRequestDone($event, true)"
        @action-failed="() => processingRequest = false"
      >
        <v-icon
          v-if="!user || (user.isFreemium() && !purchased)"
          class="mr-1"
          color="white"
          small
        >
          mdi-lock
        </v-icon>
        {{ $t('general.label.download') + '(png)' }}
      </loading-btn>
      <v-btn
        class="btn"
        :disabled="processingRequest"
        elevation="0"
        rounded
        @click="onCancel"
      >
        {{ $t('general.label.close') }}
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import LoadingBtn from '@/widgets/LoadingBtn'
import { APIRequest, downloadFromUrl } from '@/utils'
import { MODELS } from '@/utils/constants'

export default {
  name: 'crypko-download-dialog',
  components: {
    LoadingBtn,
  },
  inject: ['cancel'],
  props: {
    crypkos: {
      type: Array,
      default: null,
    },
  },
  data () {
    return {
      MODELS,
      deleted: false,
    }
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapFields('workbench', ['processingRequest']),
    hashes () {
      return this.crypkos.map(crypko => crypko.hash)
    },
    purchased () {
      return this.crypkos.every(crypko => crypko.purchased)
    },
  },
  methods: {
    onDownloadClick (is_png=false) {
      if (!this.hashes || !this.hashes.length) return
      this.processingRequest = true
      const request = new APIRequest('/crypkos/download/', {
        hashes: this.hashes,
        format: is_png ? 'png': 'jpg',
      })
      return request
    },
    async onRequestDone (data, is_png) {
      this.processingRequest = false
      if (data.status !== 200) {
        throw new Error('API Error')
      }
      const url = data.data.url
      if (this.hashes.length > 1) {
        await downloadFromUrl(url, `${this.hashes.length}crypkos.zip`)
      } else if (is_png) {
        await downloadFromUrl(url, `${this.hashes[0]}.png`)
      } else {
        await downloadFromUrl(url, `${this.hashes[0]}.jpg`)
      }
    },
    onCancel () {
      this.processingRequest = false
      this.cancel()
    },
  },
}
</script>

<style scoped>
.btn {
  width: 100%;
  margin: 10px auto 0;
}
</style>

<i18n>
{
  "ja": {
    "desc": "選択したCrypkoをダウンロードしますか？",
    "watermark-desc": "※フリーミアムプランではライセンス購入していない画像に透かしがあります。"
  },
  "en": {
    "desc": "Do you want to download selected Crypko(s)?",
    "watermark-desc": "※For freemium users, there is watermark on license unpurchased images."
  },
  "cn": {
    "desc": "确认下载选中的Crypko吗？",
    "watermark-desc": "※免费版会员在没有购买商用许可时下载图片含有水印。"
  }
}
