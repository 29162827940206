<script setup>
import { computedAsync } from '@vueuse/core'
const hibiscusCrypkos = computedAsync(async ()=>{
  return await Crypko.getGallerySectionData('hibiscus')
})
const crossfuseCrypkos = computedAsync(async ()=>{
  return await Crypko.getGallerySectionData('crossfuse')
})
const followingCrypkos = computedAsync(async ()=>{
  return await Crypko.getGallerySectionData('following')
})
const chattableCrypkos = computedAsync(async ()=>{
  return await Crypko.getGallerySectionData('chattable')
})
const gerberaCrypkos = computedAsync(async ()=>{
  return await Crypko.getGallerySectionData('gerbera')
})
const freesiaCrypkos = computedAsync(async ()=>{
  return await Crypko.getGallerySectionData('freesia')
})
const ericaCrypkos = computedAsync(async ()=>{
  return await Crypko.getGallerySectionData('erica')
})

</script>
<template>
  <div
    class="wrapper mx-auto d-flex flex-column align-center"
    style="gap: 8px"
  >
    <gallery-crypko-section
      :crypkos="hibiscusCrypkos"
      :title="$t(`gallery.views.${GALLERY_VIEW_NAME.HIBISCUS}`)"
      :to="{ name: GALLERY_ROUTE_NAME.HIBISCUS}"
      icon="mdi-account"
    />
    <gallery-crypko-section
      :crypkos="crossfuseCrypkos"
      :to="{ name: GALLERY_ROUTE_NAME.CROSSFUSE }"
      icon="mdi-vector-combine"
    >
      <template #title>
        <span>
          {{ $t(`gallery.views.${GALLERY_VIEW_NAME.CROSSFUSE}`) }}
        </span>
        <v-btn
          class="mt-n1"
          icon
          small
          :to="{
            path: '/help#crossfuse',
          }"
        >
          <v-icon>mdi-help-circle-outline</v-icon>
        </v-btn>
      </template>
    </gallery-crypko-section>
    <gallery-crypko-section
      :crypkos="followingCrypkos"
      :title="$t(`gallery.views.${GALLERY_VIEW_NAME.FOLLOWING}`)"
      :to="{ name: GALLERY_ROUTE_NAME.FOLLOWING }"
      icon="mdi-account-multiple"
    />
    <gallery-crypko-section
      :crypkos="chattableCrypkos"
      :title="$t(`gallery.views.${GALLERY_VIEW_NAME.CHATTABLE}`)"
      :to="{ name: GALLERY_ROUTE_NAME.CHATTABLE }"
      icon="mdi-chat"
    />
    <gallery-crypko-section
      :crypkos="gerberaCrypkos"
      :title="$t(`gallery.views.${GALLERY_VIEW_NAME.GERBERA}`)"
      :to="{ name: GALLERY_ROUTE_NAME.GERBERA }"
      icon="mdi-human-handsdown"
    />
    <gallery-crypko-section
      :crypkos="freesiaCrypkos"
      :title="$t(`gallery.views.${GALLERY_VIEW_NAME.FREESIA}`)"
      :to="{ name: GALLERY_ROUTE_NAME.FREESIA }"
      icon="mdi-account"
    />
    <gallery-crypko-section
      :crypkos="ericaCrypkos"
      :title="$t(`gallery.views.${GALLERY_VIEW_NAME.ERICA}`)"
      :to="{ name: GALLERY_ROUTE_NAME.ERICA }"
      icon="mdi-face-man"
    />
  </div>
</template>

<script>
import GalleryCrypkoSection from './GalleryCrypkoSection.vue'
import Crypko from '@/utils/crypko'
import { GALLERY_ROUTE_NAME, GALLERY_VIEW_NAME } from '@/utils/constants'

export default {
  name: 'gallery-home',
  components: {
    GalleryCrypkoSection,
  },
  data () {
    return {
      GALLERY_ROUTE_NAME,
      GALLERY_VIEW_NAME,
      crypkos: null,
    }
  },
}
</script>
