<template>
  <div class="bottom-toolbar">
    <v-card
      :class="{
        'bottom-toolbar__phone': $vuetify.breakpoint.xsOnly,
      }"
      class="rounded-pill my-auto pa-2"
      elevation="3"
    >
      <slot />
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'bottom-toolbar',
  data () {
    return {
    }
  },
}
</script>

<style scoped lang="scss">
.bottom-toolbar {
  display: flex;
  bottom: 30px;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 4;
  justify-content: center;
  padding-left: 16px;
  padding-right: 16px;

  &__phone {
    bottom: 50px;
  }
}
</style>