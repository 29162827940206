<template>
  <div class="d-flex">
    <crypko-batch-btns
      v-if="$vuetify.breakpoint.smAndUp && selecting"
      class="mr-3"
      :crypkos="selection"
    />
    <v-btn
      class="light-btn"
      :class="{
        'light-btn--selecting': selecting
      }"
      color="grey darken-2"
      elevation="0"
      rounded
      text
      @click="onBtnClick"
    >
      {{ $t('general.label.select') }}
    </v-btn>
    <bottom-toolbar
      v-if="$vuetify.breakpoint.xsOnly"
      v-show="selecting"
    >
      <template #default>
        <v-tooltip
          v-model="showCrypkoCount"
          top
          nudge-top="5"
        >
          <template #activator="{}">
            <crypko-batch-btns :crypkos="selection" />
          </template>
          <span>{{ crypkoCount }}</span>
        </v-tooltip>
      </template>
    </bottom-toolbar>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import BottomToolbar from '@/widgets/BottomToolbar'
import CrypkoBatchBtns from '@/widgets/CrypkoBatchBtns'
import _ from 'lodash'

export default {
  name: 'multi-select-btn',
  components: {
    BottomToolbar,
    CrypkoBatchBtns,
  },
  props: {
    selection: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      selecting: false,
      showCrypkoCount: false,
    }
  },
  computed: {
    ...mapState('user', ['user']),
    crypkoCount () {
      // return this.selection.length > 0 ? `(${this.selection.length})` : ''
      if (this.selection.length < 2) {
        return `${this.selection.length} Crypko`
      } else {
        return `${this.selection.length} Crypkos`
      }
    },
    crypkoNotOwned () {
      return this.selection.some(crypko => !crypko || crypko.owner.id !== this.user.id)
    },
  },
  watch: {
    'selection.length' () {
      if (this.selection.length || this.selecting) {
        this.showCrypkoCount = true
        this.hideCrypkoCount(this)
      }
    },
  },
  methods: {
    hideCrypkoCount: _.debounce(self => {
      self.showCrypkoCount = false
    }, 1500),
    onBtnClick () {
      this.selecting = !this.selecting
      if (!this.selecting) {
        this.showCrypkoCount = false
      }
      this.$emit('click', this.selecting)
    },
  },
}
</script>

<style scoped lang="scss">
.light-btn {
  background-color: #eeeeee !important;

  &--selecting {
    background-color: map-get($colors, crypko-card-border-selected) !important;
  }
}
</style>
