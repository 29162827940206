import { getField, updateField } from 'vuex-map-fields'
import { MIN_WINDOW_WIDTH } from '@/utils/constants'
import FingerprintJS from '@fingerprintjs/fingerprintjs'



export default {
  namespaced: true,
  state: {
    carouselIndex: 0,
    drawerRight: null,
    drawerLeft: null,
    windowSize: {
      x: null,
      y: null,
    },
    processingRequest: false,
    fingerprint: null,
  },
  mutations: {
    updateField,
    setRightDrawer (state, flag) {
      state.drawerRight = flag
    },
    setFingerprint (state, fingerprint) {
      state.fingerprint = fingerprint
    },
  },
  actions: {
    async setFingerprint ({ commit }) {
      // Initialize FingerprintJS and get the fingerprint data
      // eslint-disable-next-line import/no-named-as-default-member
      const fp = await FingerprintJS.load()
      const { visitorId } = await fp.get()
      commit('setFingerprint', visitorId)
    },
  },
  getters: {
    getField,
    windowTooNarrow: (state) => {
      return state.windowSize < MIN_WINDOW_WIDTH
    },
  },
}