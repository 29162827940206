import { APIRequest } from '.'
import Crypko from './crypko'


export default class List {

  static async getList (params) {
    const request = new APIRequest('/lists/', null, 'get')
    request.query = params
    const { data: { results: lists, count, next, previous } } = await request.send()
    for (let i = 0; i < lists.length; i++) {
      lists[i].preview_crypkos = await Promise.all(lists[i].preview_crypkos.map(c => Crypko.parse(c)))
      for (let j = lists[i].preview_crypkos.length; j < 4; j++) {
        lists[i].preview_crypkos.push(Crypko.PLACEHOLDER)
      }
    }
    return {
      lists,
      count,
      next,
      previous,
    }
  }

  static async fetch (id, params) {
    if (!id) return null
    const route = `/lists/${id}/`
    const request = new APIRequest(route, null, 'get')
    request.query = params
    const { data } = await request.send()
    data.results = data.results.map(x => Crypko.parse(x))
    return data
  }

  static async fetchFav (user, params) {
    if (!user) return null
    const route = `/faved-crypkos/`
    const request = new APIRequest(route, null, 'get')
    request.query = {
      user,
      ...params,
    }
    const { data } = await request.send()
    data.results = data.results.map(x => Crypko.parse(x))
    return data
  }

  static async getListsForCrypkos (hashes) {
    const request = new APIRequest('/lists/lists_for_crypkos/', { hashes })
    const { data } = await request.send()
    return data
  }

  static async updateListsForCrypkos (hashes, lists) {
    if (!hashes || !lists) return
    const request = new APIRequest('/lists/update_lists_for_crypkos/', {
      hashes,
      lists,
    })
    const { status } = await request.send()
    if (status !== 200) {
      throw new Error('API Error')
    }
  }

  static async create (settings) {
    if (!settings.name) return
    if (settings.public == null) settings.public = false
    const { status } = await new APIRequest('/lists/', settings).send()
    if (status !== 201) {
      throw new Error('API Error')
    }
  }

  static async delete (id) {
    if (!id) return
    const request = new APIRequest(`/lists/${id}/`, null, 'delete')
    const { status } = await request.send()
    if (status !== 204) {
      throw new Error('API Error')
    }
  }

  static async update (id, params) {
    if (!params || !id) return
    const request = new APIRequest(`/lists/${id}/`, params, 'patch')
    const { status } = await request.send()
    if (status !== 200) {
      throw new Error('API Error')
    }
  }
}