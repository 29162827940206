import _ from 'lodash'


export const N_ENCODED_SEG_LAYER = 4  // RGBA
const SEGMENTATION_LAYERS = [
  //  a good color picker: https://color.hailpixel.com
  {
    name: 'background',
    color: '#EEEEEE',
    visible: false,
  },
  {
    name: 'backHair',
    color: '#825460',
    visible: true,
  },
  {
    name: 'skin',
    color: '#F1C27D',
    visible: true,
  },
  {
    name: 'face',
    color: '#FFE0BD',
    visible: true,
  },
  {
    name: 'clothes',
    color: '#1F4C9E',
    visible: true,
  },
  {
    name: 'eyeWhite',
    color: '#FFFFFF',
    visible: true,
  },
  {
    name: 'eyeBlack',
    color: '#956332',
    visible: true,
  },
  {
    name: 'eyelashes',
    color: '#000033',
    visible: true,
  },
  {
    name: 'nose',
    color: '#39C5BB',
    visible: true,
  },
  {
    name: 'mouth',
    color: '#EE9495',
    visible: true,
  },
  {
    name: 'frontHair',
    color: '#9E7D78',
    visible: true,
  },
  {
    name: 'eyebrows',
    color: '#454517',
    visible: true,
  },
]

// assign identical values for each layer
for (const layer of SEGMENTATION_LAYERS) {
  Object.assign(layer, {
    visible: true,
    mixRatio: 100,
  })
}

export const N_SEG_LAYER = SEGMENTATION_LAYERS.length


// e.g. 4 -> '00000100'
function int2byteBinary (integer) {
  return ('00000000' + integer.toString(2)).substr(-8)
}

// e.g. '100' -> 128  (because 100 will be padded to 10000000)
export function byteBinary2int (byteBinary) {
  return parseInt((byteBinary + '00000000').substr(0, 8), 2) || 0
}

export function parseSegmentation (imageData) {
  const { data: encodedSeg } = imageData
  let segmentations = _.times(N_SEG_LAYER, () => [])

  for (let pxIdx = 0; pxIdx < encodedSeg.length / N_ENCODED_SEG_LAYER; pxIdx++) {
    let segmentationBits = ''
    // 100ms
    for (let layerIdx = 0; layerIdx < N_SEG_LAYER / 8; layerIdx++) {
      segmentationBits += int2byteBinary(encodedSeg[pxIdx * N_ENCODED_SEG_LAYER + layerIdx])
    }
    // segmentationBits = segmentationBits.slice(0, N_SEG_LAYER)
    // 150ms
    segmentations.forEach((segmentation, segIdx) => {
      segmentation.push(parseInt(segmentationBits[segIdx]))
    })
  }
  // freeze to speed up!
  // https://github.com/vuejs/vue/issues/4384#issuecomment-265776921
  segmentations = segmentations.map(segmentation => Object.freeze([segmentation]))
  return segmentations
}

// adapted from https://gist.github.com/jon-hall/2fc30039629ef22bc95c

const steps = [[1, 0], [0, 1], [0, -1], [-1, 0]]
// const fill_ways = steps.length

function isColor (imageData, offset, color) {
  return [0, 1, 2, 3].every(i => imageData[offset + i] === color[i])
}

// TODO: the following FloodFill algorithm can be optimized
// newColor: RGBA for new color.
export function floodFill (canvas, point, newColor) {
  const ctx = canvas.getContext('2d')
  const width = canvas.width
  const height = canvas.height
  const coord2offset = new Function('x', 'y', 'return 4 * (y * ' + width + ' + x)')
  const points = [point]
  const seen = new Set()

  // add alpha if missing
  if (newColor.length === 3) {
    newColor.push(255)
  }

  const imageData = ctx.getImageData(0, 0, width, height)
  const { data } = imageData
  const pointOffset = coord2offset(point.x, point.y)
  const oldColor = data.slice(pointOffset, pointOffset + 4)

  if (isColor(oldColor, 0, newColor)) return

  // Keep going while we have points to walk
  while (points.length) {
    const { x, y } = points.pop()
    const offset = coord2offset(x, y)

    // Move to next point if pixel is not 
    // TODO: is only needed for filling different colors!
    // make it faster when filling within a single label by only comparing alpha
    if (isColor(data, offset, newColor)) {
      continue
    }

    // Update the pixel to the fill oldColor and add neighbours onto stack to traverse 
    // the fill area
    for (let i = 0; i < 4; i++) {
      // Use the same loop for setting RGBA as for checking the neighbouring pixels!!
      data[offset + i] = newColor[i]

      // Get the new coordinate by adjusting x and y based on current step
      const nextX = x + steps[i][0]
      const nextY = y + steps[i][1]
      const nextPoint = nextX + ',' + nextY

      // If new coordinate is out of bounds, or we've already added it, then skip to 
      // trying the next neighbour without adding one
      if (nextX < 0 || nextY < 0 || nextX >= width || nextY >= height || seen.has(nextPoint)) {
        continue
      }

      // Push neighbour onto points array to be processed, and tag as seen
      points.push({
        x: nextX,
        y: nextY,
      })
      seen.add(nextPoint)
    }
  }
  ctx.putImageData(imageData, 0, 0)
}

export function getMinBoundary (canvas) {
  const ctx = canvas.getContext('2d')
  const { data } = ctx.getImageData(0, 0, canvas.width, canvas.height)
  let left = 0,
    top = 0,
    right = canvas.width,
    bottom = canvas.height
  const coord2alphaOffset = new Function('x', 'y', 'return 4 * (y * ' + canvas.width + ' + x) + 3')
  let isBoundary = false
  while (left < right) {
    for (let y = 0; y < bottom; y++) {
      if (data[coord2alphaOffset(left, y)]) {
        isBoundary = true
        break
      }
    }
    if (isBoundary) break
    left++
  }

  isBoundary = false
  while (left < right) {
    for (let y = 0; y < bottom; y++) {
      if (data[coord2alphaOffset(right, y)]) {
        isBoundary = true
        break
      }
    }
    if (isBoundary) break
    right--
  }

  isBoundary = false
  while (top < bottom) {
    for (let x = left; x < right; x++) {
      if (data[coord2alphaOffset(x, top)]) {
        isBoundary = true
        break
      }
    }
    if (isBoundary) break
    top++
  }

  isBoundary = false
  while (top < bottom) {
    for (let x = left; x < right; x++) {
      if (data[coord2alphaOffset(x, bottom)]) {
        isBoundary = true
        break
      }
    }
    if (isBoundary) break
    bottom--
  }
  return {
    x: left,
    y: top,
    w: right - left,
    h: bottom - top,
  }
}

// Command Manager in command pattern
// The commands it manages are actually SegmentationLayers
class EditCommandManager {
  constructor () {
    this.init()
  }

  init () {
    this._commands = []
    this._commandIdx = -1
  }

  store (command) {
    if (this._commandIdx < this._commands.length - 1) {
      // override old commands
      this._commands.splice(this._commandIdx + 1)
    }
    this._commands.push(command)
    this._commandIdx++
  }

  undo () {
    if (this._commandIdx < 0) {
      console.log('No more command to undo!')
      return
    }
    // every command consists of a series of actions on a layer
    const { layer, actions } = this._commands[this._commandIdx--]
    layer.undo(actions)
  }

  redo () {
    if (this._commandIdx >= this._commands.length - 1) {
      console.log('No more command to redo!')
      return
    }
    const { layer, actions } = this._commands[++this._commandIdx]
    layer.redo(actions)

  }

  get isDirty () {
    return !!this._commands.length
  }

  get currentCommand () {
    return this._commands[this._commandIdx]
  }
}
export const editCommandManager = new EditCommandManager()
window.editCommandManager = editCommandManager

export { SEGMENTATION_LAYERS }