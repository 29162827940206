<template>
  <div>
    <v-img
      src="/images/travel/sample/christmas.jpg"
      max-width="100%"
      max-height="300px"
    />
    <v-card-title
      class="py-0"
      style="font-size: 1rem; font-weight: 600;"
    >
      {{ $t('changelogs.241213.title') }}
    </v-card-title>
    <v-card-text class="py-0 grey--text">
      2024/12/13 ~ 2024/12/25
    </v-card-text>
    <v-card-text
      class="py-0"
      style="white-space: pre-line;"
    >
      {{ $t('changelogs.241213.desc') }}
      <v-btn
        class="d-block mt-4 mx-auto font-weight-bold"
        color="primary"
        large
        dark
        elevation="0"
        @click="onBtnClick"
      >
        {{ $t('general.label.try-now') }}
      </v-btn>
    </v-card-text>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
  components: {
  },
  data () {
    return {}
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapFields(['changelogDialog', 'dailyTaskDialog']),
  },
  methods: {
    onBtnClick () {
      this.changelogDialog = false
      this.$router.push({ name: 'travel' })
    },
  },
}
</script>

<style scoped>
</style>