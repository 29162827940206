<template>
  <div
    class="d-flex flex-column"
    style="gap: 2rem;"
  >
    <div class="text-center">
      <v-chip
        class="primary--text"
        color="rgba(242, 118, 119, 0.1)"
      >
        <h2>
          {{ $t('general.label.feature') }}
        </h2>
        <v-divider
          class="mx-2 py-2"
          style="border-color: #fff"
          vertical
        />
        <h2>
          {{ $t('header.router.create-variation') }}
        </h2>
      </v-chip>
      <div class="mt-2">
        {{ $t(`help.create.variation.desc`) }}
      </div>
    </div>
    <help-supported-models feature="variation" />
    <div>
      <p>{{ $t(`help.create.variation.1`) }}</p>
      <v-img
        :max-width="HELP_IMAGE_MAX_WIDTH"
        src="/images/help/variation1.png"
      />
    </div>
    <div>
      <p>{{ $t(`help.create.variation.2`) }}</p>
      <v-img
        :max-width="HELP_IMAGE_MAX_WIDTH"
        src="/images/help/variation2.png"
      />
      <v-img
        src="/images/help/variation3.png"
        :max-width="HELP_IMAGE_MAX_WIDTH"
      />
    </div>
    <div>
      <p>{{ $t(`help.create.variation.3`) }}</p>
      <v-img
        src="/images/help/variation4.png"
        :max-width="HELP_IMAGE_MAX_WIDTH"
      />
    </div>
    <div>
      <p>{{ $t(`help.create.variation.4`) }}</p>
      <v-img
        src="/images/help/variation5.png"
        :max-width="HELP_IMAGE_MAX_WIDTH"
      />
    </div>
  </div>
</template>

<script>
import { HELP_IMAGE_MAX_WIDTH } from '@/utils/constants'
import HelpSupportedModels from '@/widgets/HelpSupportedModels'

export default {
  name: 'help-variation',
  components: {
    HelpSupportedModels,
  },
  data () {
    return {
      HELP_IMAGE_MAX_WIDTH,
    }
  },
}
</script>

<style lang="scss" scoped>
.v-image {
  margin: 0 0 20px;
}
</style>
