<script setup>
import User from '@/utils/user'
import { computed } from 'vue'

const props = defineProps({
  user: User,
})


const coins = computed(()=>{
  return props.user.coins
})
</script>
<template>
  <v-sheet
    class="d-flex justify-center align-center px-2"
    style="font-size: 12px; gap: 0.25rem"
    color="rgba(139, 195, 74, 0.1)"
    rounded="lg"
    height="32px"
  >
    <v-icon
      color="light-green"
    >
      $coin-circle
    </v-icon>
    <span class="light-green--text font-weight-bold">
      {{ coins ?? 0 }}
    </span>
  </v-sheet>
</template>