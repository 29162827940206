import vuetify from '@/plugins/vuetify'
import axios from 'axios'
import _ from 'lodash'
import Vue from 'vue'
import AsyncComputed from 'vue-async-computed'
import VueAxios from 'vue-axios'
import App from './App.vue'
import i18n from './i18n'
import router from './router'
import store from './store'
import { APIRequest } from './utils'
import { staminaPolling, notificationPolling } from './utils/polling'
import Crypko from './utils/crypko'
import VuetifyDialog from 'vuetify-dialog'
import 'vuetify-dialog/dist/vuetify-dialog.css'
import { DEV_MODE } from './utils/constants'
import VueSocialSharing from 'vue-social-sharing'
import autoAnimate from '@formkit/auto-animate'

Vue.config.productionTip = false

i18n.silentTranslationWarn = true

// - use plugins
Vue.use(AsyncComputed)
Vue.use(VueAxios, axios)
Vue.use(VuetifyDialog, {
  context: {
    vuetify,
  },
  confirm: {
    title: i18n.t('general.msg.unsaved'),
    text: i18n.t('general.msg.overwrite'),
    actions: {
      false: i18n.t('general.label.no'),
      true: {
        text: i18n.t('general.label.yes'),
        color: 'primary',
      },
    },
    width: 400,
  },
})
Vue.use(VueSocialSharing)
Vue.directive('auto-animate', {
  inserted: function (el, binding) {
    autoAnimate(el, binding.value)
  },
})

// - Set up Axios
if (!DEV_MODE) {
  store.state.API_HOSTNAME = process.env.VUE_APP_API_HOSTNAME
}
axios.defaults.baseURL = store.state.API_HOSTNAME
axios.defaults.withCredentials = true

// // Set up global error handler
// // https://github.com/axios/axios/issues/1600#issuecomment-454013644
// axios.interceptors.response.use(function (response) {
//   // Do nothing with response data
//   return response
// }, function (error) {
//   // https://github.com/axios/axios#handling-errors
//   if (error.response) {
//     // The request was made and the server responded with a status code
//     // that falls out of the range of 2xx
//     // We use DRF so error message will be in `data.detail`
//     const { response } = error
//     console.log(response.status + ' API error: ' + response.data.detail)
//   } else if (error.request) {
//     // `error.request` is an instance of XMLHttpRequest in the browser 
//     // and an instance of http.ClientRequest in node.js
//     console.log('The request was made but no response was received:', error.message, error.request)
//   } else {
//     console.log('Something happened in setting up the request that triggered an Error', error.message)
//   }
//   return Promise.reject(error)
// })

// - register Vue instance properties
// by this way we can use `this.$xxx` in every component
// https://stackoverflow.com/questions/37694243/using-lodash-in-all-of-vue-component-template
for (const [property, value] of Object.entries({
  $_: _,
  // DEV: allow inline console log 
  $console: console,
  // WARNING: event bus is error-prone. Use Vuex whenever possible.
  // sample usage: trigger a method of a faraway component
  $eventBus: new Vue(),
  $EVENT: {
    RESET_EMOTE_POSITION: 'reset_emote_position',
  },
})) {
  Object.defineProperty(Vue.prototype, property, { value })
}

(async () => {
  await APIRequest.checkAndRefreshToken()
  await store.dispatch('user/updateCurrentUser')
  new Vue({
    vuetify,
    router,
    store,
    i18n,
    render: h => h(App),
  }).$mount('#app')
})()

// Old version of polling
// StaminaPolling.start()

// Auto update current user's stamina
staminaPolling.start()
// Auto update unread notifications at regular intervals 
notificationPolling.start()

// alert user before leaving or refreshing
// https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
window.addEventListener('beforeunload', function (e) {
  const { state } = store
  const isWorkbenchDirty = !Crypko.isEmpty([
    ...state.generate.crypkos,
    ...(state.fuse.crypkos || []),
    state.attributes.adjustedCrypko,
    state.edit.editedCrypko,
  ])
  if (isWorkbenchDirty && !DEV_MODE) {
    // Cancel the event
    e.preventDefault()
    // Chrome requires returnValue to be set
    e.returnValue = ''
  } else {
    // the absence of a returnValue property on the event will guarantee the browser unload happens
    delete e['returnValue']
  }
})

// Set up user source
const searchStr = window.location.search
const params = new URLSearchParams(searchStr)
const source = params.get('source')
if (source)
  sessionStorage.setItem('source', source)