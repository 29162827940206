<template>
  <div>
    <v-img
      src="/images/updates/240823/banner.jpg"
      max-width="100%"
      max-height="300px"
    />
    <v-card-title
      class="py-0"
      style="font-size: 1rem; font-weight: 600;"
    >
      {{ $t('changelogs.240823.title') }}
    </v-card-title>
    <v-card-text class="py-0 grey--text">
      2024/08/23
    </v-card-text>
    <v-card-text
      class="py-0"
      style="white-space: pre-line;"
    >
      {{ $t('changelogs.240823.desc') }}
      <div
        class="d-flex align-center pt-1"
        style="gap: 0.5rem;"
      >
        <div class="text-caption text-no-wrap">
          {{ $t('models.supported-feature') }}
        </div>
        <div
          class="d-flex"
          style="gap: 0.25rem; overflow-x: auto; overflow-y: hidden;"
        >
          <v-chip
            v-for="feature in Object.keys(MODEL_CONFIGS[MODELS.HIBISCUS].feature)"
            :key="feature"
            class="text-caption rounded blue--text text-no-wrap"
            style="flex-shrink: 0"
            color="rgba(33, 150, 243, 0.1)"
            small
          >
            {{ $t(`payment.plan-features.${feature}`) }}
          </v-chip>
        </div>
      </div>
      <v-btn
        class="d-block mt-4 mx-auto font-weight-bold"
        color="primary"
        large
        dark
        elevation="0"
        @click="onBtnClick"
      >
        {{ $t('general.label.try-now') }}
      </v-btn>
    </v-card-text>
    <prompt-dialog-wrapper ref="subscriptionConfirmDialog">
      <confirm-dialog
        :msg="$t('payment.feature-plan-unavailable')"
        :confirm-label="$t('payment.view-plans')"
      />
    </prompt-dialog-wrapper>
  </div>
</template>

<script>
import PromptDialogWrapper from '@/widgets/PromptDialogWrapper'
import ConfirmDialog from '@/widgets/ConfirmDialog'
import { mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import { CREATE_ROUTE_NAME, MODELS, MODEL_CONFIGS } from '@/utils/constants'

export default {
  components: {
    PromptDialogWrapper,
    ConfirmDialog,
  },
  data () {
    return {
      MODEL_CONFIGS,
      MODELS,
    }
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapFields(['changelogDialog']),
  },
  methods: {
    onBtnClick () {
      this.changelogDialog = false
      this.$store.commit('generate/setGanModel', MODELS.HIBISCUS)
      this.$router.push({ name: CREATE_ROUTE_NAME.GENERATE })
    },
  },
}
</script>

<style scoped>
</style>