<template>
  <div
    class="d-flex flex-column"
    style="gap: 2rem;"
  >
    <div class="text-center">
      <v-chip
        class="primary--text"
        color="rgba(242, 118, 119, 0.1)"
      >
        <h2>
          {{ $t('general.label.feature') }}
        </h2>
        <v-divider
          class="mx-2 py-2"
          style="border-color: #fff"
          vertical
        />
        <h2>
          {{ $t('header.router.create-fuse') }}
        </h2>
      </v-chip>
      <div class="mt-2">
        {{ $t(`help.create.fuse.desc`) }}
      </div>
    </div>
    <help-supported-models feature="fuse" />
    <div>
      <h3 class="mb-2">
        {{ $t('help.usage') }}
      </h3>
      <p>{{ $t(`help.create.fuse.1`) }}</p>
      <p class="primary--text">
        {{ $t(`help.create.fuse.0`) }}
      </p>
      <v-img
        :max-width="HELP_IMAGE_MAX_WIDTH"
        src="/images/help/fuse1.png"
      />
      <v-img
        :max-width="HELP_IMAGE_MAX_WIDTH"
        src="/images/help/fuse2.png"
      />
      <v-img
        :max-width="HELP_IMAGE_MAX_WIDTH"
        src="/images/help/fuse3.png"
      />
    </div>
    <div>
      <p>{{ $t(`help.create.fuse.2`) }}</p>
      <v-img
        src="/images/help/fuse4.png"
        :max-width="HELP_IMAGE_MAX_WIDTH"
      />
      <v-img
        src="/images/help/fuse5.png"
        :max-width="HELP_IMAGE_MAX_WIDTH"
      />
    </div>
  </div>
</template>

<script>
import { HELP_IMAGE_MAX_WIDTH } from '@/utils/constants'
import HelpSupportedModels from '@/widgets/HelpSupportedModels'

export default {
  name: 'help-fuse',
  components: {
    HelpSupportedModels,
  },
  data () {
    return {
      HELP_IMAGE_MAX_WIDTH,
    }
  },
}
</script>

<style lang="scss" scoped>
.v-image {
  margin: 0 0 20px;
}
</style>
