<template>
  <div class="user-layout__wrapper d-flex flex-column">
    <div
      id="hidden-header-bg"
      class="bg-card-shadow"
    />
    <v-container
      class="user-layout__content"
      :class="{
        'px-0': $vuetify.breakpoint.xsOnly,
      }"
    >
      <user-layout-header
        :key="'header' + $route.params.id"
        class="mb-1"
      />
      <div style="position: relative">
        <div
          id="sticky-helper"
          style="position: absolute; left: 0;top: -48px; width: 100%; height: 48px; pointer-events: none;"
        />
      </div>
      <v-tabs
        id="tabs"
        background-color="#fff0"
        class="d-flex justify-center"
        style="position: sticky; top: 48px; z-index: 2; width: 100%"
      >
        <v-tab
          v-for="(btn, btnName) in USER_NAV_BTN"
          :key="btnName"
          class="user-layout__tabbar--content mx-0 font-weight-bold"
          :to="btn.to"
        >
          {{ $t(`header.router.${btn.to.name}`) }}
        </v-tab>
      </v-tabs>
      <router-view
        :key="$route.params.id"
        class="pt-3"
        :class="{
          'px-3': $route.name != 'user-home' && $vuetify.breakpoint.xsOnly
        }"
      />
    </v-container>
    <footer-template />
  </div>
</template>

<script>
import resize from 'vue-resize-directive'
import { MIN_WINDOW_WIDTH, USER_NAV_BTN } from '@/utils/constants'
import { mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import FooterTemplate from '@/layout/FooterTemplate'
import UserLayoutHeader from '@/components/UserLayoutHeader'

export default {
  name: 'user-layout',
  components: {
    FooterTemplate,
    UserLayoutHeader,
  },
  directives: {
    resize,
  },
  data () {
    return {
      MIN_WINDOW_WIDTH,
      USER_NAV_BTN,
      scrollY,
    }
  },
  computed: {
    ...mapFields('user', ['profileUser', 'user']),
    ...mapFields('workbench', ['windowSize']),
    ...mapGetters('workbench', ['windowTooNarrow']),
  },
  watch: {
    '$route.params.id' () {
        this.updateCurrentUser()
    },
  },
  mounted () {
    const observer = new IntersectionObserver(entries => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          // 固定されていない場合の処理
          document.getElementById('hidden-header-bg').style.display = 'none'
        } else {
          // 固定されている場合の処理
          document.getElementById('hidden-header-bg').style.display = 'inherit'
        }
      }
    }, {threshold: 1.0}) // thresholdは 0.0〜1.0 の範囲で指定する, 1.0 は見えない部分があるとき、0.0 は完全にみえないとき, 
    observer.observe(document.querySelector('#sticky-helper'))
    this.$once('hook:beforeDestroy', () => {
      observer.disconnect()
    })
  },
  created () {
    this.updateCurrentUser()
  },
  methods: {
    onResize () {
      this.windowSize = {
        x: window.innerWidth,
        y: window.innerHeight,
      }
    },
    async updateCurrentUser () {
      if (!this.profileUser || this.profileUser.id != this.$route.params.id) {
        await this.$store.dispatch('user/updateProfileUser', this.$route.params.id)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.user-layout {
  &__wrapper {
    height: 100%;
    min-height: calc(100vh - 48px);
  }
  &__content {
    height: 100%;
    max-width: map-get($sizes, main-body-max-width);
  }
}

::v-deep {
  .v-tabs-slider {
    width: 60%;
    margin: 0 auto;
  }
}

#hidden-header-bg {
  display: none;
  position: fixed;
  top: 48px;
  left: 0px;
  width: 100%;
  height: 48px;
  background-color: white;
  z-index: 1;
  transition: 200ms;
}
</style>
