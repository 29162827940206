<template>
  <v-dialog
    v-model="dialog"
    max-width="350px"
    scrollable
  >
    <template #activator="{ on, attrs }">
      <slot v-bind="{ on, attrs }" />
    </template>
    <v-card>
      <v-card-title>{{ $t('list.saveToList') }}</v-card-title>
      <v-divider />
      <v-card-text class="card-content">
        <v-list
          v-if="lists.length"
        >
          <v-list-item-group
            :key="stringifyList"
            v-model="selectedLists"
            multiple
          >
            <v-list-item
              v-for="list in lists"
              :key="list.id"
            >
              <template #default="{ active }">
                <v-list-item-action>
                  <v-checkbox
                    :indeterminate="list.contains_any && !list.contains_all"
                    :input-value="active"
                    color="primary"
                    :ripple="false"
                  />
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title v-text="list.name" />
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon
                    v-if="list.public"
                    color="grey"
                    small
                  >
                    mdi-earth
                  </v-icon>
                  <v-icon
                    v-else
                    color="grey"
                    small
                  >
                    mdi-lock
                  </v-icon>
                </v-list-item-icon>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <div
          v-else
          class="d-flex justify-center align-center grey--text text--darken-1 fill-height"
        >
          {{ $t('list.empty') }}
        </div>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <list-settings-dialog
          v-model="settings"
          @change="getLists"
        >
          <template #default="{ on, attrs }">
            <v-btn
              color="grey darken-1"
              text
              v-bind="attrs"
              v-on="on"
            >
              ＋{{ $t('list.create') }}
            </v-btn>
          </template>
        </list-settings-dialog>
        <v-spacer />
        <v-btn
          color="grey darken-1"
          text
          @click="dialog = false"
        >
          {{ $t('general.label.close') }}
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="updateLists"
        >
          {{ $t('general.label.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import List from '@/utils/list'
import { mapState } from 'vuex'
import Dialog from '@/utils/dialog'
import ListSettingsDialog from '@/widgets/ListSettingsDialog'

export default {
  name: 'list-dialog',
  components: { ListSettingsDialog },
  props: {
    crypkos: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      dialog: false,
      createDialog: false,
      lists: [],
      settings: {
        name: '',
        public: true,
      },
      updatedLists: {},
      valid: true,
    }
  },
  computed: {
    ...mapState('user', ['user']),
    stringifyList () {
      return JSON.stringify(this.lists)
    },
    selectedLists: {
      get () {
        return this.lists.flatMap((v, i) => (v.contains_all ? i : []))
      },
      set (value) {
        this.lists.forEach((list, i) => {
          if (value.includes(i)) {
            if (list.contains_all) {
              delete this.updatedLists[list.id]
            } else {
              this.updatedLists[list.id] = true
            }
          } else {
            if (list.contains_all) {
                this.updatedLists[list.id] = false
            } else if (list.contains_any) {
              if (this.updatedLists[list.id]) {
                this.updatedLists[list.id] = false
              }
            } else {
              delete this.updatedLists[list.id]
            }
          }
        })
      },
    },
  },
  watch: {
    async dialog () {
      if (this.dialog) {
        await this.getLists()
      }
    },
  },
  methods: {
    async getLists () {
      try {
        const res = await List.getListsForCrypkos(this.crypkos.map(c => c.hash))
        this.lists = res
        this.updatedLists = {}
      } catch (e) {
        console.log(e)
        Dialog.showFailedDialog()
      }
    },
    async updateLists () {
      try {
        await List.updateListsForCrypkos(
          this.crypkos.map(c => c.hash),
          this.updatedLists,
        )
        this.dialog = false
        this.lists = []
        this.containedLists = []
        Dialog.showSuccessDialog()
      } catch (e) {
        Dialog.showFailedDialog()
      }
    },
    async createList () {
      if (!this.settings.name) return
      try {
        List.create(this.settings)
        this.createDialog = false
        this.getLists()
        Dialog.showSuccessDialog()
      } catch (e) {
        Dialog.showFailedDialog()
      }
    },
    test (v) {
      console.log(v)
    },
  },
}
</script>

<style lang="scss" scoped>
.card-content {
  height: 400px;
}
</style>