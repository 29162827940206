import { getField, updateField } from 'vuex-map-fields'
import { APIRequest } from '@/utils'

export default {
  namespaced: true,
  state: {
    answered: true,
    has_travel: false,
  },
  mutations: {
    updateField,
  },
  actions: {
    async fetchSurveyData ({ commit }) {
      const req = new APIRequest('survey/available/', null, 'get')
      const { data } = await req.send()
      commit('updateField', {
        path: 'answered',
        value: data.answered,
      })
      commit('updateField', {
        path: 'has_travel',
        value: data.has_travel,
      })
    },
  },
  getters: {
    getField,
  },
}