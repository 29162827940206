<template>
  <v-dialog
    v-model="dialog"
    max-width="300px"
  >
    <template #activator="{ on, attrs }">
      <slot v-bind="{ on, attrs }" />
    </template>
    <v-card>
      <v-card-title class="justify-center pt-10 pb-2">
        <v-sheet
          class="d-flex justify-center align-center"
          color="secondary"
          rounded="pill"
          width="72"
          height="72"
        >
          <v-icon
            color="white"
            size="48"
          >
            mdi-format-list-bulleted-square
          </v-icon>
        </v-sheet>
      </v-card-title>
      <v-card-title class="justify-center">
        {{ $t('list.settings') }}
      </v-card-title>
      <v-card-text>
        <v-text-field
          :value="listSettings.name"
          :rules="[v => !!v || 'Name is required']"
          :label="$t('list.placeholder')"
          counter="16"
          required
          @input="updateName"
        />
        <v-checkbox
          :input-value="!listSettings.public"
          class="mt-0"
          :label="$t('privacy.private.title')"
          @change="updatePrivate"
        />
      </v-card-text>
      <v-card-text>
        <v-btn
          class="btn"
          color="primary"
          elevation="0"
          rounded
          @click="onSaveClick"
        >
          {{ $t('general.label.save') }}
        </v-btn>
        <v-btn
          class="btn"
          elevation="0"
          rounded
          @click="dialog = false"
        >
          {{ $t('general.label.close') }}
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import List from '@/utils/list'
import Dialog from '@/utils/dialog'

export default {
  name: 'list-settings-dialog',
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    listId: {
      type: String,
      default: null,
    },
  },
  data () {
    return {
      dialog: false,
      listSettings: {},
    }
  },
  mounted () {
    this.listSettings = Object.assign({}, this.value)
    if (!Object.prototype.hasOwnProperty.call(this.listSettings, 'name')) {
      this.listSettings.name = ''
    }
    if (!Object.prototype.hasOwnProperty.call(this.listSettings, 'public')) {
      this.listSettings.public = true
    }
  },
  methods: {
    updateName (name) {
      this.listSettings.name = name
    },
    updatePrivate (isPrivate) {
      this.listSettings.public = !isPrivate
    },
    submit () {
      this.dialog = false
      this.$emit('submit')
    },
    async onSaveClick () {
      if (!this.listSettings.name) return
      try {
        if (this.listId === null) {
          await List.create(this.listSettings)
        } else {
          await List.update(this.listId, this.listSettings)
        }
        this.dialog = false
        Dialog.showSuccessDialog()
        this.$emit('change')
      } catch (e) {
        Dialog.showFailedDialog()
      }
    },
  },
}
</script>

<style scoped>
.btn {
  width: 100%;
  margin: 10px auto 0;
}
</style>
