import { getField, updateField } from 'vuex-map-fields'
import { IMG_SIZES, MARKING_PREVIEW_NUM } from '../../utils/constants'
import {set} from 'vue'

function getDefaultState () {
  return {
    markingCrypkos: new Array(MARKING_PREVIEW_NUM).fill(null),
    transformCrypko: null,
    psdName: null,
    settings: {
      editorHeight: IMG_SIZES.LG,
      originImgSize: IMG_SIZES.XL,
      previewImgSize: IMG_SIZES.MD,
      previewImgNum: MARKING_PREVIEW_NUM,
    },
    accessoryNames: [],
    json: {
      'position': {
        'type': 'barycentric',
        'points': [
          {
            'name': 'foreground_outline',
            'idx': 0,
            'ratio': 2,
          },
          {
            'name': 'foreground_outline',
            'idx': -1,
            'ratio': 2,
          },
          {
            'name': 'basic_face_outline',
            'idx': -1,
            'ratio': 1,
          },
          {
            'name': 'basic_face_outline',
            'idx': 0,
            'ratio': 1,
          },
        ],
      },
      'transform': {
        'translation_x': 0,
        'translation_y': 0,
        'rotation': 0,
        'scale': 1,
      },
    },
    'groupColors': {
      'outline': '#F00',
      'left_eye_brow': '#0F0',
      'right_eye_brow': '#00F',
      'left_eye_outline': '#F0F',
      'right_eye_outline': '#FF0',
      'nose': '#000',
      'mouth': '#7F7F7F',
      'left_eye': '#007F00',
      'right_eye': '#00007F',
      'foreground_outline': '#0FF',
      'basic_face_outline': '#FFF',
      'left_ear': '#7F7F00',
      'right_ear': '#007F7F',
      'neck': '#7F007F',
    },
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    updateField,
    setCrypko (state, { idx, crypko }) {
      set(state.markingCrypkos, idx, crypko)
    },
  },
  actions: {
  },
  getters: {
    getField,
  },
}