<script setup>
import { APIRequest } from '@/utils'
import { computed, onMounted, ref, inject } from 'vue'
import UserRankItem from '@/components/UserRankItem.vue'
import LoadingProgressCircular from '@/widgets/LoadingProgressCircular'
const props = defineProps({
  title: {
    type: String,
    default: '',
  },
})

const apiURL = computed(() => {
  switch (props.title) {
    case 'crossfuse_rank_week':
      return 'users/rank/crossfuse-week/'
    case 'fav_rank_week':
      return 'users/rank/fav-week/'
    case 'fav_rank_all':
      return 'users/rank/fav-all/'
    case 'follower_rank':
      return 'users/rank/followers/'
  }
  throw new Error('Invalid title')
})
const data = ref()
const cancel = inject('cancel')
onMounted(async () => {
  const res = await new APIRequest(apiURL.value, {}, 'get').send()
  if (!Array.isArray(res.data)) {
    data.value = []
  } else {
    data.value = res.data
  }
})

</script>
<template>
  <v-card>
    <v-card-title>
      <div>
        {{ $t(`rank.${title}`) }}
      </div>
      <v-spacer />
      <v-btn
        fab
        elevation="0"
        small
        icon
        @click="cancel"
      >
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="px-3">
      <v-list>
        <div v-if="data !== undefined">
          <UserRankItem
            v-for="user, i in data"
            :key="user.id"
            :index="i"
            :user="user"
          />
        </div>
        <LoadingProgressCircular v-else />
      </v-list>
    </v-card-text>
  </v-card>
</template>
