import { IMG_SIZES } from '../utils/constants'

function formatToPx (size) {
  if (typeof size === 'string' &&
    (size.endsWith('%') || size.endsWith('px') || size === 'auto')) {
    // of format '__%' or '__px'. Already formatted.
    return size
  }
  size = IMG_SIZES[size] || size
  return size && size + 'px'
}

export class Size {
  /* parse anything to a image size object , which has key `width` and `height`
    e.g. 
                           100 -> {width: '100px', height: 'auto'}
                          'MD' -> {width: '256px', height: 'auto'}
                        '100%' -> {width: '100%',  height: 'auto'}
                          null -> {width: 'auto',  height: 'auto'}
    {width: 100, height: 'SM'} -> {width: '100px', height: '128px'}
  */
  constructor (...args) {
    if (args[0] instanceof Size) {
      // shortcut if the argument itself is already a Size object
      return args[0]
    }
    if (args.length > 2 || args.length === 0) {
      console.error('invalid size arguments', args)
      return
    }
    let width, height
    if (args.length === 2) {
      [width, height] = args
    } else if (args[0] && typeof args[0] === 'object') {
      width = args[0].width
      height = args[0].height
      // { width, height } = args[0]
    } else {
      width = args[0]
      height = args[0]
    }

    this.width = formatToPx(width) || 'auto'
    this.height = formatToPx(height) || 'auto'
  }
}
// export function parseImgSize (size) {
// }

export default {
  props: {
    size: {
      type: [Object, Number, String],
      default: null,
    },
  },
  computed: {
    parsedSize () {
      return new Size(this.size)
    },
    // bind it to root node to use the size as css variables
    cssSize () {
      return {
        '--width': this.parsedSize.width,
        '--height': this.parsedSize.height,
      }
    },
    // Override this to use complex rules
    // unparsedSize () {
    //   return this.size
    // },
  },
}