<script setup>
import UserAvatar from '@/widgets/UserAvatar.vue'
import AlbumLikeBtn from '@/components/AlbumLikeBtn.vue'
import { inject } from 'vue'
import { asyncComputed } from '@vueuse/core'
const props = defineProps({
    data: {
        type: Object,
        required: true,
    },
    height: {
        type: String,
        default: undefined,
    },
})
const albumURL = asyncComputed(async ()=> await OSSUtils.getAlbumUrl(props.data.hash))
const selectingAlbum = inject('selectingAlbum')
import OSSUtils from '@/utils/oss'
</script>
<template>
  <div style="line-height: 0; position:relative">
    <v-img
      class="cursor-pointer"
      style="border-radius: 0.25rem;"
      :src="albumURL"
      :height="height"
      @click="selectingAlbum = data"
    />
    <div style="position: absolute; bottom: 0.5rem; left: 0.5rem;">
      <router-link
        tag="div"
        :to="{
          name: 'user-home',
          params: { id: data.user.id }
        }"
        class="cursor-pointer"
      >
        <user-avatar
          :user="data.user"
          disable-menu
          size="28px"
        />
      </router-link>
    </div>
    <div style="position: absolute; bottom: 0.5rem; right: 0.5rem;">
      <AlbumLikeBtn :album="data" />
    </div>
  </div>
</template>