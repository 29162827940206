<template>
  <div>
    <h3 class="mb-2">
      {{ $t('models.supported-models') }}
    </h3>
    <feature-supported-models
      class="flex-wrap"
      :feature="feature"
    />
  </div>
</template>

<script>
import FeatureSupportedModels from '@/widgets/FeatureSupportedModels'

export default {
  name: 'help-supported-models',
  components: {
    FeatureSupportedModels,
  },
  props: {
    feature: {
      type: String,
      default: null,
    },
  },
  data () {
    return {}
  },
}
</script>
