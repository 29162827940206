<template>
  <v-btn
    v-bind="customBtnProps"
    :class="{'btn-submit': submit}"
    :loading="busy"
    :block="block"
    :elevation="elevation"
    @click="onClick"
  >
    <template #loader>
      <v-progress-circular
        v-if="waiting"
        v-bind="customProgressProps"
      />
      <template v-else-if="successful">
        ✔
      </template>
      <template v-else-if="failed">
        {{ failureMsg || '✗' }}
      </template>
    </template>
    <template #default>
      <slot />
    </template>
    <prompt-dialog-wrapper
      v-if="checkSlot"
      ref="slotConfirmDialog"
      :persistent="false"
    >
      <slot-confirm-dialog />
    </prompt-dialog-wrapper>
  </v-btn>
</template>

<script>
import { mapState } from 'vuex'
import PromptDialogWrapper from '@/widgets/PromptDialogWrapper'
import SlotConfirmDialog from '@/widgets/SlotConfirmDialog'

import { APIRequest } from '../utils'
import Crypko from '../utils/crypko'

const STATUS = {
  WAITING: 'waiting',
  SUCCESSFUL: 'successful',
  FAILED: 'failed',
  IDLE: null,
}

const LOADING_RESULT_MSG_TIME = 3000

export default {
  name: 'loading-btn',
  components: {
    PromptDialogWrapper,
    SlotConfirmDialog,
  },
  props: {
    // The action after clicking
    clickAction: {
      type: [Object, String, Function],
      default: null,
    },
    btnProps: {
      type: Object,
      default: null,
    },
    checkSlot: {
      type: Boolean,
      default: false,
    },
    progressProps: {
      type: Object,
      default: null,
    },
    color: {
      type: String,
      default: null,
    },
    // This disabled property has LOWER priority than default disabled
    // Change btnProps.disabled for high priority
    disabled: {
      type: Boolean,
      default: false,
    },
    submit: {  // true if it is a submit button
      type: Boolean,
      default: false,
    },
    showSuccessMsg: {
      type: Boolean,
      default: false,
    },
    showFailureMsg: {
      type: Boolean,
      default: false,
    },
    failureMsg: {
      type: String,
      default: null,
    },
    // parse response as Crypko
    parseCrypko: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    elevation: {
      type: [String, Number],
      default: 0,
    },
  },
  data () {
    return {
      status: STATUS.IDLE,
      clearStatusHandler: null,
    }
  },
  computed: {
    ...mapState('user', ['user']),
    isSlotsEnough () {
      return this.user && this.user.slots.max > this.user.slots.now
    },
    busy () {
      return this.waiting || this.successful || this.failed || this.loading
    },
    waiting () {
      return this.status === STATUS.WAITING
    },
    successful () {
      return this.showSuccessMsg && this.status === STATUS.SUCCESSFUL
    },
    failed () {
      return this.showFailureMsg && this.status === STATUS.FAILED
    },
    defaultBtnProps () {
      const defaults = {
        style: {},
        disabled: !this.clickAction,
        color: this.color || (this.submit ? 'primary' : null),
      }
      switch (this.status) {
        case STATUS.SUCCESSFUL:
          if (this.showSuccessMsg) {
            defaults.color = 'success'
            defaults.style['pointer-events'] = 'none'
          }
          break
        case STATUS.FAILED:
          if (this.showFailureMsg) {
            defaults.color = 'error'
            defaults.style['pointer-events'] = 'none'
          }
          break
        case STATUS.WAITING:
          defaults.disabled = true
          break
      }
      if (!defaults.disabled) {
        defaults.disabled = this.disabled
      }
      return defaults
    },
    customBtnProps () {
      return Object.assign({}, this.defaultBtnProps, this.btnProps)
    },
    customProgressProps () {
      return Object.assign({
        size: '20',
        width: '2',
        color: 'primary',
        indeterminate: true,
      }, this.progressProps)
    },
  },
  watch: {
    status (status) {
      if ([
        STATUS.SUCCESSFUL,
        STATUS.FAILED,
      ].includes(status)) {
        this.clearStatusHandler = setTimeout(() => {
          this.status = STATUS.IDLE
          this.$emit('status-cleared')
        }, LOADING_RESULT_MSG_TIME)
      }
    },
  },
  methods: {
    async onClick () {
      let rst = true
      if (this.checkSlot) {
        if (!this.isSlotsEnough) {
          const slotConfirmRst = await this.$refs.slotConfirmDialog.show()
          rst = slotConfirmRst && rst
        }
      }
      if (rst) {
        await this.proceed()
      }
    },
    async proceed () {
      clearTimeout(this.clearStatusHandler)
      this.$emit('click')
      this.status = STATUS.WAITING
      let { clickAction } = this
      try {
        let res
        switch (typeof clickAction) {
          case 'string':
            res = await this.$store.dispatch(clickAction)
            break
          case 'function':
            clickAction = await clickAction()
          // eslint-disable-next-line no-fallthrough
          case 'object':
            if (!(clickAction instanceof APIRequest)) {
              this.status = STATUS.IDLE
              return
            }
            res = await clickAction.send()
            if (clickAction.auth) {
              await this.$store.dispatch('user/updateCurrentUser')
            }
            break
          default:
            console.error('Unknown clickAction type: ' + (typeof this.clickAction), this.clickAction)
            return
        }
        if (this.parseCrypko) {
          const { data } = res
          // Assume that all crypko parsed are TempCrypko
          const parseCrypko = crypko => Crypko.parse({
            ...crypko,
            temp: true,
          })
          if (Array.isArray(data)) {
            res = data.map(parseCrypko)
          } else {
            res = parseCrypko(data)
          }
        }
        this.$emit('click-action-done', res)
        this.status = STATUS.SUCCESSFUL
      } catch (e) {
        console.error(e)
        this.$emit('action-failed', e)
        this.status = STATUS.FAILED
        throw e
      }
    },
  },
}
</script>

<style scoped>
.btn-submit {
  margin: 20px 0 0 0;
  width: 100%;
}
</style>
