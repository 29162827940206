<template>
  <v-img
    class="crypko-img grey--text text--lighten-1"
    :class="{
      'crypko-img--blurred': crypko.nsfw && !NSFWConfirm,
    }"
    :contain="contain"
    :height="parsedSize.height"
    :lazy-src="DEFAULT_IMG_URL"
    :src="crypkoSrc"
    v-bind="customImgProps"
    :width="parsedSize.width"
    @click="$emit('click', crypko)"
    @load="src => $emit('load', src)"
  >
    <slot />
  </v-img>
</template>
<script>
import { mapFields } from 'vuex-map-fields'
import { IMG_SIZES } from '../utils/constants'
import sizeable from '../mixins/sizeable'
import Crypko from '@/utils/crypko'

export default {
  name: 'crypko-img',
  mixins: [sizeable],
  props: {
    crypko: {
      type: Object,
      default: null,
    },
    // props for images
    imgProps: {
      type: Object,
      default: null,
    },
    square: {
      type: Boolean,
      default: false,
    },
    emoteConfig: {
      type: Object,
      default: null,
    },
    blurred: {
      type: Boolean,
      default: false,
    },
    contain: {
      type: Boolean,
      default: true,
    },
    face: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      DEFAULT_IMG_URL: Crypko.PLACEHOLDER.src,
    }
  },
  computed: {
    ...mapFields(['config.NSFWConfirm']),
  },
  asyncComputed: {
    async crypkoSrc () {
      if (!this.crypko || this.crypko.isPlaceholder) return this.DEFAULT_IMG_URL
      if (this.face) return await this.crypko.getSrc('FACE_DISP')
      const imageSize = IMG_SIZES[this.size] || this.size
      if (Number.isInteger(imageSize)) {
        // imageSize *= window.devicePixelRatio
        return await this.crypko.getSmallestSrc(imageSize)
      } else {
        console.debug('crypko-img need a size to enable responsive img loading', this.size)
        // v-img can take a src object
        // https://github.com/vuetifyjs/vuetify/blob/432c4db29a521323e0c2b40a6a164cc99c0fbcb3/packages/vuetify/src/components/VImg/VImg.ts#L83
        return await this.crypko.getLargestSrc()
      }
    },
    customImgProps () {
      const props = {}
      if (this.square) {
        props['aspect-ratio'] = 1
      }
      return {
        ...props,
        ...this.imgProps,
      }
    },
  },
}
</script>

<style scoped lang="scss">
.crypko-img--blurred ::v-deep .v-image__image {
  filter: blur(16px);
}
</style>
