<template>
  <div
    id="footer-template"
  >
    <v-row class="content container text-center">
      <v-col
        cols="6"
        md="4"
        lg="3"
      >
        <a
          href="https://twitter.com/Crypko"
          target="_blank"
          rel="noopener noreferrer"
          class="link-twitter"
        >
          <v-icon
            left
            color="#1ba1f3"
          >
            mdi-twitter
          </v-icon>
          <span style="vertical-align: middle;">Twitter</span>
        </a>
      </v-col>
      <v-col
        cols="6"
        md="4"
        lg="3"
      >
        <a
          :href="DISCORD_LINK"
          target="_blank"
          rel="noopener noreferrer"
          class="link-discord"
        >
          <img
            src="/images/discord-mark-blue.svg"
            width="24"
            height="24"
            style="margin-right: 8px"
            align="top"
          >
          <span style="vertical-align: middle;">Discord</span>
        </a>
      </v-col>
      <v-col
        cols="6"
        md="4"
        lg="3"
      >
        <a
          :href="$t('general.support.faq-link')"
          target="_blank"
          rel="noopener noreferrer"
          class="black--text"
        >
          FAQ
        </a>
      </v-col>
      <v-col
        cols="6"
        md="4"
        lg="3"
      >
        <a
          :href="$t('general.support.contact-link')"
          target="_blank"
          rel="noopener noreferrer"
          class="black--text"
        >
          <v-icon left>
            mdi-email
          </v-icon>
          <span style="vertical-align: middle;">{{ $t('footer.contact.title') }}</span>
        </a>
      </v-col>
      <v-col
        cols="6"
        md="4"
        lg="3"
      >
        <a
          href="https://www.preferred-networks.jp/"
          target="_blank"
          rel="noopener noreferrer"
          class="link-2"
        >
          <img
            src="/images/PFN_logo.png"
            width="100"
            align="top"
          >
        </a>
      </v-col>
      <v-col
        cols="6"
        md="4"
        lg="3"
      >
        <a
          :href="$t('link.privacy-policy')"
          rel="noopener noreferrer"
          target="_blank"
          style="color: black"
        >
          {{ $t('general.privacy-policy') }}
        </a>
      </v-col>
      <v-col
        cols="6"
        md="4"
        lg="3"
      >
        <router-link
          rel="noopener noreferrer"
          style="color: black"
          target="_blank"
          :to="{ name: 'terms' }"
        >
          {{ $t('general.terms') }}
        </router-link>
      </v-col>
      <v-col
        cols="6"
        md="4"
        lg="3"
      >
        <a
          href="/guideline/"
          target="_blank"
          style="color: black"
        >
          {{ $t('general.crypko-guideline') }}
        </a>
      </v-col>
    </v-row>
    <div
      class="text-center"
    >
      <language-select class="mx-auto" />
    </div>
    <div class="text-center">
      Copyright
      <span class="copyright-resource" />
      2024 Preferred Networks, Inc.
    </div>
  </div>
</template>

<script>
import { DISCORD_LINK } from '@/utils/constants'
import LanguageSelect from '@/widgets/LanguageSelect'

export default {
  name: 'footer-template',
  components: {
    LanguageSelect,
  },
  data () {
    return {
      DISCORD_LINK,
      dialog: false,
    }
  },
  computed: {
  },
  methods: {
    onContactClick () {
      open(this.$t('general.support.contact-link'), '_blank')
    },
  },
}
</script>

<style scoped lang="scss">
#footer-template {
  width: 100%;
  border-top: 1px solid #ccc;
  background-color: white;

  padding-top: 12px;
  padding-bottom: 90px;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    padding-bottom: 64px;
  }
}
.content {
  margin: auto;
  max-width: 1200px;
  padding: 10px 20px 10px 20px;
}
.heading {
  font-weight: bold;
}
.links .heading {
  margin: 0 0 10px 0;
}
.links a {
  color: inherit;
  text-decoration: none;
}
.links > a,
.links > div {
  margin: 5px 10px;
}
.link-twitter {
  color: #1ba1f3 !important;
}
.link-discord {
  color: #5865f2 !important;
}
.link-2 {
  color: #000 !important;
}
.link-3 {
  color: #ff0000 !important;
}
.link-pfn {
  color: #11338a !important;
}
.copyright-resource {
  background: url("../../public/images/copyright.png") no-repeat;
  height: 10px;
  width: 10px;
  display: inline-block;
  background-size: contain;
}

::v-deep {
  .Cookie {
    background: rgba($color: #424851, $alpha: 0.85);
    color: white;
    padding: 12px 16px;

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      bottom: 56px;
    }
  }

  .Cookie {
    font-size: 12px;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      padding: 16px;
    }
  }

  .Cookie__content {
    margin: 0;
  }

  .Cookie__button {
    border-radius: 100px;
    background: #f27677;
    padding: 8px 16px;
    font-size: 14px;
    margin: 0 12px;
  }

  .Cookie__button:hover {
    background-color: #f27677;
  }
}
</style>
