import { PRICES, DEV_MODE } from './constants'
import fuseStore from '../store/modules/fuse'
// eslint-disable-next-line no-inner-declarations
function gtag () {
    if (window['dataLayer']) {
        window['dataLayer'].push(arguments)
    }
}

/**
 * Get purchase data for GA4
 * 
 * @param {string} price_id 
 * @param {string} session_id 
 * @returns 
 */
function getPurchaseData (price_id, session_id) {
    switch (price_id) {
        case (PRICES.SUBSCRIPTION.STANDARD.MONTHLY):
            return {
                currency: 'JPY',
                value: 980,
                tax: 89,
                transaction_id: session_id,
                items: [
                    {
                        item_name: 'Standard Monthly Subscription',
                        quantity: 1,
                    },
                ],
            }
        case (PRICES.SUBSCRIPTION.STANDARD.ONEMONTH):
            return {
                currency: 'JPY',
                value: 1080,
                tax: 98,
                transaction_id: session_id,
                items: [
                    {
                        item_name: 'Standard One-Month Subscription',
                        quantity: 1,
                    },
                ],
            }
        case (PRICES.SUBSCRIPTION.PREMIUM.MONTHLY):
            return {
                currency: 'JPY',
                value: 3980,
                tax: 362,
                transaction_id: session_id,
                items: [
                    {
                        item_name: 'Premium Monthly Subscription',
                        quantity: 1,
                    },
                ],
            }
        case (PRICES.SUBSCRIPTION.PREMIUM.ONEMONTH):
            return {
                currency: 'JPY',
                value: 4380,
                tax: 398,
                transaction_id: session_id,
                items: [
                    {
                        item_name: 'Premium One-Month Subscription',
                        quantity: 1,
                    },
                ],
            }
        case (PRICES.SLOT[10]):
            return {
                currency: 'JPY',
                value: 275,
                tax: 25,
                transaction_id: session_id,
                items: [
                    {
                        item_name: 'Slot',
                        quantity: 10,
                    },
                ],
            }
        case (PRICES.SLOT[110]):
            return {
                currency: 'JPY',
                value: 2750,
                tax: 250,
                transaction_id: session_id,
                items: [
                    {
                        item_name: 'Slot',
                        quantity: 110,
                    },
                ],
            }
    }
    console.error('getPurchaseData: price_id not found', price_id)
}

export default class GAUtils {
    static setUserProps (data) {
        if (!data) return
        if (DEV_MODE) {
            gtag('config', 'G-3PSDJCB818', {
                'user_id': data.id,
                'debug_mode': true,
            })
        } else {
            gtag('config', 'G-RVW2HF5QNS', {
                'user_id': data.id,
            })
        }
        gtag('set', 'user_properties', {
            subscription_plan: data.subscription.plan,
            stamina_max: data.stamina.max,
            stamina_now: data.stamina.now,
            slots_max: data.slots.max,
            slots_now: data.slots.now,
            is_verified: data.is_verified,
            fav_count: data.fav_count,
            crossfuse_count: data.crossfuse_count,
            username: data.username,
        })
    }
    static logEvent (evt, data = {}) {
        if (evt) {
            try {
                if (DEV_MODE) {
                    console.log(evt, data)
                }
                gtag('event', evt, data)
            } catch (e) {
                console.error(e)
            }
        }
    }

    static logPurchaseEvent (price_id, session_id) {
        const data = getPurchaseData(price_id, session_id)
        GAUtils.logEvent('purchase', data)
    }

    static logEventFromAPIRequest (request) {
        const route = request.route
        let currentEvent = null
        let eventParams = {}

        switch (route) {
            case '/create/fuse/': {
                let value = 20
                let item_name = 'fuse'
                // judge if it's a crossfuse
                if (fuseStore.state.pair[0].owner.id !== fuseStore.state.pair[1].owner.id) {
                    value = 30
                    item_name = 'crossfuse'
                }
                currentEvent = 'spend_virtual_currency'
                eventParams = {
                    item_name,
                    virtual_currency_name: 'stamina',
                    value,
                }
                break
            }
            case '/create/generate/':
                currentEvent = 'spend_virtual_currency'
                eventParams = {
                    item_name: 'generate',
                    virtual_currency_name: 'stamina',
                    value: 20,
                    model: request.params.model,
                    batchsize: request.params.batchsize,
                    norm_pose: request.params.norm_pose || false,
                }
                break
            case '/create/save/':
                currentEvent = 'save_crypkos'
                eventParams = {
                    count: request.params.hashes.length,
                    public: request.params.public,
                    type: request.params.type,
                    model: request.params.model,
                }
                break
            case '/create/attrs/': {
                currentEvent = 'spend_virtual_currency'
                const tags = request.params.tags
                const significantTag = Object.keys(tags).reduce((a, b) => Math.abs(tags[a]) > Math.abs(tags[b]) ? a : b)
                eventParams = {
                    item_name: 'attributes',
                    virtual_currency_name: 'stamina',
                    value: 1,
                    number_of_tags: Object.keys(tags).length,
                    total_changes: Object.values(tags).reduce((a, b) => a + Math.abs(b)),
                    significant_tag: significantTag,
                    significant_value: tags[significantTag],
                }
                break
            }

            case '/create/variation/':
                currentEvent = 'spend_virtual_currency'
                eventParams = {
                    item_name: 'variation',
                    virtual_currency_name: 'stamina',
                    value: 20,
                    type: request.params.type,
                    strength: request.params.strength,
                }
                break

            case '/create/keypoints/':
                currentEvent = 'create_keypoints'
                break
            case '/create/canvas/':
                currentEvent = 'create_edit'
                break
            case '/crypkos/download/':
                currentEvent = 'download_crypko'
                eventParams = {
                    length: request.params.hashes.length,
                }
                break
            case '/crypkos/delete/':
                currentEvent = 'delete_crypko'
                eventParams = {
                    count: request.params.hashes.length,
                }
                break
            case '/crypkos/update_privacy/':
                currentEvent = 'update_crypko_privacy'
                eventParams = {
                    count: request.params.hashes.length,
                    public: request.params.public,
                }
                break
            case route.match(/^\/users\/.*\//)?.input:
                if (request.method === 'patch') {
                    if (request.params.username) {
                        currentEvent = 'update_username'
                    } else if (request.params.profile) {
                        currentEvent = 'update_user_profile'
                    }
                }
                break
            case route.match(/^\/crypkos\/.*\//)?.input:
                if (request.method === 'patch') {
                    currentEvent = 'update_crypko_profile'
                    eventParams = {
                        name: !!request.params.name,
                        profile: !!request.params.profile,
                    }
                }
                break
            case '/lists/fav/':
                currentEvent = 'fav_crypko'
                eventParams = {
                    fav: request.params.fav,
                }
                break
            case '/lists/update_lists_for_crypkos/':
                currentEvent = 'update_lists_for_crypkos'
                break
            case '/users/follow/':
                currentEvent = 'follow'
                eventParams = {
                    id: request.params.id,
                    follow: request.params.follow,
                }
                break
            case '/users/set_avatar/':
                currentEvent = 'set_avatar'
                break
            case '/auth/register/':
                currentEvent = 'sign_up'
                eventParams = { method: 'email' }
                break
            case '/auth/verify_email/':
                currentEvent = 'verify_email'
                break
            case '/auth/login/':
                currentEvent = 'login'
                eventParams = { method: 'email' }
                break
            case '/auth/logout/':
                currentEvent = 'logout'
                break
        }
        this.logEvent(currentEvent, eventParams)
    }
}