import { getField, updateField } from 'vuex-map-fields'
import { SEGMENTATION_LAYERS } from '../../utils/canvas'
import { EDIT_MODE, IMG_SIZES, DEFAULT_CARD_SIZE } from '../../utils/constants'

function getDefaultState () {
  return {
    editedCrypko: null,
    baseCrypko: null,
    refCrypko: null,
    segmentationSrc: null,
    tab: null,
    layers: SEGMENTATION_LAYERS,
    activeLayerIdx: SEGMENTATION_LAYERS.length - 1,
    settings: {
      editorHeight: IMG_SIZES.LG,
      refImgSize: DEFAULT_CARD_SIZE,
      mode: EDIT_MODE.DRAWING,
      brushSize: 20,
      eraserSize: 20,
      autoSelectCutout: true,
    },
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    updateField,
    updateSettings (state, data) {
      state.settings = {
        ...state.settings,
        ...data,
      }
    },
    updateAllLayers (state, data) {
      state.layers = state.layers.map(layer => {
        return {
          ...layer,
          ...data,
        }
      })
    },
  },
  actions: {
  },
  getters: {
    getField,
    cursorVisible: (state, getters) => getters.inMode(EDIT_MODE.DRAWING, EDIT_MODE.ERASER),
    cursorSize: (state, getters) => getters.inMode(EDIT_MODE.ERASER) ? state.settings.eraserSize : state.settings.brushSize,
    cursorRadius: (state, getters) => getters.cursorSize / 2,
    inMode: state => (...modes) => modes.includes(state.settings.mode),
  },
}