<template>
  <div
    class="d-flex flex-column"
    style="gap: 2rem;"
  >
    <div class="text-center">
      <v-chip
        class="primary--text"
        color="rgba(242, 118, 119, 0.1)"
      >
        <h2>
          {{ $t('general.label.feature') }}
        </h2>
        <v-divider
          class="mx-2 py-2"
          style="border-color: #fff"
          vertical
        />
        <h2>
          {{ $t('header.router.create-canvas') }}
        </h2>
      </v-chip>
      <div class="mt-2">
        {{ $t(`help.create.canvas.desc`) }}
      </div>
    </div>
    <help-supported-models feature="canvas" />
    <div>
      <h3 class="mb-2">
        {{ $t('help.usage') }}
      </h3>
      <div class="primary--text mb-4">
        <div>{{ $t(`payment.notice.canvas-fuse-desc`) }}</div>
        <div>{{ $t(`help.create.canvas.0`) }}</div>
        <div>{{ $t(`help.create.canvas.01`) }}</div>
        <div>{{ $t(`help.create.canvas.02`) }}</div>
      </div>
      <div>
        <p>{{ $t(`help.create.canvas.1`) }}</p>
        <v-img
          :max-width="HELP_IMAGE_MAX_WIDTH"
          src="/images/help/canvas1.png"
        />
      </div>
      <div>
        <p>{{ $t(`help.create.canvas.2`) }}</p>
        <v-img
          :max-width="HELP_IMAGE_MAX_WIDTH"
          src="/images/help/canvas2.png"
        />
      </div>
      <div>
        <p>{{ $t(`help.create.canvas.3`) }}</p>
        <v-img
          :max-width="HELP_IMAGE_MAX_WIDTH"
          src="/images/help/canvas3.png"
        />
      </div>
      <div>
        <p>{{ $t(`help.create.canvas.4`) }}</p>
        <v-img
          :max-width="HELP_IMAGE_MAX_WIDTH"
          src="/images/help/canvas4.png"
        />
      </div>
      <div>
        <p>{{ $t(`help.create.canvas.5`) }}</p>
        <v-img
          :max-width="HELP_IMAGE_MAX_WIDTH"
          src="/images/help/canvas5.png"
        />
        <v-img
          :max-width="HELP_IMAGE_MAX_WIDTH"
          src="/images/help/canvas6.png"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { HELP_IMAGE_MAX_WIDTH } from '@/utils/constants'
import HelpSupportedModels from '@/widgets/HelpSupportedModels'

export default {
  name: 'help-canvas',
  components: {
    HelpSupportedModels,
  },
  data () {
    return {
      HELP_IMAGE_MAX_WIDTH,
    }
  },
}
</script>

<style lang="scss" scoped>
.v-image {
  margin: 0 0 20px;
}
</style>
