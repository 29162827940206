import OSS from 'ali-oss'
import { OSS_CONFIG, ASSET_TYPES } from './constants'
import { APIRequest } from '.'

const STORAGE_TOKEN_NAME = 'CrypkoOSSToken'


export default class OSSUtils {

  static client
  static token
  static tokenPromise
  static cache = {}

  static async updateToken () {
    if (!OSSUtils.tokenPromise) {
      OSSUtils.tokenPromise = new APIRequest('/sts/token/', null, 'get').send()
    }
    const { data } = await OSSUtils.tokenPromise
    OSSUtils.token = data
    OSSUtils.token.uid = APIRequest.token.id
    OSSUtils.tokenPromise = null
    return data
  }

  static async clearToken () {
    OSSUtils.token = null
    OSSUtils.tokenPromise = null
    localStorage.removeItem(STORAGE_TOKEN_NAME)
  }

  static async init () {
    // update sts token and return if a new token is issued
    let exp
    if (!OSSUtils.token) {
      const tokenInStorage = JSON.parse(localStorage.getItem(STORAGE_TOKEN_NAME))
      OSSUtils.token = tokenInStorage  // can be null
    }
    if (OSSUtils.token) {
      exp = OSSUtils.token.Expiration
    }
    const flag = !OSSUtils.token || !exp || (Date.parse(exp) - Date.now()) < 10000 || (APIRequest.token && OSSUtils.token.uid !== APIRequest.token.id)
    if (flag) {
      await OSSUtils.updateToken()
      localStorage.setItem(STORAGE_TOKEN_NAME, JSON.stringify(OSSUtils.token))
    }
    if (!OSSUtils.client || flag) {
      OSSUtils.setClient()
      return true
    }
    return false
  }

  static setClient () {
    OSSUtils.client = new OSS({
      ...OSS_CONFIG,
      accessKeyId: OSSUtils.token.AccessKeyId,
      accessKeySecret: OSSUtils.token.AccessKeySecret,
      stsToken: OSSUtils.token.SecurityToken,
      // refreshSTSToken: async () => {  // not working
      //   console.log('refreshing token')
      //   const token = await OSSUtils.updateToken()
      //   localStorage.setItem(STORAGE_TOKEN_NAME, JSON.stringify(token))
      //   return token
      // },
    })
  }

  static async getCrypkoSrcBySize (crypko, size) {
    if (!Object.keys(ASSET_TYPES).includes(size)) {
      throw new Error(`Size ${size} not present in assets_type.json`)
    }
    let prefix
    if (crypko.temp) {
      // FIXME temp fix
      prefix = `users/${crypko.owner.id}/private/temp_`
    } else {
      const privacy = crypko.public ? 'public' : 'private'
      prefix = `users/${crypko.owner.id}/${privacy}/`
    }
    const key = prefix + ASSET_TYPES[size].path.replaceAll('{crypko_hash}', crypko.hash)
    const url = await OSSUtils.getUrl({ key })
    return url
  }

  static async getUserAvatar (id) {
    const key = `users/${id}/public/avatar/avatar.png`
    const url = await OSSUtils.getUrl({
      key,
      cache: false,
    })
    return url
  }

  static async uploadFile (key, file, options = null) {
    await OSSUtils.init()
    const { res } = await OSSUtils.client.put(key, file, options)
    if (res.statusCode !== 200) {
      throw new Error('API Error')
    }
  }

  static async getUrl ({ key, expires = 1800, cache = true }) {
    const expired = await OSSUtils.init()
    if (expired || !cache) {
      OSSUtils.cache = {}
    } else {
      const record = OSSUtils.cache[key]
      if (record && record.expire > Date.now()) {
        return OSSUtils.cache[key].url
      }
    }
    const url = OSSUtils.client.signatureUrl(key, {
      expires,
    })
    const linkExpire = Date.now() + expires * 1000
    const stsExpire = Date.parse(OSSUtils.token.Expiration)
    const expire = Math.min(linkExpire, stsExpire)
    OSSUtils.cache[key] = {
      url,
      expire,
    }
    return url
  }

  static async getAlbumUrl (hash) {
    const key = `albums/${hash}.jpg`
    return OSSUtils.getUrl({
      key,
    })
  }
}
