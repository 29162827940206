// modified from https://github.com/vuejs/vue/issues/8085#issuecomment-384884242
// conditionally wrap a component
export default {
  functional: true,
  props: {
    tag: {
      type: String,
      default: null,
    },
    active: Boolean,
    props: {
      type: Object,
      default: null,
    },
  },
  render (h, ctx) {
    const { props, slots, data, children } = ctx
    return props.active
      ? h(props.tag, {
        ...data,
        ...props,
      }, children)
      : slots().default[0]
  },
}