<template>
  <v-sheet
    class="d-flex justify-center align-center rounded"
    :color="color"
    width="24px"
    height="24px"
  >
    <v-badge
      color="primary"
      :content="badgeValue"
      :value="badgeValue"
      :dot="badgeDot"
    >
      <v-icon
        size="18px"
        color="white"
      >
        {{ icon }}
      </v-icon>
    </v-badge>
  </v-sheet>
</template>

<script>

export default {
  name: 'user-avatar-icon',
  props: {
    badgeValue: {
      type: Number,
      default: 0,
    },
    badgeDot: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'grey',
    },
    icon: {
      type: String,
      default: null,
    },
  },
  data () {
    return {}
  },
}
</script>
