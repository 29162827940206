var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.profileUser)?_c('div',{staticClass:"wrapper"},[_c('v-sheet',{staticClass:"d-flex align-center px-4",class:_vm.$vuetify.breakpoint.xsOnly ? 'rounded-0' : 'rounded-xl',attrs:{"height":"100px"}},[_c('user-avatar',{attrs:{"disable-menu":"","size":"70px","user":_vm.profileUser}}),_c('div',{staticClass:"text-h5 ml-5"},[_vm._v(" "+_vm._s(_vm.profileUser.username)+" ")]),_c('v-spacer'),(_vm.user && _vm.$route.params.id !== _vm.user.id)?_c('follow-btn',{staticStyle:{"width":"100px"},attrs:{"btn-props":{
        small: true,
      },"user":_vm.profileUser}}):_vm._e(),(_vm.user && _vm.$route.params.id === _vm.user.id)?_c('v-badge',{attrs:{"id":"badge-wrapper","bordered":"","color":"light-green","overlap":"","offset-x":"16","offset-y":"16"}},[_c('v-btn',{staticStyle:{"z-index":"1"},attrs:{"slot":"badge","dark":"","icon":"","ripple":false,"x-small":"","to":{ name: 'plans' }},slot:"badge"},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-sheet',{staticClass:"d-flex flex-column justify-space-between px-3 pt-3 pb-2",attrs:{"color":"grey lighten-3","height":"76px","width":"120px","rounded":"16"}},[_c('div',{staticClass:"d-flex justify-center align-center"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-face ")]),_c('div',[_vm._v(" "+_vm._s(_vm.user.slots.now)+" ")])],1),_c('div',[_c('v-progress-linear',{staticClass:"slot-bar",attrs:{"value":100 * _vm.user.slots.now / _vm.user.slots.max,"color":"grey darken-1","height":"5px","rounded":""}}),_c('div',{staticClass:"d-flex justify-space-between text-caption"},[_c('div',[_vm._v(_vm._s(_vm.$t('general.slot')))]),_c('div',[_vm._v(" "+_vm._s(_vm.user.slots.max)+" ")])])],1)])],1):_vm._e()],1),_c('v-sheet',{staticClass:"d-flex justify-center",class:_vm.$vuetify.breakpoint.xsOnly ? 'rounded-0' : 'rounded-xl',attrs:{"height":"100px","width":"100%"}},[_c('div',{staticClass:"d-flex align-center px-3 justify-space-between fill-height",staticStyle:{"max-width":"600px","overflow":"auto","width":"100%","gap":"12px"}},[_c('router-link',{attrs:{"tag":"button","to":{
          name: 'followers-user',
        }}},[_c('v-sheet',{staticClass:"pa-3",attrs:{"color":"grey lighten-3","rounded":"16","min-width":"86px","elevation":"0"}},[_c('div',{staticClass:"text-h6 d-flex align-center justify-center"},[_c('v-icon',{staticClass:"mr-1"},[_vm._v(" mdi-account ")]),_c('span',[_vm._v(" "+_vm._s(_vm.profileUser.follower_count)+" ")])],1),_c('div',{staticClass:"text-caption text-center font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('header.router.followers-user'))+" ")])])],1),_c('router-link',{attrs:{"tag":"button","to":{
          name: 'followings-user',
        }}},[_c('v-sheet',{staticClass:"pa-3",attrs:{"color":"grey lighten-3","rounded":"16","min-width":"86px"}},[_c('div',{staticClass:"text-h6 d-flex align-center justify-center"},[_c('v-icon',{staticClass:"mr-1"},[_vm._v(" mdi-account ")]),_c('span',[_vm._v(" "+_vm._s(_vm.profileUser.following_count)+" ")])],1),_c('div',{staticClass:"text-caption text-center font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('header.router.followings-user'))+" ")])])],1),_c('v-sheet',{staticClass:"pa-3",attrs:{"color":"grey lighten-3","rounded":"16","min-width":"86px"}},[_c('div',{staticClass:"text-h6 d-flex align-center justify-center"},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"primary"}},[_vm._v(" mdi-heart ")]),_c('span',[_vm._v(" "+_vm._s(_vm.profileUser.fav_count)+" ")])],1),_c('div',{staticClass:"text-caption text-center"},[_vm._v(" "+_vm._s(_vm.$t('general.label.fav'))+" ")])]),_c('v-sheet',{staticClass:"pa-3",attrs:{"color":"grey lighten-3","rounded":"16","min-width":"86px"}},[_c('div',{staticClass:"text-h6 d-flex align-center justify-center"},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"light-green","size":"20"}},[_vm._v(" mdi-vector-combine ")]),_c('span',[_vm._v(_vm._s(_vm.profileUser.crossfuse_count))])],1),_c('div',{staticClass:"text-caption text-center"},[_vm._v(" "+_vm._s(_vm.$t('crypko.crossfuse'))+" ")])])],1)])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }