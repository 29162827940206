<template>
  <div class="text-center">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      max-width="300"
      offset-y
    >
      <template #activator="{ on, attrs }">
        <v-badge
          :value="dirty"
          dot
          overlap
        >
          <v-btn
            color="grey lighten-3 grey--text text--darken-2"
            elevation="0"
            rounded
            v-bind="attrs"
            v-on="on"
          >
            {{ $t(`gallery.filter.label`) }}
          </v-btn>
        </v-badge>
      </template>
      <v-card>
        <v-card-subtitle class="pb-0">
          {{ $t('gallery.filter.model') }}
        </v-card-subtitle>
        <v-card-text class="py-0">
          <v-chip-group
            v-model="query[CRYPKO_FILTER_PARAMS.MODEL.key]"
            active-class="primary--text primary"
            column
            multiple
          >
            <v-chip
              v-for="model in CRYPKO_FILTER_PARAMS.MODEL.value"
              :key="model"
              :disabled="modelFixed && !constraintsList.includes(model)"
              outlined
              :value="model"
            >
              {{ $t(`models.${model}`) }}
            </v-chip>
          </v-chip-group>
        </v-card-text>
        <v-card-subtitle class="pb-0">
          {{ $t('gallery.filter.status') }}
        </v-card-subtitle>
        <v-card-text class="d-flex pb-0">
          <v-chip-group
            v-model="selectedStatusIndex"
            active-class="primary--text primary"
            column
            multiple
          >
            <v-chip outlined>
              {{ $t(`crypko.crossfuse`) }}
            </v-chip>
            <template v-if="owned">
              <v-chip
                v-for="s in status.slice(1, 3)"
                :key="s"
                outlined
              >
                {{ $t(`privacy.${s}.title`) }}
              </v-chip>
            </template>
          </v-chip-group>
        </v-card-text>
        <v-card-subtitle class="pb-0">
          {{ $t('gallery.filter.type') }}
        </v-card-subtitle>
        <v-card-text class="py-0">
          <v-chip-group
            v-model="query[CRYPKO_FILTER_PARAMS.TYPE.key]"
            active-class="primary--text primary"
            column
            multiple
          >
            <v-chip
              v-for="type in CRYPKO_FILTER_PARAMS.TYPE.value"
              :key="type"
              outlined
              :value="type"
            >
              {{ $t(`crypko.type.${type}`) }}
            </v-chip>
          </v-chip-group>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="ml-auto"
            color="grey darken-1"
            text
            @click="onCancelClick"
          >
            {{ $t('general.label.cancel') }}
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="onApplyClick"
          >
            {{ $t('general.label.apply') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { CRYPKO_FILTER_PARAMS } from '@/utils/constants'

export default {
  name: 'browser-filter',
  components: {
  },
  inject: ['owned'],
  props: {
    ordering: {
      type: String,
      default: 'default',
    },
    orderingTypes: {
      type: String,
      default: 'crypko',
    },
    value: {
      type: Object,
      default: () => { },
    },
    modelFixed: {
      type: Boolean,
      default: false,
    },
    filteredModel: {
      type: String,
      default: null,
    },
    filteredType: {
      type: String,
      default: null,
    },
    modelConstraints: {
      type: String,
      default: () => null,
    },
    filteredPrivacy: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      CRYPKO_FILTER_PARAMS,
      menu: false,
      owned: this.owned,
      query: {},
      status: [
        'crossfuse',
        'public',
        'private',
      ],
      selectedStatusIndex: [],
    }
  },
  computed: {
    dirty () {
      return !this.$_.isEmpty(this.value)
    },
    constraintsList () {
      if (!this.modelConstraints) {
        return []
      }
      return this.modelConstraints.split(',')
    },
    selectedStatus () {
      return this.selectedStatusIndex.map(i => this.status[i])
    },
  },
  created () {
    if (this.filteredModel) {
      this.query[CRYPKO_FILTER_PARAMS.MODEL.key] = this.filteredModel.split(',')
    }
    if (this.filteredType) {
      this.query[CRYPKO_FILTER_PARAMS.TYPE.key] = this.filteredType.split(',')
    }
    // if (this.filteredPrivacy.length > 0) {
    //   this.query[CRYPKO_FILTER_PARAMS.PRIVACY.key] = this.filteredPrivacy.slice()
    // }
    const pararms = new URLSearchParams(window.location.search)
    if (pararms.get('crossfuse') === 'true')
      this.selectedStatusIndex.push(0)
    if (pararms.get('public') === 'true')
      this.selectedStatusIndex.push(1)
    if (pararms.get('public') === 'false')
      this.selectedStatusIndex.push(2)
  },
  methods: {
    onCancelClick () {
      this.query = { ...this.value }
      this.menu = false
    },
    onApplyClick () {
      // remove empty lists
      for (const key in this.query) {
        if (this.$_.isEmpty(this.query[key])) {
          delete this.query[key]
        }
      }
      if (!this.query.privacy || this.query.privacy.length === 0) {
        this.query.privacy = ['public', 'private']
      }
      if (this.selectedStatus.includes('crossfuse')) {
        this.query.crossfuse = true
      } else {
        delete this.query.crossfuse
      }
      if (this.selectedStatus.includes('public') && !this.selectedStatus.includes('private')) {
        this.query.privacy = ['public']
      }
      if (this.selectedStatus.includes('private') && !this.selectedStatus.includes('public')) {
        this.query.privacy = ['private']
      }
      this.$emit('input', { ...this.query })
      this.$emit('change', { ...this.query })
      this.menu = false
    },
  },
}
</script>

<style scoped lang="scss">
.light-btn {
  &--selecting {
    background-color: map-get($colors, crypko-card-border-selected) !important;
  }
}
</style>
