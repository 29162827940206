/* eslint-disable quotes */
import { APIRequest } from '@/utils'

export default {
    namespaced: true,
    state: {
        allData: [],
        shouldRead: [],
        next: null,
        unread: 0,
        count: 0,
    },
    mutations: {
        setPageInfo (state, data) {
            state.count = data.count
            if (data.next) {
                const origin = new URL(data.next).origin
                state.next = data.next.replace(origin, this.state.API_HOSTNAME)
            } else {
                state.next = null
            }
        },
        addNotifications (state, data) {
            data.forEach(item => {
                if (!state.allData.find(i => i.id === item.id)) {
                    state.allData.unshift(item)
                }
            })
            state.allData.sort((a, b) => {
                return new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
            })
        },
        setUnreadCount (state, newValue) {
            state.unread = newValue
        },
        markAsRead (state, ids) {
            ids.forEach(id => {
                const index = state.allData.findIndex(i => i.id === id)
                if (index !== -1) {
                    state.allData[index].is_read = true
                }
            })
            if (state.unread > ids.length) {
                state.unread -= ids.length
            } else {
                state.unread = 0
            }
            state.shouldRead = []
        },
        cleanData (state) {
            state.allData = []
            state.next = null
            state.prev = null
            state.count = 0
        },
        addToShouldRead (state, id) {
            if (!state.shouldRead.find(i => i === id)) {
                state.shouldRead.push(id)
            }
        },
    },
    actions: {
        async fetchFirstPage ({ commit }) {
            const request = new APIRequest(`/notifications/`, null, 'get')
            const resp = await request.send()
            commit('setPageInfo', resp.data)
            commit('addNotifications', resp.data.results)

        },
        async getUnreadCount ({ commit }) {
            const request = new APIRequest(`/notifications/unread/count/`, null, 'get')
            const resp = await request.send()
            commit('setUnreadCount', resp.data.count)
        },
        async fetchUnread ({ commit }) {
            const request = new APIRequest(`/notifications/unread/`, null, 'get')
            const resp = await request.send()
            commit('addNotifications', resp.data)
        },
        async markAsRead ({ state, commit }) {
            const ids = state.shouldRead
            const request = new APIRequest(`/notifications/read/`, {
                ids,
            }, 'patch', true)
            await request.send()
            commit('markAsRead', ids)
        },
        async getNext ({ commit, state }) {
            if (!state.next) return
            const request = new APIRequest(state.next, null, 'get')
            const resp = await request.send()
            commit('setPageInfo', resp.data)
            commit('addNotifications', resp.data.results)
        },
    },
}