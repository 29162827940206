<template>
  <v-main>
    <workbench-select v-if="$route.name === CREATE_ROUTE_NAME.CREATE" />
    <!-- TODO: add keep-alive (and maybe transition) around router-view -->
    <!-- https://vuejs.org/v2/api/#keep-alive -->
    <v-toolbar
      v-if="$route.name !== CREATE_ROUTE_NAME.CREATE"
      absolute
      :class="CREATE_NAV_BTN[currentWorkbench].toolbarShadow ? 'bg-card-shadow' : 'elevation-0'"
      style="position: fixed; top: 48px; left: 0; z-index: 174;"
      width="100%"
    >
      <v-menu
        offset-y
        transition="slide-y-transition"
      >
        <template #activator="{ on, attrs }">
          <v-btn
            class="mx-auto text-subtitle-1"
            color="grey darken-2"
            elevation="0"
            text
            width="150"
            v-bind="attrs"
            v-on="on"
          >
            <img
              class="icon mr-4"
              :src="`/images/workbench/${currentWorkbench}.svg`"
            >
            {{ $t(`header.router.${$route.name}`) }}
          </v-btn>
        </template>
        <v-list>
          <div
            v-for="(btn, btnName) in CREATE_NAV_BTN"
            :key="btnName"
          >
            <v-list-item
              v-if="!user || !btn.isAvailable(user)"
              @click="freemiumUserClick"
            >
              <v-list-item-icon>
                <v-icon>mdi-lock</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ $t(`header.router.${btn.to.name}`) }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-else
              :to="btn.to"
            > 
              <v-list-item-icon>
                <img
                  class="icon"
                  :src="`/images/workbench/${btnName}.svg`"
                >
              </v-list-item-icon>
              <v-list-item-content>
                {{ $t(`header.router.${btn.to.name}`) }}
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list>
      </v-menu>
      <div
        class="d-flex"
        style="position: absolute; right: 12px; gap: 4px;"
      >
        <v-btn
          icon
          color="grey lighten-2"
          small
          @click="onHelpClick"
        >
          <v-icon style="color: rgba(0, 0, 0, 0.25)">
            mdi-help-circle-outline
          </v-icon>
        </v-btn>
        <v-btn
          v-show="drawerRight !== null"
          color="primary"
          :disabled="drawerRight === null"
          icon
          small
          @click="drawerRight = !drawerRight"
        >
          <v-icon>$menu</v-icon>
        </v-btn>
      </div>
    </v-toolbar>
    <div
      v-if="$route.name !== CREATE_ROUTE_NAME.CREATE"
      class="fill-height workbench-wrapper"
    >
      <div
        v-show="showWorkbenchContent"
        class="mt-14 mt-md-16 fill-height"
      >
        <router-view />
      </div>
      <v-container
        v-if="!showWorkbenchContent"
        class="fill-height ml-n7"
      >
        <!-- FIXME v-main has a strange 56px padding -> ml-n7 -->
        <v-sheet
          class="mx-auto d-flex flex-column align-center pa-4 bg-card-shadow"
          color="white"
          max-width="280px"
          rounded="xl"
        >
          <v-img
            src="/images/404_s.png"
            max-width="160px"
          />
          <p class="text-subtitle-1 mt-3">
            {{ $t('workbench.select.screen-too-small') }}
          </p>
          <v-btn
            elevation="0"
            @click="onCanvasVidBtnClick"
          >
            <v-icon
              class="mr-2"
              color="primary"
            >
              mdi-youtube
            </v-icon>
            {{ $t('workbench.select.canvas-feature') }}
          </v-btn>
          <v-btn
            v-if="DEV_MODE"
            class="mt-3"
            elevation="0"
            @click="() => showContentAnyway = true"
          >
            [Dev] Show
          </v-btn>
        </v-sheet>
      </v-container>
    </div>
    <workbench-help-dialog
      ref="helpDialog"
    />
    <plan-required-dialog
      ref="planRequiredDialog"
    />
  </v-main>
</template>

<script>
import { MIN_WINDOW_WIDTH, CREATE_NAV_BTN, CREATE_NAV_TYPE, CREATE_ROUTE_NAME, DEV_MODE } from '@/utils/constants'
import { mapGetters, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import WorkbenchSelect from '@/layout/WorkbenchSelect'
import WorkbenchHelpDialog from '@/widgets/WorkbenchHelpDialog'
import PlanRequiredDialog from '@/widgets/PlanRequiredDialog'

export default {
  name: 'workbench-layout',
  components: {
    WorkbenchSelect,
    WorkbenchHelpDialog,
    PlanRequiredDialog,
  },
  data () {
    return {
      CREATE_NAV_BTN,
      CREATE_NAV_TYPE,
      CREATE_ROUTE_NAME,
      DEV_MODE,
      MIN_WINDOW_WIDTH,
      showContentAnyway: false,
    }
  },
  computed: {
    ...mapFields('workbench', ['windowSize', 'drawerRight']),
    ...mapFields(['lang']),
    ...mapGetters('workbench', ['windowTooNarrow']),
    ...mapState('user', ['user']),
    currentWorkbench () {
      for (const workbench in CREATE_NAV_BTN) {
        if (CREATE_NAV_BTN[workbench].to.name === this.$route.name) {
          return workbench
        }
      }
      return null
    },
    showWorkbenchContent () {
      return this.showContentAnyway || this.$vuetify.breakpoint.mdAndUp || this.$route.name !== CREATE_ROUTE_NAME.CANVAS
    },
  },
  watch: {
    $route () {
      this.updateRightDrawer()
    },
  },
  mounted () {
    this.updateRightDrawer()
  },
  methods: {
    updateRightDrawer () {
      if (this.$route.name === 'create') return
      const hasDrawer = Object.entries(CREATE_NAV_BTN).filter(
        ([, v]) => v.to.name == this.$route.name)[0][1].rightDrawer
      this.drawerRight = hasDrawer ? this.$vuetify.breakpoint.lgAndUp : null
    },
    onCanvasVidBtnClick () {
      let url
      if (this.lang === 'cn') {
        url = 'https://www.bilibili.com/video/BV1DC4y1a7TR'
      } else {
        url = 'https://www.youtube.com/watch?v=TZqNlpJpXjc'
      }
      open(url, '_blank')
    },
    freemiumUserClick () {
      this.$refs.planRequiredDialog.show()
    },
    onHelpClick () {
      this.$refs.helpDialog.openDialog()
    },
  },
}
</script>

<style lang="scss" scoped>
.workbench-wrapper {
  // max-width: 1161px;
  max-height: calc(100% - 56px);
  @media screen and (min-width: 960px) {
    max-height: calc(100% - 64px);
  }
}

.inner {
  width: 100%;
  padding-bottom: 64px;
}

.icon {
  width: 26px;
}

.v-menu__content {
  z-index: 200 !important;
}
</style>
