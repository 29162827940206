import { getField, updateField } from 'vuex-map-fields'

function getDefaultState () {
  return {
    // available labels initialized on the first instantiation of EmotePlayer
    mainTimelineLabels: null,
    diffTimelineLabels: null,

    // mainTimelineLabel: 'sample_全自動_test',
    mainTimelineLabel: '普通',
    diffTimelineSlot: [],
    interactive: true,
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    updateField,
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
  },
  getters: {
    getField,
  },
}