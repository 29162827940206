<template>
  <v-container class="wrapper fill-height mt-2 px-6">
    <div class="fill-width">
      <div class="text-h5 font-weight-bold mb-6">
        <span style="box-shadow: inset 0 -10px 0 0 #ddd;">
          {{ $t('workbench.select.create-character') }}
        </span>
      </div>
      <div class="tool-row">
        <div
          v-for="(btn, btnName) in characterBtns"
          :key="btnName"
        >
          <v-card
            class="d-flex flex-column align-center py-6 select-card"
            :color="!btn.isAvailable(user) ? '#dcdcdc' : null"
            width="160"
            height="160"
            rounded="lg"
            outlined
            @click="() => onBtnClick(btn)"
          >
            <v-icon
              v-if="!btn.isAvailable(user)"
              :color="btn.color"
              size="56"
            >
              mdi-lock
            </v-icon>
            <img
              v-else
              class="d-block rounded-0"
              :src="`/images/workbench/${btnName}.svg`"
              width="56"
              height="56"
            >
            <div
              class="text-h5 mt-5 text-no-wrap"
              :style="{
                'color': btn.color,
              }"
            >
              {{ $t(`header.router.${btn.to.name}`) }}
            </div>
            <img
              v-if="btn.alpha"
              class="d-block rounded-0 alpha-beta-icon"
              src="/images/workbench/alpha.svg"
            >
            <img
              v-if="btn.beta"
              class="d-block rounded-0 alpha-beta-icon"
              src="/images/workbench/beta.svg"
            >
          </v-card>
        </div>
      </div>
      <div class="text-h5 font-weight-bold mt-10 mb-6">
        <span style="box-shadow: inset 0 -10px 0 0 #ddd;">
          {{ $t('workbench.select.create-illust') }}
        </span>
      </div>
      <div class="tool-row">
        <v-card
          class="d-flex flex-column align-center py-6 select-card"
          width="160"
          height="160"
          rounded="lg"
          outlined
          @click="() => onBtnClick(travelBtn)"
        >
          <div
            class="d-inline-block"
            style="overflow: hidden; border-radius: 16px"
          >
            <v-img
              src="/images/travel_create.jpg"
              max-height="56"
              max-width="56"
              contain
              rounded="lg"
            />
          </div>
          <div
            class="text-h5 mt-5 text-no-wrap"
            :style="{
              'color': 'rgb(198, 120, 129)',
            }"
          >
            {{ $t(`header.router.travel`) }}
          </div>
        </v-card>
        <div
          v-for="(btn, btnName) in illustBtns"
          :key="btnName"
        >
          <v-card
            class="d-flex flex-column align-center py-6 select-card"
            :color="!btn.isAvailable(user) ? '#dcdcdc' : null"
            width="160"
            height="160"
            rounded="lg"
            outlined
            @click="() => onBtnClick(btn)"
          >
            <v-icon
              v-if="!btn.isAvailable(user)"
              :color="btn.color"
              size="56"
            >
              mdi-lock
            </v-icon>
            <img
              v-else
              class="d-block rounded-0"
              :src="`/images/workbench/${btnName}.svg`"
              width="56"
              height="56"
            >
            <div
              class="text-h5 mt-5 text-no-wrap"
              :style="{
                'color': btn.color,
              }"
            >
              {{ $t(`header.router.${btn.to.name}`) }}
            </div>
            <img
              v-if="btn.alpha"
              class="d-block rounded-0 alpha-beta-icon"
              src="/images/workbench/alpha.svg"
            >
            <img
              v-if="btn.beta"
              class="d-block rounded-0 alpha-beta-icon"
              src="/images/workbench/beta.svg"
            >
          </v-card>
        </div>
      </div>
    </div>
    <plan-required-dialog ref="planRequiredDialog" />
    <prompt-dialog-wrapper ref="crypkoRequiredDialog">
      <confirm-options-dialog
        :title="$t('dialog.require-crypko.title')"
        :msg="$t('dialog.require-crypko.message')"
        :btn-colors="['primary', null, null]"
        :options="[`${$t('general.label.generate')}`,`${$t('general.label.continue-anyway')}`,`${$t('general.label.close')}`]"
      />
    </prompt-dialog-wrapper>
  </v-container>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapState } from 'vuex'
import { DEV_MODE, CREATE_NAV_BTN, CREATE_NAV_TYPE } from '@/utils/constants'
import PlanRequiredDialog from '@/widgets/PlanRequiredDialog'
import PromptDialogWrapper from '@/widgets/PromptDialogWrapper'
import ConfirmOptionsDialog from '@/widgets/ConfirmOptionsDialog'

export default {
  name: 'workbench-select',
  components: {
    PlanRequiredDialog,
    PromptDialogWrapper,
    ConfirmOptionsDialog,
  },
  data () {
    return {
      CREATE_NAV_BTN,
      CREATE_NAV_TYPE,
      DEV_MODE,
    }
  },
  computed: {
    ...mapFields(['lang']),
    ...mapState('user', ['user']),
    characterBtns () {
      let types = [
        CREATE_NAV_TYPE.GENERATE,
        CREATE_NAV_TYPE.FUSE,
        CREATE_NAV_TYPE.ATTRS,
        CREATE_NAV_TYPE.VARIATION,
      ]
      if (DEV_MODE) {
        types = types.concat([
          CREATE_NAV_TYPE.KEYPOINT,
        ])
      }
      return this.$_.pick(CREATE_NAV_BTN, types)
    },
    illustBtns () {
      const types = [CREATE_NAV_TYPE.INTERPOLATE, CREATE_NAV_TYPE.CANVAS]
      return this.$_.pick(CREATE_NAV_BTN, types)
    },
    travelBtn () {
      return {
        to: {
          name: 'travel',
        },
        isAvailable (user) {
          return !!user
        },
        requiresCrypko: true,
      }
    },
  },
  methods: {
    async onBtnClick (btn) {
      if (btn.isAvailable(this.user)) {
        if (btn.requiresCrypko && !this.user.slots?.now) {
          const res = await this.$refs.crypkoRequiredDialog.show()
          switch (res) {
            case 0:
              this.$router.push(CREATE_NAV_BTN[CREATE_NAV_TYPE.GENERATE].to)
              return
            case 1:
              break
            default:
              return
          }
        }
        this.$router.push(btn.to)
      } else {
        this.$refs.planRequiredDialog.show()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  max-width: 760px;
}

.text-no-wrap {
  white-space: nowrap;
}

.tool-row {
  display: grid;
  justify-content: space-between;
  column-gap: 30px;
  row-gap: 30px;
  grid-template-columns: repeat(auto-fill, 160px);
  flex-wrap: wrap;
  @media screen and (max-width: 600px) {
    grid-template-columns: repeat(auto-fill, 140px);
    column-gap: 20px;
    row-gap: 20px;
  }
}

.alpha-beta-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
}

.select-card {
  overflow: hidden;
  transition: box-shadow 0.2s;
}

.select-card:hover {
  box-shadow: map-get($shadows, hover);
}
</style>
