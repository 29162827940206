var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-title',{staticClass:"justify-center pt-10 pb-2"},[_c('v-icon',{attrs:{"color":"light-green","size":"80"}},[_vm._v(" mdi-download ")])],1),_c('v-card-title',{staticClass:"justify-center"},[_vm._v(" "+_vm._s(_vm.$t('general.label.download'))+" ")]),_c('v-card-text',[_c('div',{staticClass:"text-center"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('desc'))+" ")]),(!_vm.user || (_vm.user.isFreemium() && !_vm.purchased))?_c('p',[_vm._v(" "+_vm._s(_vm.$t('watermark-desc'))+" ")]):_vm._e()]),_c('loading-btn',{staticClass:"btn",attrs:{"btn-props":{
        elevation: 0,
        rounded: true,
        color: 'f5f5f5',
      },"click-action":() => _vm.onDownloadClick(false),"disabled":_vm.processingRequest,"show-failure-msg":""},on:{"click-action-done":function($event){return _vm.onRequestDone($event, false)},"action-failed":() => _vm.processingRequest = false}},[_vm._v(" "+_vm._s(_vm.$t('general.label.download') + '(jpg)')+" ")]),_c('loading-btn',{staticClass:"btn",attrs:{"btn-props":{
        elevation: 0,
        rounded: true,
        color: 'f5f5f5',
      },"click-action":() => _vm.onDownloadClick(true),"disabled":!_vm.user || (_vm.user.isFreemium() && !_vm.purchased) || _vm.processingRequest,"show-failure-msg":""},on:{"click-action-done":function($event){return _vm.onRequestDone($event, true)},"action-failed":() => _vm.processingRequest = false}},[(!_vm.user || (_vm.user.isFreemium() && !_vm.purchased))?_c('v-icon',{staticClass:"mr-1",attrs:{"color":"white","small":""}},[_vm._v(" mdi-lock ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.$t('general.label.download') + '(png)')+" ")],1),_c('v-btn',{staticClass:"btn",attrs:{"disabled":_vm.processingRequest,"elevation":"0","rounded":""},on:{"click":_vm.onCancel}},[_vm._v(" "+_vm._s(_vm.$t('general.label.close'))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }