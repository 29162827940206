<template>
  <v-card>
    <v-card-title class="justify-center pt-10 pb-2">
      <v-icon
        v-if="!reported"
        color="secondary"
        size="80"
      >
        mdi-information
      </v-icon>
      <v-icon
        v-else
        color="green"
        size="80"
      >
        mdi-check-bold
      </v-icon>
    </v-card-title>
    <v-card-title class="justify-center">
      {{ reported ? $t('general.label.done') : $t('general.label.report') }}
    </v-card-title>
    <v-card-text>
      <v-container
        v-if="!reported"
        fluid
      >
        <div class="mb-2">
          {{ $t('reason') }}
        </div>
        <v-select
          v-model="reportReason"
          :items="REPORT_REASONS"
          :label="$t('general.label.pleaseSelect')"
          dense
        />
        <div>
          {{ $t('general.label.comment') }}
        </div>
        <v-textarea
          v-model="comment"
          class="mt-2"
          :counter="MAX_COMMENT_LENGTH"
          filled
          dense
          hide-spin-buttons
        />
      </v-container>
      <v-container v-else>
        <div class="text-center">
          {{ $t('thank-you') }}
        </div>
      </v-container>
      <loading-btn
        v-if="!reported"
        :btn-props="{
          elevation: 0,
          rounded: true,
          color: 'primary',
        }"
        class="btn"
        :click-action="onReportClick"
        :disabled="reportBtnDisabled"
        show-failure-msg
        @click-action-done="onRequestDone"
        @action-failed="() => processingRequest = false"
      >
        {{ $t('general.label.report') }}
      </loading-btn>
      <v-btn
        class="btn"
        :disabled="processingRequest"
        elevation="0"
        rounded
        @click="onCancel"
      >
        {{ $t('general.label.close') }}
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import LoadingBtn from '@/widgets/LoadingBtn'
import { APIRequest } from '@/utils'

const MAX_COMMENT_LENGTH = 500

export default {
  name: 'crypko-report-dialog',
  components: {
    LoadingBtn,
  },
  inject: ['cancel'],
  props: {
    hash: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'crypko',
    },
  },
  data () {
    return {
      MAX_COMMENT_LENGTH,
      REPORT_REASONS: [
        this.$t('reason-1'),
        this.$t('reason-2'),
        this.$t('reason-3'),
        this.$t('reason-4'),
      ],
      comment: '',
      reported: false,
      reportReason: null,
    }
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapFields('workbench', ['processingRequest']),
    reportBtnDisabled () {
      return this.comment.length > MAX_COMMENT_LENGTH
    },
  },
  methods: {
    onReportClick () {
      this.reported = false
      this.processingRequest = true
      switch (this.type) {
        case 'crypko': {
          const request = new APIRequest('/crypkos/report/', {
            hash: this.hash,
            reason: this.reportReason,
            comment: this.comment,
          })
          return request
        }
        case 'album': {
          const request = new APIRequest('/travel/album/report/', {
            hash: this.hash,
            reason: this.reportReason,
            comment: this.comment,
          })
          return request
        }
      }
    },
    onRequestDone () {
      this.reported = true
      this.processingRequest = false
    },
    onCancel () {
      this.reported = false
      this.processingRequest = false
      this.cancel()
    },
  },
}
</script>

<style scoped>
.btn {
  width: 100%;
  margin: 10px auto 0;
}

.cp-text {
  background-color: #fcb071;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  padding: 2px 5px;
}
</style>

<i18n>
{
  "ja": {
    "reason": "情報提供理由",
    "reason-1": "過剰な性的表現が含まれている",
    "reason-2": "過剰なグロテスク表現が含まれている",
    "reason-3": "児童ポルノ又は児童虐待表現が含まれている",
    "reason-4": "その他、利用規約に違反している",
    "thank-you": "ご報告ありがとうございます。"
  },
  "en": {
    "reason": "Reason",
    "reason-1": "Contains excessive sexual expression",
    "reason-2": "Contains excessive grotesque expression",
    "reason-3": "Contains child pornography or child abuse expressions",
    "reason-4": "Others, violates the terms of service",
    "thank-you": "Thank you for reporting"
  },
  "cn": {
    "reason": "理由",
    "reason-1": "含有过度的性描写",
    "reason-2": "含有过度的暴力血腥描写",
    "reason-3": "含有不恰当的儿童描写",
    "reason-4": "其他，违反服务条款",
    "thank-you": "感谢您的举报"
  }
}
</i18n>
