import { getField, updateField } from 'vuex-map-fields'

export default {
  namespaced: true,
  state: {
    adjustedCrypko: null,
    baseCrypko: null,
    crypkos: null,
    mode: 0,
    strength: 10,
    hand_scale: 10,
    clothes_scale: 5,
    selectedCrypkos: [],
  },
  mutations: {
    updateField,
    setBaseCrypko (state, crypko) {
      state.baseCrypko = crypko
    },
  },
  actions: {
  },
  getters: {
    getField,
  },
}