<template>
  <div class="text-center">
    <v-menu offset-y>
      <template #activator="{ on, attrs }">
        <v-btn
          color="grey lighten-3 grey--text text--darken-2"
          elevation="0"
          rounded
          v-bind="attrs"
          v-on="on"
        >
          {{ $t(`gallery.ordering.title`) }}
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in ORDERING_TYPES[orderingTypes]"
          :key="index"
          class="px-3"
          @click="$emit('change', item)"
        >
          <v-icon
            v-if="item == ordering"
            color="green"
          >
            mdi-check
          </v-icon>
          <v-icon v-else>
            mdi-blank
          </v-icon>
          <v-list-item-title class="ml-2">
            {{ $t(`gallery.ordering.${item}`) }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { ORDERING_TYPES } from '@/utils/constants'

export default {
  name: 'browser-sort',
  components: {
  },
  props: {
    ordering: {
      type: String,
      default: 'default',
    },
    orderingTypes: {
      type: String,
      default: 'crypko',
    },
  },
  data: function () {
    return {
      ORDERING_TYPES,
    }
  },
}
</script>
