<template>
  <v-tooltip bottom>
    <template #activator="{ on, attrs }">
      <v-sheet
        :class="activatorClass"
        color="#0000"
        v-bind="attrs"
        v-on="on"
      >
        <div
          v-if="user"
          class="d-flex align-center"
        >
          <div class="d-flex flex-column pg-bar-wrapper mr-2">
            <div class="d-flex justify-space-between text-caption">
              <div>CP</div>
              <div>{{ user.stamina.now }}/{{ user.stamina.max }}</div>
            </div>
            <v-progress-linear
              :buffer-value="user.stamina.max"
              color="light-green"
              height="8"
              rounded
              :value="barValue"
            />
          </div>
          <v-btn
            v-if="DEV_MODE"
            color="light-green"
            elevation="0"
            fab
            x-small
            @click="onFillClick"
          >
            <v-icon color="white">
              mdi-plus
            </v-icon>
          </v-btn>
        </div>
      </v-sheet>
    </template>
    <span>{{ remainSecs }}</span>
  </v-tooltip>
</template>

<script>
import { mapState } from 'vuex'
import { APIRequest } from '@/utils'
import { DEV_MODE } from '@/utils/constants'
import Dialog from '@/utils/dialog'

export default {
  name: 'stamina-widget',
  props: {
    activatorClass: {
      type: String,
      default: null,
    },
  },
  data () {
    return {
      DEV_MODE,
      remainSecs: '00:00',
      timer: null,
    }
  },
  computed: {
    ...mapState('user', ['user']),
    barValue () {
      if (!this.user) return 0
      return this.user.stamina.now / this.user.stamina.max * 100
    },
  },
  created () {
    this.timer = setInterval(this.updateRemainSecs, 1000)
  },
  destroyed () {
    clearInterval(this.timer)
  },
  methods: {
    updateRemainSecs () {
      if (!this.user) {
        clearInterval(this.timer)
        return
      }
      const remain = this.user.stamina.remain 
      if (remain && remain > 0) {
        const delta = remain * 1000 - Date.now() + this.user.updated
        if (delta < 1000) {
          this.remainSecs = '00:00'
        } else {
          if (delta > 60 * 60 * 1000) {
            this.remainSecs = new Date(delta).toISOString().substr(11, 8)
          } else {
            this.remainSecs = new Date(delta).toISOString().substr(14, 5)
          }
        }
      } else {
        this.remainSecs = this.$t('general.label.full')
      }
    },
    async onFillClick () {
      const request = new APIRequest('/users/fill_stamina/')
      try {
        const { status } = await request.send()
        if (status === 200) {
          await this.$store.dispatch('user/updateStamina')
          Dialog.showSuccessDialog()
        } else {
          throw new Error()
        }
      } catch (e) {
        Dialog.showFailedDialog()
      }
    },
  },
}
</script>

<style scoped lang="scss">
.pg-bar-wrapper {
  width: 94px;
}

.v-btn--fab.v-size--x-small {
  height: 18px;
  width: 18px;
}

.v-icon {
  font-size: 15px !important;
}
</style>
