<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue'
import getLayoutData from '@/utils/waterfall'

const props = defineProps({
  data: {
    type: Array,
    required: true,
  },
  columnWidth: {
    type: Number,
    default: 120,
  },
  boxSpacing: {
    type: Number,
    default: 10,
  },
  cols: {
    type: Number,
    default: undefined,
  },
  containerPadding: {
    type: Number,
    default: 0,
  },
})



const aspectRatioList = computed(() => props.data.map((item) => item.width / item.height))
const width = ref(500)

const columnWidth = computed(() => {
  return props.cols ? (width.value - 2 * props.containerPadding - (props.cols - 1) * props.boxSpacing) / props.cols - 1 : props.columnWidth
})

const waterfallWrapper = ref(null)
// FIXME: Use better detection method
let int = null
onMounted(() => {
  int = setInterval(() => {
    width.value = waterfallWrapper.value?.clientWidth ?? 500
  }, 100)
})

onBeforeUnmount(() => {
  clearInterval(int)
})


const positionData = computed(() => {
  if (width.value === 0) {
    return {
      containerHeight: 0,
      boxes: [],
    }
  }
  return getLayoutData(aspectRatioList.value, {
    containerWidth: width.value,
    direction: 'column',
    defaultColumnWidth: columnWidth.value,
    containerPadding: props.containerPadding,
    boxSpacing: props.boxSpacing,
    forceAspectRatio: true,
    showWidows: true,
    fullWidthBreakoutRowCadence: true,
  })
})

</script>

<template>
  <div
    ref="waterfallWrapper"
    style="position: relative; width: 100%; "
    :style="`height: ${positionData.containerHeight}px`"
    class="waterfall-wrapper"
  >
    <div
      v-for="(pos, idx) in positionData.boxes"
      :key="`${idx}-${pos['top']}-${pos['left']}-${pos['width']}-${pos['height']}`"
      :style="{
        position: 'absolute',
        top: `${pos['top']}px`,
        left: `${pos['left']}px`,
        width: `${pos['width']}px`,
        height: `${pos['height']}px`,
      }"
    >
      <slot :data="data[idx]" />
    </div>
  </div>
</template>