import { render, staticRenderFns } from "./Update240520.vue?vue&type=template&id=7c168727&scoped=true&"
import script from "./Update240520.vue?vue&type=script&lang=js&"
export * from "./Update240520.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c168727",
  null
  
)

export default component.exports