var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-dialog',{attrs:{"width":"fit-content"},model:{value:(_setup.isSelectingAlbum),callback:function ($$v) {_setup.isSelectingAlbum=$$v},expression:"isSelectingAlbum"}},[(_vm.album)?_c('v-card',[_c('v-card-title',{staticClass:"pa-2 pa-sm-3 flex-nowrap"},[_c('div',{staticClass:"d-flex flex-grow-1",staticStyle:{"gap":"1rem","overflow-x":"auto","overflow-y":"hidden"}},[_c('router-link',{staticClass:"cursor-pointer",attrs:{"tag":"a","to":{
            name: 'user-home',
            params: { id: _vm.album.user.id }
          },"target":"_blank"}},[(_vm.album)?_c('div',{staticClass:"d-flex align-center",staticStyle:{"gap":"0.5rem"}},[_c('div',[_c(_setup.UserAvatar,{attrs:{"user":_vm.album.user,"size":"36px","disable-menu":""}})],1),_c('div',[_c('div',{staticClass:"black--text font-weight-bold",staticStyle:{"font-size":"1rem","line-height":"1.25rem","overflow":"hidden","white-space":"nowrap","text-overflow":"ellipsis","max-width":"80px"}},[_vm._v(" "+_vm._s(_vm.album.user.username)+" ")]),_c('div',{staticClass:"text-caption grey--text",staticStyle:{"line-height":"1rem"}},[_vm._v(" "+_vm._s(_vm.$t('general.label.owner'))+" ")])])]):_vm._e()]),_c('v-divider',{attrs:{"vertical":""}}),_c('router-link',{staticClass:"cursor-pointer",attrs:{"tag":"a","to":{
            name: 'crypko',
            params: {
              hash: _vm.album?.crypko?.hash,
            },
          },"target":"_blank"}},[(_vm.album?.crypko)?_c('div',{staticClass:"d-flex align-center",staticStyle:{"gap":"0.5rem"}},[_c('div',[_c(_setup.CrypkoImg,{staticClass:"rounded-circle",attrs:{"size":"36","crypko":_vm.album.crypko,"face":""}})],1),_c('div',[_c('div',{staticClass:"black--text font-weight-bold",staticStyle:{"font-size":"1rem","line-height":"1.25rem","overflow":"hidden","white-space":"nowrap","text-overflow":"ellipsis","max-width":"80px"}},[_vm._v(" "+_vm._s(_vm.album.crypko.name)+" ")]),_c('div',{staticClass:"text-caption grey--text",staticStyle:{"line-height":"1rem"}},[_vm._v(" Crypko ")])])]):_vm._e()])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_setup.isSelectingAlbum = false}}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)],1),_c('div',{staticClass:"px-2"},[(['done', 'gen'].includes(_vm.album.message?.status))?_c(_setup.AlbumMsgBubble,{attrs:{"expandable":"","max-width":`calc((90vh - 110px) * ${_vm.album.width / _vm.album.height} - 16px)`,"msg":_vm.album.message,"bg-color":"rgba(139, 195, 74, 0.1)","font-color":"rgb(139, 195, 74)"}}):_vm._e()],1),_c('div',{staticStyle:{"position":"relative"}},[_c('v-img',{staticClass:"cursor-pointer",attrs:{"max-height":"calc(90vh - 110px)","max-width":`calc((90vh - 110px) * ${_vm.album.width / _vm.album.height})`,"contain":"","src":_setup.albumURL},on:{"click":() => _setup.onImgClick()}},[_c('div',{staticStyle:{"position":"absolute","bottom":"0.25rem","left":"0.25rem"}},[_c(_setup.albumInfoDialog,{attrs:{"album":_vm.album},on:{"removed":_setup.onRemoved,"update":_setup.onUpdate}})],1),_c('div',{staticStyle:{"position":"absolute","bottom":"0.75rem","right":"0.75rem"}},[_c(_setup.albumLikeBtn,{attrs:{"album":_vm.album}})],1)])],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }