import { getField, updateField } from 'vuex-map-fields'

export default {
  namespaced: true,
  state: {
    baseCrypko: null,
    adjustedCrypko: null,
    keypoints: null,
    defaultKeypoints: null,
    refCrypko: null,
  },
  mutations: {
    updateField,
    setBaseCrypko (state, crypko) {
      state.baseCrypko = crypko
    },
  },
  actions: {
  },
  getters: {
    getField,
  },
}