<script setup>
import UserRankItem from './UserRankItem.vue'
import LoadingProgressCircular from '@/widgets/LoadingProgressCircular'
import store from '@/store'
import { computed, ref } from 'vue'
import PromptDialogWrapper from '@/widgets/PromptDialogWrapper.vue'
import RankDialog from '@/widgets/RankDialog.vue'
const props = defineProps({
    users: {
        type: Array,
        default: null,
    },
    title: {
        type: String,
        default: '',
    },
    self: {
        type: Object,
        default: null,
    },
})
const userData = computed(() => store.getters['user/user'])
const shouldShowSelf = computed(() => {
    return (props.self && props.self.ranking > props.users.length) || !props.self
})
const rankDialogRef = ref(null)
const title = computed(() => {
    return props.title
})
</script>

<template>
  <VList
    v-if="users !== undefined"
    :dense="$vuetify.breakpoint.mdAndUp ? false : true"
    style="border-radius: 1rem;"
  >
    <VSubheader>
      <span
        class="font-weight-bold"
        style="color: #616161"
      >
        {{ $t(`rank.${title}`) }}
      </span>
      <v-spacer />
      <a
        v-if="$vuetify.breakpoint.smAndUp"
        style="color: rgba(0, 0, 0, 0.6)"
        @click="rankDialogRef.show()"
      >
        {{ $t('general.label.more') }}
      </a>
    </VSubheader>
    <div>
      <UserRankItem
        v-for="user, i in users"
        :key="user.id"
        :index="i"
        :user="user"
      />
    </div>
    <VDivider v-if="shouldShowSelf" />
    <UserRankItem
      v-if="shouldShowSelf"
      :user="self ? self : {
        id: userData.id,
        username: userData.username,
        count: 0,
        ranking: '-',
      }"
      :index="4"
    />
    <div
      v-if="$vuetify.breakpoint.xsOnly"
      class="text-center py-1 text-caption"
    >
      <a
        style="color: rgba(0, 0, 0, 0.6)"
        @click="rankDialogRef.show()"
      >
        {{ $t('general.label.more') }}
      </a>
    </div>
    <PromptDialogWrapper
      ref="rankDialogRef"
      scrollable
      max-width="400px"
    >
      <RankDialog :title="title" />
    </PromptDialogWrapper>
  </VList>
  <LoadingProgressCircular
    v-else
    class="mb-8"
  />
</template>