<template>
  <div
    class="d-flex flex-column"
    style="gap: 2rem;"
  >
    <div class="text-center">
      <v-chip
        class="primary--text"
        color="rgba(242, 118, 119, 0.1)"
      >
        <h2>
          {{ $t('general.label.feature') }}
        </h2>
        <v-divider
          class="mx-2 py-2"
          style="border-color: #fff"
          vertical
        />
        <h2>
          {{ $t('header.router.create-generate') }}
        </h2>
      </v-chip>
      <div class="mt-2">
        {{ $t(`help.create.generate.desc`) }}
      </div>
    </div>
    <help-supported-models feature="gen" />
    <div>
      <h3 class="mb-2">
        {{ $t('help.usage') }}
      </h3>
      <div>
        <p>{{ $t(`help.create.generate.1`) }}</p>
        <v-img
          :max-width="HELP_IMAGE_MAX_WIDTH"
          src="/images/help/generation1.png"
        />
      </div>
      <div>
        <p>{{ $t(`help.create.generate.2`) }}</p>
        <v-img
          :max-width="HELP_IMAGE_MAX_WIDTH"
          src="/images/help/generation2.png"
        />
        <v-img
          :max-width="HELP_IMAGE_MAX_WIDTH"
          src="/images/help/generation3.png"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { HELP_IMAGE_MAX_WIDTH, GEN_MODEL_CONFIG } from '@/utils/constants'
import HelpSupportedModels from '@/widgets/HelpSupportedModels'

export default {
  name: 'help-generate',
  components: {
    HelpSupportedModels,
  },
  data () {
    return {
      HELP_IMAGE_MAX_WIDTH,
      GEN_MODEL_CONFIG,
    }
  },
}
</script>

<style lang="scss" scoped>
.v-image {
  margin: 0 0 20px;
}
</style>
