var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex mx-sm-auto",staticStyle:{"overflow-x":"auto","overflow-y":"hidden"}},[_c('div',{staticClass:"d-flex",staticStyle:{"gap":"0.5rem"}},[_c('v-btn',{staticClass:"rounded-pill px-2",attrs:{"color":"grey darken-2","to":{
        name: 'gallery'
      },"ripple":false,"exact":"","text":"","height":"32px"},on:{"click":() => _vm.$emit('changeSearch', [])}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" mdi-home ")]),_vm._v(" "+_vm._s(_vm.$t('gallery.views.home'))+" ")],1),_c('v-btn',{staticClass:"rounded-pill px-2",attrs:{"color":"grey darken-2","to":{
        name: 'gallery-crypko'
      },"ripple":false,"exact":"","text":"","height":"32px"},on:{"click":() => _vm.$emit('changeSearch', [])}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" mdi-face-man ")]),_vm._v(" "+_vm._s(_vm.$t('general.crypko'))+" ")],1),_c('v-btn',{staticClass:"rounded-pill px-2",attrs:{"color":"grey darken-2","to":{
        name: 'gallery-travel'
      },"ripple":false,"exact":"","text":"","height":"32px"}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" mdi-image-album ")]),_vm._v(" "+_vm._s(_vm.$t('header.router.travel'))+" ")],1)],1),_c('v-dialog',{attrs:{"max-width":"768"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"rounded-pill px-0",staticStyle:{"position":"absolute","right":"16px"},attrs:{"text":"","min-width":"32px","height":"32px"}},on),[_c('v-icon',{attrs:{"size":"20px"}},[_vm._v(" mdi-magnify ")])],1)]}}])},[_c('v-card',{staticStyle:{"display":"flex","align-items":"center","justify-content":"center","padding":"1rem"}},[_c('v-combobox',{staticClass:"combobox rounded-pill",attrs:{"append-icon":null,"multiple":"","chips":"","autocomplete":"","single-line":"","clearable":"","hide-details":"","items":_vm.searchItems,"dense":"","flat":"","hide-no-data":_vm.inputValue === '',"filled":"","filter":_vm.filter,"label":_vm.$t('search.hint'),"prepend-inner-icon":"mdi-magnify"},on:{"input":_vm.updateValue,"update:search-input":_vm.updateSearchInput},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [(_vm.inputValue[0] !== '#')?_c('div',{staticClass:"pa-2"},[_vm._v(" "+_vm._s(_vm.$t(`search.no-data`))+" ")]):_c('div',{staticClass:"pa-2"},[_vm._v(" "+_vm._s(_vm.$t(`search.search-by-hash`))+" ")])]},proxy:true},{key:"selection",fn:function({ attrs, item, selected }){return [_c('v-chip',_vm._b({attrs:{"color":`${item.color} lighten-3`,"input-value":selected,"label":"","small":""}},'v-chip',attrs,false),[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" "+_vm._s(_vm.getIcon(item))+" ")]),_vm._v(" "+_vm._s(_vm.tagDisplayName(item))+" ")],1)]}},{key:"item",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.tagDisplayName(item))+" ")]}}]),model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }