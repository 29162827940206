<template>
  <v-card>
    <v-card-title class="justify-center pt-10 pb-2">
      <v-icon
        v-if="!deleted"
        color="primary"
        size="80"
      >
        mdi-delete-circle
      </v-icon>
      <v-icon
        v-else
        color="green"
        size="80"
      >
        mdi-check-bold
      </v-icon>
    </v-card-title>
    <v-card-title class="justify-center">
      {{ deleted ? $t('general.label.done') : $t('general.label.delete') }}
    </v-card-title>
    <v-card-text>
      <div
        class="mb-8 text-center"
      >
        {{ deleted ? $t('reload-desc') : $t('delete-desc') }}
      </div>
      <loading-btn
        v-if="!deleted"
        :btn-props="{
          elevation: 0,
          rounded: true,
          color: 'primary',
        }"
        class="btn"
        :click-action="onDeleteClick"
        show-failure-msg
        @click-action-done="onRequestDone"
        @action-failed="() => processingRequest = false"
      >
        {{ $t('general.label.delete') }}
      </loading-btn>
      <v-btn
        class="btn"
        :disabled="processingRequest"
        elevation="0"
        rounded
        @click="onCancel"
      >
        {{ $t('general.label.close') }}
      </v-btn>
    </v-card-text>
    <prompt-dialog-wrapper
      ref="licenseConfirmDialog"
    >
      <confirm-dialog
        :msg="$t('general.msg.delete-licensed-crypko')"
      />
    </prompt-dialog-wrapper>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import { APIRequest } from '@/utils'
import LoadingBtn from '@/widgets/LoadingBtn'
import PromptDialogWrapper from '@/widgets/PromptDialogWrapper'
import ConfirmDialog from '@/widgets/ConfirmDialog'

export default {
  name: 'crypko-delete-dialog',
  components: {
    LoadingBtn,
    PromptDialogWrapper,
    ConfirmDialog,
  },
  inject: ['cancel'],
  props: {
    crypkos: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      deleted: false,
    }
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapFields('workbench', ['processingRequest']),
    request () {
      return new APIRequest('/crypkos/delete/', {
        hashes: this.crypkos.map(c => c.hash),
      })
    },
  },
  methods: {
    async onDeleteClick () {
      this.deleted = false
      if (this.crypkos.some(c => c.purchased)) {
        const res = await this.$refs.licenseConfirmDialog.show()
        if (!res) return null
      }
      this.processingRequest = true
      return this.request
    },
    onRequestDone () {
      this.deleted = true
      this.processingRequest = false
    },
    onCancel () {
      this.deleted = false
      this.processingRequest = false
      this.cancel()
    },
  },
}
</script>

<style scoped>
.btn {
  width: 100%;
  margin: 10px auto 0;
}

.cp-text {
  background-color: #fcb071;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  padding: 2px 5px;
}
</style>

<i18n>
{
  "ja": {
    "reload-desc": "再読み込みで結果をロードできます。",
    "delete-desc": "選択したCrypkoを削除しますか？"
  },
  "en": {
    "reload-desc": "You can reload page to see the results.",
    "delete-desc": "Do you want to delete selected Crypko(s)?"
  },
  "cn": {
    "reload-desc": "刷新页面后可以看到结果。",
    "delete-desc": "确认删除选中的Crypko吗？"
  }
}
