<template>
  <div
    class="mx-auto pagination-wrapper d-flex justify-center align-center flex-wrap"
    style="gap: 0.75rem;"
  >
    <v-pagination
      v-model="currentPage"
      :length="paginationLength"
      :total-visible="totalVisible"
      circle
      @input="changePage"
    />
    <div
      class="d-flex align-center flex-no-wrap"
      style="gap: 0.5rem"
    >
      <v-text-field
        ref="pageInput"
        v-model.number="tmpPage"
        color="primary"
        style="width: 72px"
        min="1"
        label="Page"
        :max="paginationLength"
        type="number"
        hide-details
        dense
        outlined
        @keyup.enter="goToPage"
      />
    </div>
    <a
      class="font-weight-bold"
      style="cursor: pointer; color: #767676"
      @click="goToPage"
    >
      Go
    </a>
  </div>
</template>

<script>
export default {
  name: 'pagination',
  props: {
    page: {
      type: Number,
      default: null,
    },
    paginationLength: {
      type: Number,
      default: null,
    },
  },
  data () {
    return {
      currentPage: this.page,
      tmpPage: null,
    }
  },
  computed: {
    totalVisible: function () {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return 7
      } else {
        return 5
      }
    },
  },
  watch: {
    page () {
      this.currentPage = this.page
    },
  },
  methods: {
    changePage (page) {
      this.$emit('change', parseInt(page) || this.currentPage)
    },
    goToPage () {
      if (this.tmpPage >= 1 && this.tmpPage <= this.paginationLength) {
        this.currentPage = this.tmpPage
        this.$refs.pageInput.blur()
        this.changePage(this.tmpPage)
      } else {
        alert(`Please enter a number between 1 and ${this.paginationLength}`)
      }
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep {
  .v-pagination {
    &__item {
      box-shadow: none !important;
      &:not(.v-pagination__item--active) {
        background: none !important;
      }
    }

    &__navigation {
      box-shadow: none !important;
      background: none !important;
    }
  }
}
</style>
