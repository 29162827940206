import { getField, updateField } from 'vuex-map-fields'
import router from '../../router'
import Crypko from '../../utils/crypko'
import Dialog from '@/utils/dialog'
import _ from 'lodash'
import { APIRequest } from '@/utils'

export default {
  namespaced: true,
  state: {
    crypko: null,  // a crypko with details
    crypkos: null,  // a list of crypkos without details
    idx: 0,
  },
  mutations: {
    updateField,
    setCrypko (state, crypko) {
      state.crypko = crypko
      // Fix bug, but why in production it's not needed?
      if (!_.isEmpty(state.crypkos)) {
        state.idx = state.crypkos.findIndex(c => c.hash === crypko.hash)
      }
    },
    fav (state, flag) {
      if (state.crypko.faved === flag) return
      state.crypko.faved = flag
      state.crypko.fav_count += flag ? 1 : -1
    },
  },
  actions: {
    async fetchCrypko ({ commit }, hash) {
      hash = hash || router.currentRoute.params.hash
      if (!hash) {
        throw new Error('Cannot fetch crypko without knowing its hash.')
      }
      try {
        const crypko = await Crypko.fetch(hash)
        const albumsData = await fetchCrypkoAlbums(hash)
        crypko.albums = albumsData.map(d => ({
          ...d,
          crypko: new Crypko(d.crypko),
        }))
        commit('setCrypko', crypko)
      } catch (error) {
        handleAPIError(error)
        // throw error to ensure the 404 page is shown
        throw error
      }
    },
    async favCrypko ({ commit, state }, flag) {
      try {
        await Crypko.fav(state.crypko.hash, flag)
        commit('fav', flag)
      } catch (error) {
        handleAPIError(error)
      }
    },
    async patchCrypko ({ dispatch, state }, params) {
      try {
        await Crypko.patch(state.crypko.hash, params)
        await dispatch('fetchCrypko', state.crypko.hash)
      } catch (error) {
        handleAPIError(error)
      }
    },
  },
  getters: {
    getField,
    centerCrypko: (state) => state.crypkos && state.crypkos[state.idx],
    carouselTotal: (state) => state.crypkos && state.crypkos.length,
    isFirst: (state) => state.idx === 0,
    isLast: (state, getters) => (state.idx === getters.carouselTotal - 1) || !state.crypkos,
    isOwner: (state, getters, rootState, rootGetters) =>
      rootGetters['user/user'] && rootGetters['user/user'].id === state.crypko.owner.id,
    nextCrypko: (state, getters) => state.crypkos &&
      state.crypkos[state.idx >= getters.carouselTotal - 1 ? getters.carouselTotal - 1 : state.idx + 1],
    prevCrypko: (state) => state.crypkos &&
      state.crypkos[state.idx <= 0 ? 0 : state.idx - 1],
  },
}

// Utility function to fetch Crypko albums
async function fetchCrypkoAlbums (hash) {
  const { data } = await new APIRequest('/travel/crypko_albums/?hash=' + hash, null, 'get').send()
  return data
}

// Generic error handler
function handleAPIError (error) {
  console.error(error)
  Dialog.showFailedDialog()
}
