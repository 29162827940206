import axios from 'axios'
import _ from 'lodash'
import { DEFAULT_ORDERING_TYPE, ORDERING_TYPES } from './constants'
import * as Sentry from '@sentry/vue'
import GAUtils from './ga'

export function getOrderingTypes (browsCategory) {
  return ORDERING_TYPES[browsCategory]
}

export function getDefaultOrderingType (browsCategory) {
  return DEFAULT_ORDERING_TYPE[browsCategory]
}

export function appendPngPrefix (src) {
  return 'data:image/png;base64,' + src
}

export function isValidLatentVec (latentVec, { gan: { noiseLength, labelLength } }) {
  const latentVecLen = noiseLength + labelLength
  return latentVec.length && latentVec[0].length === latentVecLen && latentVec[1].length === latentVecLen
}

export async function sleep (interval) {
  await new Promise(resolve => setTimeout(() => resolve(), interval))
}

// e.g. #12CDEF -> [18, 205, 239]
export function hex2rgb (hex) {
  hex = hex.replace('#', '')
  return _.times(3, i => parseInt(hex.substring(2 * i, 2 * (i + 1)), 16))
}

// * Map props to a computed variable that can be used for v-model
// https://forum.vuejs.org/t/generating-computed-properties-on-the-fly/14833/3
// A prop will be mapped to a computed propModel 
export function mapPropsModels (props = [], { object, event } = {}) {
  return props.reduce((obj, prop) => {
    const propModel = prop + 'Model'
    const computedProp = {
      get () {
        return object ? this[object][prop] : this[prop]
      },
      set (value) {
        if (event) {
          this.$emit(event, {
            prop,
            value,
          })
        } else {
          this.$emit(prop, value)
        }
      },
    }
    obj[propModel] = computedProp
    return obj
  }, {})
}


// - API related
export class APIRequest {

  static token = null
  static tokenPromise

  constructor (route, params, method = 'post', auth = true) {
    this.route = route
    this.params = params
    this.method = method
    this.auth = auth  // use jwt to authenticate
    this.query = {}
    this.source = null
    this.config = {}
  }

  async send () {
    const CancelToken = axios.CancelToken
    this.source = CancelToken.source()
    this.config = {
      ...this.config,
      cancelToken: this.source.token,
    }
    if (this.auth) {
      await APIRequest.checkAndRefreshToken()
      if (APIRequest.token && APIRequest.token.jwt) {
        this.config.headers = {
          'Authorization': `Bearer ${APIRequest.token.jwt}`,
        }
      }
    }

    let route = this.route
    if (!_.isEmpty(this.query)) {
      let query = '?'
      for (const [k, v] of Object.entries(this.query)) {
        query += `${k}=${v}&`
      }
      route += query.slice(0, -1)
    }

    let result
    if (['put', 'post', 'patch'].includes(this.method)) {
      result = await axios[this.method](route, this.params, this.config)
    } else {
      result = await axios[this.method](route, this.config)
    }
    GAUtils.logEventFromAPIRequest(this)
    return result
  }

  supressHTTPError (max_code = 500) {
    this.config.validateStatus = status =>
      status >= 200 && status < max_code
  }

  cancel () {
    if (this.source) {
      this.source.cancel()
    }
  }

  static setToken (token) {
    if (!token) {
      APIRequest.token = null
      Sentry.setUser(null)
      return
    }
    const parsedToken = JSON.parse(window.atob(token.split('.')[1]))
    const { exp, user_id } = parsedToken
    APIRequest.token = {
      jwt: token,
      id: user_id,
      exp,
    }
    Sentry.setUser({ id: user_id })
  }

  static async checkAndRefreshToken () {
    if (!APIRequest.token || APIRequest.token.exp < Date.now() / 1000) {
      if (!APIRequest.tokenPromise) {
        APIRequest.tokenPromise = axios.get('/auth/refresh_token/', { timeout: 5000 })
      }
      try {
        const { data, status } = await APIRequest.tokenPromise
        if (status === 200) {
          APIRequest.setToken(data.access_token)
        }
      } catch (e) {
        console.error(e)
      }
      APIRequest.tokenPromise = null
    }
  }
}


// - vuex-map-fields
// mapFields with dynamic namespace
// https://github.com/maoberlehner/vuex-map-fields/blob/master/src/index.js#L40
// a vuexModule computed property or prop must be presented
export function mapFieldsDynamic (fields) {
  return fields.reduce((acc, path) => {
    acc[path] = {
      get () {
        return this.$store.getters[`${this.vuexModule}/getField`](path)
      },
      set (value) {
        this.$store.commit(`${this.vuexModule}/updateField`, {
          path,
          value,
        })
      },
    }
    return acc
  }, {})
}

export async function downloadFromUrl (url, filename) {
  const blob = await axios.get(url, {
    headers: {
      'Content-Type': 'application/octet-stream',
    },
    responseType: 'blob',
    withCredentials: false,
  })
  const a = document.createElement('a')
  const href = window.URL.createObjectURL(blob.data)
  a.href = href
  a.download = filename
  a.click()
}

export * from './formattedRemainTime'

export const datetimeFormatter = (() => {
  let lang = JSON.parse(localStorage.getItem('vuex'))?.lang
  switch (lang) {
    case 'cn':
      lang = 'zh-CN'
      break
    case 'ja':
      lang = 'ja-JP'
      break
    default:
      lang = 'en-US'
  }
  return new Intl.DateTimeFormat(
    lang, {
    // year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  })
})()