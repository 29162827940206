<template>
  <v-main>
    <!-- Rerender the view component when route.name changes -->
    <!-- Useful for sharing components between routes -->
    <!-- https://forum.vuejs.org/t/rerendering-component-on-route-param-change-recalling-created-hooks/9536/6 -->
    <router-view />
  </v-main>
</template>

<script>

export default {
  name: 'app-layout',
}
</script>
