<template>
  <div
    v-if="profileUser"
    class="wrapper"
  >
    <v-sheet
      class="d-flex align-center px-4"
      :class="$vuetify.breakpoint.xsOnly ? 'rounded-0' : 'rounded-xl'"
      height="100px"
    >
      <user-avatar
        disable-menu
        size="70px"
        :user="profileUser"
      />
      <div class="text-h5 ml-5">
        {{ profileUser.username }}
      </div>
      <v-spacer />
      <follow-btn
        v-if="user && $route.params.id !== user.id"
        style="width: 100px"
        :btn-props="{
          small: true,
        }"
        :user="profileUser"
      />
      <v-badge
        v-if="user && $route.params.id === user.id"
        id="badge-wrapper"
        bordered
        color="light-green"
        overlap
        offset-x="16"
        offset-y="16"
      >
        <v-btn
          slot="badge"
          dark
          icon
          :ripple="false"
          style="z-index: 1;"
          x-small
          :to="{ name: 'plans' }"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-sheet
          class="d-flex flex-column justify-space-between px-3 pt-3 pb-2"
          color="grey lighten-3"
          height="76px"
          width="120px"
          rounded="16"
        >
          <div class="d-flex justify-center align-center">
            <v-icon class="mr-2">
              mdi-face
            </v-icon>
            <div>
              {{ user.slots.now }}
            </div>
          </div>
          <div>
            <v-progress-linear
              class="slot-bar"
              :value="100 * user.slots.now / user.slots.max"
              color="grey darken-1"
              height="5px"
              rounded
            />
            <div class="d-flex justify-space-between text-caption">
              <div>{{ $t('general.slot') }}</div>
              <div>
                {{ user.slots.max }}
              </div>
            </div>
          </div>
        </v-sheet>
      </v-badge>
    </v-sheet>
    <v-sheet
      class="d-flex justify-center"
      :class="$vuetify.breakpoint.xsOnly ? 'rounded-0' : 'rounded-xl'"
      height="100px"
      width="100%"
    >
      <div
        class="d-flex align-center px-3 justify-space-between fill-height"
        style="max-width: 600px; overflow: auto; width: 100%; gap: 12px"
      >
        <router-link
          tag="button"
          :to="{
            name: 'followers-user',
          }"
        >
          <v-sheet
            class="pa-3"
            color="grey lighten-3"
            rounded="16"
            min-width="86px"
            elevation="0"
          >
            <div class="text-h6 d-flex align-center justify-center">
              <v-icon class="mr-1">
                mdi-account
              </v-icon>
              <span>
                {{ profileUser.follower_count }}
              </span>
            </div>
            <div class="text-caption text-center font-weight-bold">
              {{ $t('header.router.followers-user') }}
            </div>
          </v-sheet>
        </router-link>
        <router-link
          tag="button"
          :to="{
            name: 'followings-user',
          }"
        >
          <v-sheet
            class="pa-3"
            color="grey lighten-3"
            rounded="16"
            min-width="86px"
          >
            <div class="text-h6 d-flex align-center justify-center">
              <v-icon class="mr-1">
                mdi-account
              </v-icon>
              <span>
                {{ profileUser.following_count }}
              </span>
            </div>
            <div class="text-caption text-center font-weight-bold">
              {{ $t('header.router.followings-user') }}
            </div>
          </v-sheet>
        </router-link>
        <v-sheet
          class="pa-3"
          color="grey lighten-3"
          rounded="16"
          min-width="86px"
        >
          <div class="text-h6 d-flex align-center justify-center">
            <v-icon
              class="mr-1"
              color="primary"
            >
              mdi-heart
            </v-icon>
            <span>
              {{ profileUser.fav_count }}
            </span>
          </div>
          <div class="text-caption text-center">
            {{ $t('general.label.fav') }}
          </div>
        </v-sheet>
        <v-sheet
          class="pa-3"
          color="grey lighten-3"
          rounded="16"
          min-width="86px"
        >
          <div class="text-h6 d-flex align-center justify-center">
            <v-icon
              class="mr-1"
              color="light-green"
              size="20"
            >
              mdi-vector-combine
            </v-icon>
            <span>{{ profileUser.crossfuse_count }}</span>
          </div>
          <div class="text-caption text-center">
            {{ $t('crypko.crossfuse') }}
          </div>
        </v-sheet>
      </div>
    </v-sheet>
  </div>
</template>

<script>
import resize from 'vue-resize-directive'
import { MIN_WINDOW_WIDTH, USER_NAV_BTN } from '@/utils/constants'
import { mapFields } from 'vuex-map-fields'
import FollowBtn from '@/widgets/FollowBtn'

export default {
  name: 'user-layout-header',
  components: {
    UserAvatar: () => import('../widgets/UserAvatar'),
    FollowBtn,
  },
  directives: {
    resize,
  },
  data () {
    return {
      MIN_WINDOW_WIDTH,
      USER_NAV_BTN,
    }
  },
  computed: {
    ...mapFields('user', ['profileUser', 'user']),
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.wrapper > div {
  flex-basis: calc(50% - 6px);
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    flex-basis: 100%;
  }
}

::v-deep {
  .v-badge__badge {
    padding: 0px;
    cursor: pointer;
  }
}

.slot-bar {
  margin: 2px 0 1px;
}
</style>
