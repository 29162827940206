<script setup>
import Crypko from '@/utils/crypko'
import GalleryAlbumSection from './GalleryAlbumSection.vue'
import { computedAsync } from '@vueuse/core'
const trendCrypkos = computedAsync(async ()=>{
  return await Crypko.getGallerySectionData('trend')
})
const albumCrypkos = computedAsync(async ()=>{
  return await Crypko.getGallerySectionData('album')
})

</script>
<template>
  <div
    class="wrapper mx-auto d-flex flex-column align-center"
    style="gap: 8px"
  >
    <GalleryAlbumSection
      :loading="crypkos === undefined"
      :title="$t(`gallery.views.${GALLERY_VIEW_NAME.ALBUM}`)"
      icon="mdi-image-album"
      :data="albumCrypkos"
    />
    <gallery-crypko-section
      :crypkos="trendCrypkos"
      :title="$t(`gallery.views.${GALLERY_VIEW_NAME.TREND}`)"
      :to="{ name: GALLERY_ROUTE_NAME.TREND }"
      icon="mdi-trending-up"
    />
    <gallery-rank-section
      :title="$t(`gallery.views.${GALLERY_VIEW_NAME.RANK}`)"
      icon="mdi-trophy"
    />
  </div>
</template>

<script>
import GalleryCrypkoSection from './GalleryCrypkoSection.vue'
import GalleryRankSection from '@/components/GalleryRankSection.vue'
import { GALLERY_ROUTE_NAME, GALLERY_VIEW_NAME } from '@/utils/constants'

export default {
  name: 'gallery-home',
  components: {
    GalleryCrypkoSection,
    GalleryRankSection,
  },
  data () {
    return {
      GALLERY_ROUTE_NAME,
      GALLERY_VIEW_NAME,
      crypkos: null,
    }
  },
}
</script>
