<template>
  <v-card
    class="wrapper fill-width"
    elevation="0"
    :rounded="$vuetify.breakpoint.mdAndUp ? 'lg' : '0'"
  >
    <v-card-title class="d-flex pb-2 align-center px-0">
      <v-sheet
        v-if="icon"
        class="d-flex justify-center align-center mr-1"
        color="primary"
        width="24px"
        height="24px"
        rounded="circle"
      >
        <v-icon
          color="white"
          size="15px"
        >
          {{ icon }}
        </v-icon>
      </v-sheet>
      <div class="text-h6 grey--text text--darken-2 font-weight-bold">
        <slot name="title">
          {{ title }}
        </slot>
      </div>
      <v-spacer />
      <v-btn
        class="px-1"
        elevation="0"
        text
        :to="to"
      >
        {{ $t('general.label.more') }}
      </v-btn>
    </v-card-title>
    <v-divider />
    <v-card-text class="px-0">
      <crypko-grid
        v-if="crypkos && crypkos.length"
        ref="crypkoGrid"
        :card-props="{
          displayName: true,
          dropShadow: true,
          favIcon: true,
        }"
        :crypkos="displayCrypkos"
        v-on="$listeners"
      />
      <div
        v-else-if="crypkos"
        class="text-center"
      >
        {{ $t('general.msg.empty') }}
      </div>
      <loading-progress-circular
        v-else
        class="mb-8"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import CrypkoGrid from '@/components/CrypkoGrid'
import LoadingProgressCircular from '@/widgets/LoadingProgressCircular'

export default {
  name: 'gallery-crypko-section',
  components: {
    CrypkoGrid,
    LoadingProgressCircular,
  },
  props: {
    crypkos: {
      type: Array,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    to: {
      type: Object,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
  },
  data () {
    return {
      windowWidth: window.innerWidth,
    }
  },
  computed: {
    displayCrypkos () {
      if ((this.windowWidth <= 1007 && this.windowWidth >= 740) || (this.windowWidth >= 560 && this.windowWidth < 600)) {
        return this.crypkos.slice(0, 3)
      }
      if (this.$vuetify.breakpoint.smAndUp) {
        return this.crypkos
      }
      return this.crypkos.slice(0, 4)
    },
  },
  watch: {
    '$vuetify.breakpoint.name' () {
      if (this.$refs.crypkoGrid) {
        this.$refs.crypkoGrid.$forceUpdate()
      }
    },
  },
  mounted () {
    // onResize
    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize () {
      this.windowWidth = window.innerWidth
    },
  },
}
</script>

<style scoped lang="scss">
.wrapper {
  background-color: #0000;
}
</style>
