<template>
  <v-dialog
    v-model="dialog"
    max-width="400px"
  >
    <template #activator="{ on, attrs }">
      <slot v-bind="{ on, attrs }" />
    </template>
    <v-card>
      <v-card-title>{{ $t('privacy.setting') }}</v-card-title>
      <v-card-text v-if="user">
        <p
          v-if="!hasPermission"
          class="primary--text"
        >
          *{{ $t('general.msg.enableAfterSubscribe') }}
        </p>
        <v-radio-group
          v-model="radioGroup"
          :disabled="!hasPermission"
        >
          <div
            v-for="(option, i) in options"
            :key="i"
          >
            <v-radio
              :label="option.label"
              :value="i"
              color="primary"
            />
            <p class="ml-8 grey--text">
              {{ option.description }}
            </p>
          </div>
        </v-radio-group>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="grey darken-1"
          text
          @click="() => dialog = false"
        >
          {{ $t('general.label.close') }}
        </v-btn>
        <loading-btn
          v-if="hasPermission"
          :btn-props="{
            color: 'primary',
            text: true,
            rounded: true,
          }"
          :click-action="saveRequest"
          @click-action-done="onSaved"
          @action-failed="() => Dialog.showFailedDialog()"
        >
          {{ $t('general.label.save') }}
        </loading-btn>
        <v-btn
          v-else
          class="px-3"
          color="primary"
          elevation="0"
          rounded
          :to="{ name: 'plans' }"
        >
          {{ $t('payment.view-plans') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import { APIRequest } from '@/utils'
import Dialog from '@/utils/dialog'
import LoadingBtn from '@/widgets/LoadingBtn'

export default {
  name: 'privacy-dialog',
  components: {
    LoadingBtn,
  },
  props: {
    crypkos: {
      type: Array,
      default: null,
    },
  },
  data () {
    return {
      Dialog,
      dialog: false,
      radioGroup: 1,
      options: [
        {
          label: this.$t('privacy.public.title'),
          value: true,
          description: this.$t('privacy.public.description'),
        },
        {
          label: this.$t('privacy.private.title'),
          value: false,
          description: this.$t('privacy.private.description'),
        },
      ],
    }
  },
  computed: {
    ...mapState('user', ['user']),
    saveRequest () {
      const hashes = this.crypkos.map(c => c.hash)
      const params = {
        hashes,
        public: this.options[this.radioGroup].value,
      }
      return new APIRequest(`/crypkos/update_privacy/`, params)
    },
    hasPermission () {
      return this.user && (!this.user.isFreemium() || this.crypkos.every(c => c.purchased))
    },
  },
  created () {
    if (!this.crypkos || !this.crypkos.length) return
    if (this.crypkos.every(c => c.public)) {
      this.radioGroup = 0
    }
  },
  methods: {
    onSaved () {
      this.$emit('change')
      Dialog.showSuccessDialog()
      this.dialog = false
    },
  },
}
</script>
