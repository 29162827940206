<template>
  <v-dialog
    v-model="dialog"
    max-width="300"
    persistent
  >
    <v-card>
      <v-card-title class="text-subtitle-1">
        {{ title || $t('general.msg.fetching') }}
      </v-card-title>
      <v-card-text>
        <v-progress-linear
          indeterminate
          class="mb-0"
        />
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          text
          @click="onCanceled"
        >
          {{ $t('general.label.cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import Dialog from '@/utils/dialog'

export default {
  name: 'loading-dialog',
  props: {
    title: {
      type: String,
      default: '',
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      dialog: false,
      request: null,
    }
  },
  methods: {
    async send (request) {
      if (this.$_.isEmpty(request)) return
      this.request = request
      this.dialog = true
      try {
        const res = await request.send()
        this.dialog = false
        return res
      } catch (thrown) {
        this.dialog = false
        if (axios.isCancel(thrown)) {
          console.log('request canceled')
        } else {
          Dialog.showFailedDialog()
          throw thrown
        }
      }
    },
    onCanceled () {
      this.request.cancel()
    },
  },
}
</script>

<style scoped lang="scss">
</style>
