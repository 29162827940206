<template>
  <div>
    <img
      :src="$t('changelogs.231027.img')"
      style="object-fit: cover; max-width: 100%"
    >
    <v-card-title
      class="py-0"
      style="font-size: 1rem; font-weight: 600;"
    >
      {{ $t('changelogs.231027.title') }}
    </v-card-title>
    <v-card-text class="py-0 grey--text">
      2023/10/27
    </v-card-text>
    <v-card-text
      class="py-0"
      style="white-space: pre-line;"
    >
      <div>
        {{ $t('changelogs.231027.desc') }}
      </div>
    </v-card-text>
    <prompt-dialog-wrapper ref="subscriptionConfirmDialog">
      <confirm-dialog
        :msg="$t('payment.feature-plan-unavailable')"
        :confirm-label="$t('payment.view-plans')"
      />
    </prompt-dialog-wrapper>
  </div>
</template>

<script>
import PromptDialogWrapper from '@/widgets/PromptDialogWrapper'
import ConfirmDialog from '@/widgets/ConfirmDialog'
import { mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
  components: {
    PromptDialogWrapper,
    ConfirmDialog,
  },
  data () {
    return {}
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapFields(['changelogDialog']),
  },
}
</script>

<style scoped>
</style>