<script setup>
import { ref, onMounted, provide } from 'vue'
import Waterfall from '@/components/Waterfall.vue'
import { APIRequest } from '@/utils'
import Crypko from '@/utils/crypko'
import AlbumImgDialog from '@/widgets/AlbumImgDialog.vue'
import store from '@/store'

const props = defineProps({
  userId: {
    type: String,
    default: null,
  },
  order: {
    type: String,
    default: 'claimed_at',
  },
  orderType: {
    type: String,
    default: 'album',
  },
})

const skip = ref(0)


const albums = ref([])

function setLike (hash, like) {
  const album = albums.value.find(album => album.hash === hash)
  album.liked = like
  album.likes += like ? 1 : -1
}
const sortBy = ref(props.order)
provide('setLike', setLike)
const hasMore = ref(true)
const loading = ref(false)
onMounted(async () => {
  await fetchAlbums(skip.value)
  const observer = new IntersectionObserver(async (entries) => {
    if (entries[0].isIntersecting) {
      skip.value = albums.value.length
      if (hasMore.value) {
        await fetchAlbums(skip.value)
      }
    }
  })
  observer.observe(document.getElementById('intersection-observer'))
})


async function fetchAlbums (skip) {
  loading.value = true
  try {
    let url = `/travel/albums/?skip=${skip}&order=${sortBy.value}`
    if (props.userId) {
      url += `&user=${props.userId}`
    }
    const res = await new APIRequest(url, {}, 'get').send()
    albums.value = [...albums.value, ...res.data]
    if (res.data.length === 0) {
      hasMore.value = false
    }
    albums.value = albums.value.map(album => {
      return {
        ...album,
        crypko: new Crypko(album.crypko),
      }
    })
  } catch (e) {
    console.error(e)
  }
  loading.value = false
}
const selectingAlbum = ref(null)

import { useRoute } from '@/router'
const route = useRoute()
const onRemoved = (album) => {
  switch (route.name) {
    case 'gallery-travel':
    case 'user-album':
      // 如果是用户自己的相册，或是 gallery 页面
      albums.value = albums.value.filter(a => a.hash !== album.hash)
      break
    default:
      // 是 crypko 详情页面
      store.dispatch('carousel/fetchCrypko', this.crypko.hash)
  }
  selectingAlbum.value = null
}
provide('selectingAlbum', selectingAlbum)
import LoadingProgressCircular from '@/widgets/LoadingProgressCircular.vue'
import AlbumWaterfallItem from './AlbumWaterfallItem.vue'
import BrowserSort from '@/widgets/BrowserSort.vue'
const onSortChange = (type) => {
  sortBy.value = type
  console.log(sortBy.value)
  albums.value = []
  fetchAlbums(0)
}
</script>
<template>
  <div>
    <div class="mb-4 d-flex justify-end">
      <BrowserSort
        :ordering="sortBy"
        :ordering-types="orderType"
        @change="onSortChange"
      />
    </div>
    <Waterfall
      :data="albums"
      :box-spacing="10"
      :column-width="$vuetify.breakpoint.mdAndUp ? 300 : 150"
      :cols="$vuetify.breakpoint.smAndUp ? $vuetify.breakpoint.mdAndUp ? 4 : 3 : 2"
    >
      <template #default="{ data }">
        <AlbumWaterfallItem
          :data="data"
        />
      </template>
    </Waterfall>
    <AlbumImgDialog
      :album="selectingAlbum"
      @closed="selectingAlbum = null"
      @removed="onRemoved"
    />
    <div id="intersection-observer">
      <LoadingProgressCircular v-if="hasMore && loading" />
    </div>
  </div>
</template>